import { jsx as _jsx } from "react/jsx-runtime";
import { LocalizationStatus, LocalizationOrigin } from 'libs.localization.common';
import { Badge, Tooltip } from 'libs.nucleus';
import { AnimatedBadge } from 'libs.react';
import { toSentenceCase } from '../../utils/string_utils';
const statusColorMap = {
    [LocalizationStatus.READY]: 'success',
    [LocalizationStatus.IN_PROGRESS]: 'incomplete',
    [LocalizationStatus.IN_REVIEW]: 'incomplete',
    [LocalizationStatus.NOT_STARTED]: 'danger',
};
const IN_PROGRESS_CUSTOM_COLOR = 'bg-orange-100 border-orange-200 text-orange-900';
export var AssetVersionLoadingState;
(function (AssetVersionLoadingState) {
    AssetVersionLoadingState["LOADING"] = "loading";
    AssetVersionLoadingState["DONE"] = "done";
    AssetVersionLoadingState["AI_TRANSLATING"] = "ai_translating";
    AssetVersionLoadingState["AI_MIGRATING"] = "ai_migrating";
})(AssetVersionLoadingState || (AssetVersionLoadingState = {}));
export const AssetVersion = ({ currentVersion, loadingState }) => {
    const isVersionAvailable = Boolean(currentVersion && (currentVersion.status || (currentVersion.version && currentVersion.status)));
    // Default to loading state if no version is available
    const isAssetLoading = (loadingState &&
        [
            AssetVersionLoadingState.LOADING,
            AssetVersionLoadingState.AI_TRANSLATING,
            AssetVersionLoadingState.AI_MIGRATING,
        ].includes(loadingState)) ||
        !isVersionAvailable;
    if (isAssetLoading) {
        if (loadingState === AssetVersionLoadingState.AI_TRANSLATING ||
            loadingState === AssetVersionLoadingState.AI_MIGRATING) {
            return _jsx(AnimatedBadge, { label: '', startIcon: { name: 'animatedSparkles', color: 'primary', size: 'sm' } });
        }
        return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center animate-pulse', children: _jsx(Badge, { className: 'min-h-6 min-w-6', label: '' }) }));
    }
    if (currentVersion?.status === LocalizationStatus.NOT_AVAILABLE) {
        return '-';
    }
    if (!currentVersion) {
        return null;
    }
    const { status, version, origin = LocalizationOrigin.USER } = currentVersion;
    const showAIBadge = status !== LocalizationStatus.READY &&
        (origin === LocalizationOrigin.AI_TRANSLATION || origin === LocalizationOrigin.AI_MIGRATION);
    return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center', children: _jsx("div", { className: 'relative flex', children: _jsx(Tooltip, { placement: 'top', title: `${version > 0 ? `v${version}` : 'No version'}, ${toSentenceCase(status)}`, children: _jsx(Badge, { className: `min-h-6 min-w-6 gap-1 ${status === LocalizationStatus.IN_PROGRESS ? IN_PROGRESS_CUSTOM_COLOR : ''}`, variant: statusColorMap[status], dataTestId: `locale-status-icon-${status.replace(' ', '-').toLowerCase()}`, label: version > 0 ? `v${version}` : '', startIcon: showAIBadge ? { name: 'sparklesSolid', color: 'primary', size: 'xs' } : undefined }) }) }) }));
};
