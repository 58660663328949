import { getApiInstance } from './api.helpers';
import { normalizeDate } from './date.helpers';
export const isOutsideVisitWindowRange = (date, visit) => {
    if (!visit.c_window_start_date || !visit.c_window_end_date) {
        return false;
    }
    const today = normalizeDate(date);
    const windowStartDate = normalizeDate(visit.c_window_start_date);
    const windowEndDate = normalizeDate(visit.c_window_end_date);
    return today < windowStartDate || today > windowEndDate;
};
export const getParticipantVisitScheduleData = async ({ environment, participantId, siteId, }) => {
    return await getApiInstance(environment).get(`/v2/routes/c_sites/${siteId}/c_public_users/${participantId}/c_visit_schedule`);
};
