import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { FirebaseAuthContext } from 'libs.firebase_react';
import { useLocalizeMessage } from 'libs.nucleus';
import { ApiClientService, useApiClient } from 'libs.react';
export const useDownloadFromGCS = () => {
    const translate = useLocalizeMessage();
    const { post } = useApiClient(ApiClientService.WEB);
    const { firebaseToken } = useContext(FirebaseAuthContext);
    const { mutate: downloadFileFromGCSPath, isPending: isDownloading, isSuccess: isDownloaded, isError, } = useMutation({
        mutationKey: ['screenshot_report', 'download_report_pdf'],
        mutationFn: async ({ url }) => {
            const response = await post('/api/report_pdf/download_report_pdf', { url }, { responseType: 'blob', headers: { Authorization: `Bearer ${firebaseToken}` } });
            if (response.status !== 200) {
                throw new Error(translate('Failed to download file.'));
            }
            return response;
        },
        onSuccess: (response, params) => {
            downloadFile(response, params);
        },
    });
    return { downloadFileFromGCSPath, isDownloading, isDownloaded, isError };
};
function downloadFile(response, params) {
    const href = window.URL.createObjectURL(response.data);
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    const contentDisposition = response.headers['content-disposition'];
    const fileName = params?.fileName || extractFilenameFromContentDisposition(contentDisposition) || 'report.pdf';
    anchorElement.download = decodeURI(fileName);
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
}
function extractFilenameFromContentDisposition(contentDisposition) {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
    }
    return null;
}
