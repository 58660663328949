import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SDKStudy, SpecialProductTemplate } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { getLocaleMetaInfo } from 'libs.i18n-core';
import { LocalizationStatus } from 'libs.localization.common';
import { Locale, useLocalizeMessage, Table, Badge } from 'libs.nucleus';
import { useStudyLocales } from 'libs.translation_workbench';
const statusMap = {
    [LocalizationStatus.READY]: {
        color: 'success',
        label: 'Ready',
    },
    [LocalizationStatus.IN_PROGRESS]: {
        color: 'incomplete',
        label: 'In progress',
    },
    [LocalizationStatus.IN_REVIEW]: {
        color: 'incomplete',
        label: 'In review',
    },
    [LocalizationStatus.NOT_STARTED]: {
        color: 'incomplete',
        label: 'Not Ready',
    },
};
export const LocalesSelection = ({ onLocaleSelection, selectedStudyVersion }) => {
    const { studyId } = useParams();
    const t = useLocalizeMessage();
    const { logEvent } = useContext(FirebaseAppContext);
    const [study, setStudy] = useState(new SDKStudy({ name: '', template: SpecialProductTemplate.r400, pinnedProductTemplate: 40000 }));
    const { locales, getLocalizationStatus } = useStudyLocales(study);
    useEffect(() => {
        if (!selectedStudyVersion) {
            return;
        }
        const currentStudy = SDKStudy.fromRosetta(selectedStudyVersion.data);
        setStudy(currentStudy);
    }, [selectedStudyVersion]);
    const columns = [
        {
            header: t('Code'),
            accessorKey: 'locale',
            cell: (value) => {
                return value.getValue();
            },
        },
        {
            header: t('Language/script'),
            accessorKey: 'language',
        },
        {
            header: t('Country/region'),
            accessorKey: 'region',
        },
        {
            header: t('Status'),
            accessorKey: 'status',
            cell: (value) => {
                const status = value.getValue();
                const currentStatus = statusMap[status];
                const variant = currentStatus?.color || 'incomplete';
                return _jsx(Badge, { variant: variant, label: t(currentStatus.label) });
            },
        },
    ];
    const data = locales.map((locale) => {
        const { language, region } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: locale });
        const status = locale !== Locale.en_US
            ? getLocalizationStatus(locale)
                ? LocalizationStatus.READY
                : LocalizationStatus.NOT_STARTED
            : LocalizationStatus.READY;
        return {
            locale,
            language,
            region,
            status,
        };
    });
    const handleOnSearch = useCallback((searchValue) => {
        logEvent('submission_package_modal_search', {
            studyId,
            searchValue,
        });
    }, []);
    const onRowSelectionChange = useCallback((selectedRows) => {
        const selectedLocales = selectedRows.map((row) => locales[row]);
        onLocaleSelection(selectedLocales);
    }, [locales]);
    return (_jsx("div", { className: 'flex flex-col gap-5', children: _jsx(Table, { onSearch: handleOnSearch, isSingleSelect: true, data: data, columns: columns, labelNoResults: t('No Locales'), useInternalSearch: true, hasPagination: true, initialPageSize: 10, labelSearchField: t('Search'), isSelectable: true, onRowSelectionChange: onRowSelectionChange, paginationNavigationScreenReaderLabel: t('Locale table navigation') }) }));
};
