export const STUDY_MANAGEMENT_FLAGS = {
    cloneStudies: {
        id: 'cloneStudies',
        value: false,
        description: 'Enables the ability to clone a study',
        owner: 'Enterprise experience team',
        name: 'Clone studies',
        disabled: true,
        extra: { section: 'Studies' },
    },
    review: {
        id: 'review',
        value: false,
        description: 'Enables the review tab under Build section for a study',
        owner: 'Enterprise experience team',
        name: 'Review',
        extra: { section: 'Studies' },
    },
    chat: {
        id: 'chat',
        value: false,
        description: 'Enables AI chat',
        owner: 'Enterprise experience team',
        name: 'Medable chat',
        disabled: true,
        extra: { section: 'Studies' },
    },
    restoreVersion: {
        id: 'restoreVersion',
        value: false,
        description: 'Enables Restoring of study version',
        owner: 'Enterprise experience team',
        name: 'Restore Version',
        disabled: true,
        extra: { section: 'Studies' },
    },
    experimentalPackages: {
        id: 'experimentalPackages',
        value: false,
        description: `When enabled, study build will use non-stable 'dev-latest' template`,
        owner: 'Enterprise experience team',
        name: 'Use latest template',
        extra: { section: 'Studies' },
    },
    genStudy: {
        id: 'getStudy',
        value: false,
        description: 'Enables GenStudy, used to generate studies via AI',
        owner: 'Enterprise experience team',
        name: 'GenStudy',
        disabled: true,
        extra: { section: 'Studies' },
    },
    tentative41: {
        id: 'tentative41',
        value: false,
        description: 'Tentative features for 4.1 (paused to reduce validation for 4.1)',
        owner: 'Enterprise experience team',
        name: 'Tentative 4.1',
        extra: { section: 'Studies' },
    },
};
