import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FlagContext } from 'libs.flags.react';
import { IRB_FLAGS } from 'libs.irb.react';
import { Icon, Text, Tooltip, useLocalizeMessage } from 'libs.nucleus';
import { AclScope, AuthContext, useScopeValidator } from 'libs.react';
import { STUDY_MANAGEMENT_FLAGS } from '../../constants/developer_flags.constants';
import { StudyConfigurationContext } from '../../contexts/study_configuration/study_configuration.context';
import { timelineNameToHash } from '../../utils/study/study.utils';
const renderLinkContent = ({ title, isSectionHeader = false, navTooltipTitle, }) => {
    return (_jsxs("div", { className: 'flex items-center gap-2', children: [_jsx(Text, { size: 'sm', className: `nucleus-flex-1 nucleus-truncate ${isSectionHeader ? 'nucleus-font-medium' : ''}`, children: title }), _jsx(Tooltip, { as: 'div', placement: 'end', title: navTooltipTitle, children: _jsx(Icon, { name: 'lockSolid', color: 'yellowDark', size: 'sm' }) })] }));
};
export const useNavigation = () => {
    const { studyId } = useParams();
    const { entityId, isInternalUser } = useContext(AuthContext);
    const { currentStudy, currentTimelineId, translationsReadOnly, isUpgradeAvailable } = useContext(StudyConfigurationContext);
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const resource = `entity/${entityId}/study/${studyId}`;
    const canManageStudies = useScopeValidator(AclScope.MOM_STUDY_UPDATE, { resource });
    const canManageSites = useScopeValidator(AclScope.MOM_ENVIRONMENT_LIST, { resource });
    const isReadOnlyMode = useMemo(() => translationsReadOnly || isUpgradeAvailable, [translationsReadOnly, isUpgradeAvailable]);
    const navTooltipTitle = !isUpgradeAvailable
        ? translate('Study is in read-only mode to support translations activities.')
        : translate('Update required to unlock configuration.');
    const navItems = useMemo(() => {
        const timelines = currentStudy.getScheduleTimelines();
        const scheduleHash = timelineNameToHash(timelines[currentTimelineId]?.name ?? timelines.onboarding.name);
        const items = [
            {
                icon: 'navCube',
                links: [
                    {
                        title: translate('Schedule'),
                        path: `/studies/${studyId}/build/schedule${scheduleHash}`,
                        ignoreFragments: true,
                        hidden: !canManageStudies,
                    },
                    {
                        title: translate('Resources'),
                        path: `/studies/${studyId}/build/resources`,
                        hidden: !canManageStudies,
                    },
                ],
                path: `/studies/${studyId}/build`,
                title: !isReadOnlyMode
                    ? translate('Build')
                    : renderLinkContent({
                        title: translate('Build'),
                        navTooltipTitle,
                        isSectionHeader: true,
                    }),
                hidden: !canManageStudies,
                sectionToggleTestId: 'build-section-toggle',
            },
            {
                title: translate('Translations'),
                path: `/studies/${studyId}/translations`,
                icon: 'navTranslations',
                hidden: !canManageStudies,
            },
            {
                title: translate('Versions'),
                path: `/studies/${studyId}/versions`,
                icon: 'rectangleStack',
                hidden: !canManageStudies,
            },
            {
                title: translate('Tests'),
                path: `/studies/${studyId}/tests`,
                icon: 'window',
                sectionToggleTestId: 'tests-section-toggle',
                hidden: !canManageStudies && !canManageSites,
                links: [
                    {
                        title: translate('Test plans'),
                        path: `/studies/${studyId}/test-plans`,
                        badgeProps: { label: translate('Internal') },
                        hidden: !canManageStudies || !isInternalUser,
                    },
                ],
            },
            {
                title: translate('Review package'),
                path: `/studies/${studyId}/review-package`,
                icon: 'packageTruck',
                hidden: !canManageStudies || !flagManager.getValue(IRB_FLAGS.irb),
            },
            {
                title: translate('Deploy'),
                path: `/studies/${studyId}/deploy`,
                icon: 'navDeployment',
                hidden: !canManageStudies && !canManageSites,
            },
        ];
        if (isInternalUser) {
            items[0].links.push({
                title: translate('Extensions'),
                path: `/studies/${studyId}/build/extensions`,
                badgeProps: { label: translate('Internal') },
                hidden: !canManageStudies,
            }, {
                title: translate('Notifications'),
                path: `/studies/${studyId}/build/notifications`,
                badgeProps: { label: translate('Internal') },
                hidden: !canManageStudies,
            }, {
                title: translate('Study summary'),
                path: `/studies/${studyId}/build/study-summary`,
                badgeProps: { label: translate('Internal') },
                hidden: !canManageStudies,
            });
        }
        if (flagManager.getValue(STUDY_MANAGEMENT_FLAGS.review)) {
            items[0].links.push({
                title: translate('Review'),
                path: `/studies/${studyId}/build/review`,
                hidden: !canManageStudies,
            });
        }
        return items;
    }, [flagManager, studyId, currentTimelineId, isReadOnlyMode]);
    return { navItems };
};
