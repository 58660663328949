import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from 'react';
export const AppBannerContext = createContext({
    bannerMessage: undefined,
    clearAppBanner: () => undefined,
    showAppBanner: () => undefined,
});
export const AppBannerProvider = ({ children }) => {
    const [bannerMessage, setBannerMessage] = useState();
    const clearAppBanner = () => {
        setBannerMessage(undefined);
    };
    const showAppBanner = (newBannerMessage) => {
        setBannerMessage(newBannerMessage);
    };
    return (_jsx(AppBannerContext.Provider, { value: { clearAppBanner, bannerMessage, showAppBanner }, children: children }));
};
