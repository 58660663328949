import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { find } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { FormSections, useLocalizeMessage, ModalWindow, Spinner, useToastNotification } from 'libs.nucleus';
import { DisplayNameField } from './form_items/display_name.component';
import { EmailField } from './form_items/email.component';
import { createAndInviteCaregiver, createCaregiver, getFormInitialValues } from './helpers/caregiver_form.helpers';
import { getApiErrorMessage } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useForm } from '../../hooks/use_form';
export const CaregiverFormModal = ({ isModalOpen, caregiver, onClose, participant, onView, dataTestId }) => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const initialValues = getFormInitialValues({
        caregiver,
        totalCaregivers: participant.c_caregiver_relationship?.c_caregivers_info?.length || 0,
    });
    const { form, isFieldtouched, onFieldChange } = useForm({ initialValues });
    const { addNotification } = useToastNotification();
    const queryClient = useQueryClient();
    const addCaregiverMutation = useMutation({
        mutationFn: createCaregiver,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['participantDetails', participant._id] });
            addNotification({
                title: '',
                subtitle: translate('info.caregiver.record_created'),
                type: 'success',
            });
            onClose();
        },
        onError: (e) => {
            const message = getApiErrorMessage(e, true);
            addNotification({
                title: '',
                subtitle: message ?? translate('error.generic'),
                type: 'error',
            });
        },
    });
    const addAndInviteCaregiverMutation = useMutation({
        mutationFn: createAndInviteCaregiver,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: ['participantDetails', participant._id] });
            addNotification({
                title: '',
                subtitle: translate('info.caregiver.record_created'),
                type: 'success',
            });
            const _caregiverInfo = find(data.caregiverRelationship.c_caregivers_info, ({ c_public_user: { _id } }) => _id === data.caregiverUser._id);
            const caregiverInfo = {
                ..._caregiverInfo,
                c_public_user: data.caregiverUser,
            };
            onView(caregiverInfo);
            onClose();
        },
        onError: (e) => {
            const message = getApiErrorMessage(e, true);
            addNotification({
                title: '',
                subtitle: message ?? translate('error.generic'),
                type: 'error',
            });
        },
    });
    const errors = {
        email: form?.email && !isEmail(form?.email) ? translate('validation.invalid_email') : undefined,
        displayName: !form?.displayName ? translate('validation.caregiver.display_name_required') : undefined,
    };
    const formHasErrors = Object.values(errors).some((error) => !!error);
    const formSections = [
        {
            title: translate('generic.details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(DisplayNameField, { form: form, errors: errors, isFieldtouched: isFieldtouched, onFieldChange: onFieldChange }), _jsx(EmailField, { form: form, errors: errors, onFieldChange: onFieldChange, isFieldtouched: isFieldtouched })] })),
        },
    ];
    const isMutationInProgress = addCaregiverMutation.isPending || addAndInviteCaregiverMutation.isPending;
    const savingButton = (_jsx("div", { className: 'flex items-center gap-3', children: _jsx(Spinner, { size: 'md' }) }));
    return (_jsx(ModalWindow, { title: translate('action.caregiver.add'), subtitle: participant.c_number && `${translate('label.caregiver.modal_subtitle', { number: participant.c_number })}`, isOpen: isModalOpen, closeWindow: onClose, footerSecondaryActionButtons: [
            {
                label: translate('generic.cancel'),
                onClick: onClose,
                disabled: isMutationInProgress,
            },
        ], footerPrimaryActionButton: {
            disabled: formHasErrors || isMutationInProgress,
            label: translate('generic.create', { tense: 'present' }),
            content: addAndInviteCaregiverMutation.isPending ? savingButton : undefined,
            onClick: () => {
                if (!environment) {
                    throw new Error('Environment not defined');
                }
                addAndInviteCaregiverMutation.mutate({ environment, form, participantId: participant._id });
            },
            id: 'add-caregiver-invite',
            dataTestId: 'add-caregiver-invite',
        }, width: 'full', dataTestId: dataTestId, children: _jsx("form", { className: 'mt-10 mb-24', children: _jsx(FormSections, { sections: formSections }) }) }));
};
