import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { LocaleContext, Spinner } from 'libs.nucleus';
import { ActivityEcoaView } from './activity_ecoa/activity_ecoa_view';
import { ActivityStandardView } from './activity_standard/activity_standard_view';
import { useOrgContext } from '../../../hooks/use_org_context';
import { useSiteContext } from '../../../hooks/use_site_context';
import { ActivityManager } from '../helper_managers/activity_manager';
export const ActivityView = ({ visitActivity, setIsActivityModalClosed }) => {
    const activity = visitActivity.activity.c_group_task.c_assignment;
    const currentVisit = visitActivity.visit;
    const org = useOrgContext();
    const site = useSiteContext();
    const currentLocale = useContext(LocaleContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId/*');
    const initActivityResponseData = () => {
        const activityManager = new ActivityManager();
        activityManager.initActivityResponseData({
            activityId: activity._id,
            studyId: org._id,
            publicUserId: match?.params.participantId || '',
            currentGroupId: currentVisit.c_group,
            visitId: currentVisit.c_visit,
            siteId: site._id,
            currentLocale,
        });
        return {
            activityResponse: activityManager.activityResponse,
            activityManager: activityManager,
        };
    };
    /**
     * state hook to hold the activity response and activity manager between renders only
     * setActivityManagement to clear the activity response data on cancel or after a successful upload
     */
    const [activityManagement, setActivityManagement] = useState(() => initActivityResponseData());
    if (!activityManagement?.activityResponse || !activityManagement?.activityManager) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (activity.c_type === 'web_form') {
        return (_jsx(ActivityEcoaView, { visitActivity: visitActivity, setIsActivityModalClosed: setIsActivityModalClosed, activityResponse: activityManagement.activityResponse, activityManager: activityManagement.activityManager, setActivityManagement: setActivityManagement }));
    }
    return (_jsx(ActivityStandardView, { visitActivity: visitActivity, setIsActivityModalClosed: setIsActivityModalClosed, activityResponse: activityManagement.activityResponse, activityManager: activityManagement.activityManager, setActivityManagement: setActivityManagement }));
};
