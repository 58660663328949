import { jsx as _jsx } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalizeMessage, Table, ModalWindow } from 'libs.nucleus';
import { useSiteSelectorTableColumns } from './site_selector_table_columns.hook';
import { AxonAccountContext } from '../../contexts/axon_account';
import { SiteContext } from '../../contexts/site';
import { getUserSiteSelectionLocalStorageKey } from '../../helpers/local_storage.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const SiteSelector = ({ isModalOpen, setIsModalClosed }) => {
    const translate = useLocalizeMessage();
    const { sites, indexedSites, isLoading, site, setSelectedSite } = useContext(SiteContext);
    const { account: axonUser } = useContext(AxonAccountContext);
    const [selectedSiteId, setSelectedSiteId] = useState(site?._id ?? null);
    const { environment } = useCurrentEnvironment();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const userSiteSelectionKey = getUserSiteSelectionLocalStorageKey({
        environmentId: environment?.id || 0,
        axonUserId: axonUser?._id || '',
    });
    const handleSiteSelection = async () => {
        if (selectedSiteId && indexedSites) {
            setSelectedSite(indexedSites[selectedSiteId]);
            localStorage.setItem(userSiteSelectionKey, selectedSiteId);
            queryClient.invalidateQueries({
                queryKey: ['participantsList', environment?.id, selectedSiteId],
            });
            navigate(`/site/study/${environment?.id}/participants`);
            setIsModalClosed();
        }
    };
    const { siteSelectorColumns } = useSiteSelectorTableColumns({ setSelectedSiteId, selectedSiteId });
    return (_jsx(ModalWindow, { title: translate('action.site_selector.select'), isOpen: isModalOpen, closeWindow: () => { }, dataTestId: 'change-site-modal', footerSecondaryActionButtons: [
            {
                disabled: false,
                label: translate('generic.cancel'),
                content: undefined,
                onClick: setIsModalClosed,
            },
        ], footerPrimaryActionButton: {
            disabled: selectedSiteId === site?._id,
            label: translate('generic.continue'),
            content: undefined,
            onClick: handleSiteSelection,
        }, width: 'full', removeBodyPadding: true, children: _jsx("div", { className: 'p-6', children: _jsx(Table, { columns: siteSelectorColumns, data: sites || [], hasPagination: true, initialPageSize: 10, isLoading: isLoading, labelNoResults: translate('info.site_selector.no_sites'), paginationNavigationScreenReaderLabel: translate('info.site_selector.table_navigation'), useInternalSearch: true, getRowId: (row) => row._id, highlightedRowIds: selectedSiteId ? [selectedSiteId] : [] }) }) }));
};
