import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { InlineNotification, useLocalizeMessage } from 'libs.nucleus';
import { BottomNavigation } from './components/bottom_navigation.component';
import { LumosTrainingSteps } from './lumos_training.utils';
import { useLumosTrainingProgress } from '../../hooks/use_lumos_training';
export const LumosTrainingView = () => {
    const { allModulesCompleted, siteUserModuleProgress, localeNotSupported } = useLumosTrainingProgress();
    // we dont want to see the welcome screen if the user has already completed atleast one module training
    const [step, setStep] = useState(siteUserModuleProgress?.metadata?.moduleCompletionLogs?.length >= 1 ? 1 : 0);
    const [isTrainingCompleted, setIsTrainingCompleted] = useState(false);
    const [isOnCompletionScreen, setIsOnCompletionScreen] = useState(false);
    const translate = useLocalizeMessage();
    const [isOnReviewMode, setIsOnReviewMode] = useState(false);
    const ComponentToRender = LumosTrainingSteps[step].component;
    const navigate = useNavigate();
    const location = useLocation();
    const onHandleNextStep = () => {
        if (step === 1) {
            setIsOnCompletionScreen(true);
            setStep(2);
            return;
        }
        setStep(step + 1);
    };
    const onHandlePreviousStep = () => {
        setStep(step - 1);
    };
    const onHandleAllModulesCompleted = () => {
        setIsTrainingCompleted(true);
    };
    const onHandleLumosTrainingCompletion = () => {
        navigate('/site/dashboard');
    };
    useEffect(() => {
        setIsTrainingCompleted(allModulesCompleted ?? false);
        if (allModulesCompleted && location?.state?.prevLocation) {
            setStep(1);
            setIsOnReviewMode(true);
        }
    }, [allModulesCompleted, siteUserModuleProgress]);
    return (_jsx("div", { className: 'h-screen w-full overflow-auto', children: _jsxs("div", { className: 'flex flex-col h-full w-full relative bg-no-repeat bg-cover py-4 md:overflow-y-auto touch-pan-y ', style: {
                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(/site_app/images/training-background-image-optimized.png)',
            }, children: [localeNotSupported && (_jsx(InlineNotification, { title: translate('info.lumos_training.locale_not_supported_title'), subtitle: translate('info.lumos_training.locale_not_supported_description'), type: 'warning' })), _jsx(ComponentToRender, { onHandleNextStep: onHandleNextStep, onHandlePreviousStep: onHandlePreviousStep, onHandleAllModulesCompleted: onHandleAllModulesCompleted, siteUserModuleProgress: siteUserModuleProgress }), LumosTrainingSteps[step].showBottomNavigation && (_jsx(BottomNavigation, { onHandlePreviousStep: onHandlePreviousStep, onHandleNextStep: onHandleNextStep, isTrainingCompleted: isTrainingCompleted, onHandleLumosTrainingCompletion: onHandleLumosTrainingCompletion, isOnCompletionScreen: isOnCompletionScreen, isOnReviewMode: isOnReviewMode }))] }) }));
};
