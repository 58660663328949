import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiClientService, LibraryResourceStatus, useApiClient, LibraryEndpoint, AuthContext, useScopeValidator, AclScope, } from 'libs.react';
import { StudyConfigurationContext } from '../study_configuration/study_configuration.context';
const missingProvider = () => {
    throw Error('Missing the library activities provider');
};
export const LIBRARY_ACTIVITIES_QUERY_KEYS = {
    CURRENT_ACTIVITIES: ['library', 'current_activities'],
    LATEST_ACTIVITIES: ['library', 'latest_activities'],
};
export const LibraryActivitiesContext = createContext({
    activitiesWithNewVersion: [],
    activityScreens: {},
    addActivityScreens: missingProvider,
    getLatestActivities: missingProvider,
    isFetchingLatestActivities: false,
    latestActivityVersions: {},
    libraryActivities: [],
    showUpdateBanner: false,
    setShowUpdateBanner: missingProvider,
});
const getActivityLibraryIds = (study) => {
    return study
        .getAllActivities()
        .reduce((acc, activity) => (activity.libraryId ? [...acc, activity.libraryId] : acc), []);
};
export const LibraryActivitiesProvider = ({ children }) => {
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const { studyId } = useParams();
    const { currentStudy, assignStudy } = useContext(StudyConfigurationContext);
    const { entityId } = useContext(AuthContext);
    const [activityScreens, setActivityScreens] = useState({});
    const [showUpdateBanner, setShowUpdateBanner] = useState(false);
    const [isFetchLatestActivityEnabled, setIsFetchLatestActivityEnabled] = useState(false);
    const resource = `entity/${entityId}/study/${studyId}`;
    const canManageStudies = useScopeValidator(AclScope.MOM_STUDY_UPDATE, { resource });
    /**
     * Fetches all activities in use in the current study to have the screens available
     */
    const getLibraryActivities = async () => {
        const activityIds = getActivityLibraryIds(currentStudy);
        if (!activityIds.length) {
            setActivityScreens({});
            return [];
        }
        const { data: response } = await libraryClient.get(LibraryEndpoint.GET_ACTIVITY_RESOURCES, { params: { entityId, id: activityIds, limit: 200, status: LibraryResourceStatus.PUBLISHED } });
        assignActivityKeys(response.data);
        setActivityScreens(response.data.reduce((acc, activity) => {
            acc[activity.id] = activity.data?.screens ?? [];
            return acc;
        }, {}));
        return response.data;
    };
    const assignActivityKeys = (newLibraryActivities) => {
        let keysMigration = false;
        // We want to assign the activity resource key in the rosetta activity
        currentStudy.getAllActivities().forEach((activity) => {
            const libraryActivity = newLibraryActivities.find((libraryActivity) => libraryActivity.id === activity.libraryId);
            if (libraryActivity) {
                keysMigration = true;
                activity.libraryResourceKey = libraryActivity.key;
            }
        });
        if (keysMigration) {
            assignStudy(currentStudy);
        }
    };
    const { data: libraryActivities = [] } = useQuery({
        queryKey: LIBRARY_ACTIVITIES_QUERY_KEYS.CURRENT_ACTIVITIES,
        queryFn: getLibraryActivities,
        enabled: !!currentStudy.name && canManageStudies,
    });
    /**
     * Adds the screens for the given activity to the query cache. Useful when screen data needs to be immediately
     * available
     * @param activityId - string
     * @param screens - ActivityScreens
     */
    const addActivityScreens = useCallback((activityId, screens) => {
        if (screens?.length) {
            setActivityScreens((prev) => ({ ...prev, [activityId]: screens }));
        }
    }, [setActivityScreens]);
    /**
     * Fetches all activities in use in the current study to have the latest versions available
     */
    const getLatestActivityVersions = async () => {
        const activityKeys = libraryActivities.map((activity) => activity.key);
        const { data: response } = await libraryClient.get(LibraryEndpoint.GET_ACTIVITY_RESOURCES, { params: { entityId, limit: 200, key: activityKeys, status: LibraryResourceStatus.PUBLISHED } });
        return response.data.reduce((acc, activity) => (activity.latest ? { ...acc, [activity.key]: activity } : acc), {});
    };
    const { data: latestActivityVersions = {}, isFetching: isFetchingLatestActivities } = useQuery({
        queryKey: LIBRARY_ACTIVITIES_QUERY_KEYS.LATEST_ACTIVITIES,
        queryFn: getLatestActivityVersions,
        enabled: isFetchLatestActivityEnabled && canManageStudies,
    });
    const activitiesWithNewVersion = useMemo(() => {
        return libraryActivities.reduce((acc, currentActivity) => {
            const latestActivity = latestActivityVersions[currentActivity.key];
            if (latestActivity && latestActivity.version > currentActivity.version) {
                acc.push({ currentActivity, latestActivity });
            }
            return acc;
        }, []);
    }, [libraryActivities, latestActivityVersions]);
    const getLatestActivities = () => {
        setIsFetchLatestActivityEnabled(true);
    };
    const value = useMemo(() => ({
        activitiesWithNewVersion,
        activityScreens,
        addActivityScreens,
        getLatestActivities,
        isFetchingLatestActivities,
        latestActivityVersions,
        libraryActivities,
        showUpdateBanner,
        setShowUpdateBanner,
    }), [
        activitiesWithNewVersion,
        activityScreens,
        addActivityScreens,
        getLatestActivities,
        isFetchingLatestActivities,
        latestActivityVersions,
        libraryActivities,
        showUpdateBanner,
        setShowUpdateBanner,
    ]);
    return _jsx(LibraryActivitiesContext.Provider, { value: value, children: children });
};
