import { LUMOS_TRAINING_CONFIG_KEY } from '../../../constants/lumos_training.constants';
export const fetchLumosTrainingModules = async ({ momClient }) => {
    const responseLumosTrainingModules = await momClient.get(`/v1/site-training-config?configKey=${LUMOS_TRAINING_CONFIG_KEY}`);
    return responseLumosTrainingModules.data.data;
};
export const postLumosTrainingLog = async ({ momClient, entityId, codeCompleted, currentModuleCompletionDate, locale, lastModule, trainingConfigId, }) => {
    /**
     * device: 'laptop',
     * userAgent: navigator.userAgent
     * are optional fields for now
     */
    const response = await momClient.post(`/v1/entities/${entityId}/training`, {
        trainingConfigId,
        moduleCompletionLogs: [
            {
                code: codeCompleted,
                locale,
                completedOn: currentModuleCompletionDate,
            },
        ],
        ...(lastModule && { completedOn: new Date().toISOString() }),
    });
    return response.data.data;
};
export const fetchSiteUserTrainingProgress = async ({ entityId, indentityId, momClient, }) => {
    const response = await momClient.get(`/entities/${entityId}/training?identityId=${indentityId}&type=workspace`);
    return response.data.data ?? null;
};
