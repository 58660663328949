import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage, Input, ModalWindow, Select, Spinner, H5, Text, useToastNotification } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { getDeactivationInitialValues, getDeactivationReasons } from './participant_deactivation.utils';
import { getApiErrorMessage, getApiInstance } from '../../helpers/api.helpers';
import { getParticipantNumber } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useForm } from '../../hooks/use_form';
export const ParticipantDeactivationModal = ({ isModalOpen, participant, onClose, onDone, }) => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { addNotification } = useToastNotification();
    const [isSaving, , , toggleIsSaving] = useBooleanState(false);
    const { form, isFieldtouched, onFieldChange, onSelectFieldChange, resetForm } = useForm({
        initialValues: getDeactivationInitialValues(),
    });
    const errors = {
        participantNumber: participant.c_number && !form?.participantNumber
            ? translate('validation.participant_deactivation.id_required')
            : undefined,
        reason: !form?.reason?.value ? translate('validation.participant_deactivation.reason_required') : undefined,
    };
    //  If there is a participant number and it does not match the form participant number, show an error
    if (participant.c_number && form.participantNumber && participant.c_number !== form.participantNumber) {
        errors.participantNumber = translate('validation.participant_deactivation.id_mismatch');
    }
    const formHasErrors = Object.values(errors).some((error) => !!error);
    const onModalClose = () => {
        resetForm();
        onClose();
    };
    const deactivateParticipant = async () => {
        if (!environment) {
            throw new Error('Environment not defined');
        }
        toggleIsSaving();
        const body = {
            c_deactivation_reason_code: form.reason.value,
            c_public_user: participant?._id,
        };
        try {
            await getApiInstance(environment).post(`routes/participant/deactivate`, body);
            addNotification({
                title: '',
                subtitle: translate('status.participant_deactivation.success'),
                type: 'success',
            });
            onModalClose();
            onDone?.();
        }
        catch (e) {
            const message = getApiErrorMessage(e);
            addNotification({
                title: '',
                subtitle: message ?? translate('error.generic'),
                type: 'error',
            });
        }
        finally {
            toggleIsSaving();
        }
    };
    return (_jsx(ModalWindow, { title: translate('label.participant_deactivation.deactivate', {
            participantNumber: getParticipantNumber(participant),
        }), isOpen: isModalOpen, closeWindow: onModalClose, footerSecondaryActionButtons: [{ label: translate('generic.cancel'), onClick: onModalClose }], footerPrimaryActionButton: {
            variant: 'destructive',
            disabled: formHasErrors,
            label: translate('generic.deactivate'),
            onClick: deactivateParticipant,
            id: 'deactivate-participant-btn',
            dataTestId: 'deactivate-participant-btn',
        }, width: 'full', children: _jsxs("div", { className: 'flex flex-col h-full justify-between', children: [isSaving && _jsx(Spinner, { wrapper: 'full' }), _jsxs("div", { className: 'flex flex-col flex-grow items-center gap-6 justify-center', children: [_jsxs("section", { className: 'w-1/3', children: [_jsx(H5, { children: translate('prompt.participant_deactivation.confirm') }), participant.c_number && (_jsx(Text, { className: 'text-sm', children: translate('info.participant_deactivation.confirmation') }))] }), participant.c_number && (_jsx("section", { className: 'w-1/3', children: _jsx(Input, { dataTestId: 'participant-number', id: 'participantNumber', name: 'participantNumber', label: translate('label.participant_id'), onChange: onFieldChange, value: form.participantNumber, width: 'full', required: true, errorMessage: errors?.participantNumber && isFieldtouched('participantNumber')
                                    ? errors?.participantNumber
                                    : undefined, hasError: !!(errors?.participantNumber && isFieldtouched('participantNumber')) }) })), _jsx("section", { className: 'w-1/3', children: _jsx(Select, { required: true, dataTestId: 'deactivation-reason-select', errorMessage: errors?.reason && isFieldtouched('reason') ? errors?.reason : undefined, hasError: !!(errors?.reason && isFieldtouched('reason')), label: translate('label.participant_deactivation.reason'), onChange: (value) => {
                                    onSelectFieldChange(value, 'reason');
                                }, options: getDeactivationReasons(translate), value: form.reason, width: 'full' }) })] })] }) }));
};
