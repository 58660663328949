import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faker } from '@faker-js/faker';
import { DefaultTimelineId } from '@axon/rosetta-sdk';
import { Text } from 'libs.nucleus';
const getRandomSentence = () => {
    const firstWord = faker.lorem.word({ length: { min: 3, max: 15 } });
    const lorem = faker.lorem.words(2);
    const number = faker.number.int({ max: 9999 });
    return `${firstWord}-${lorem.split(' ').join('-')}-${number}`;
};
export const generateRandomOrgName = (environment) => {
    let sentence = '';
    do {
        sentence = getRandomSentence();
    } while (sentence.length > 40);
    return `${environment}.${sentence}`;
};
export const timelineNameToHash = (name = '') => {
    return `#${encodeURIComponent(name)}`;
};
export const hashToTimelineName = (hash = '') => {
    if (!hash) {
        return '';
    }
    const nameCheck = hash.startsWith('#') ? hash.slice(1) : hash;
    return decodeURIComponent(nameCheck);
};
const dependencyLabelMap = {
    activities: 'Activities',
    notifications: 'Notifications',
    timelineConditions: 'Timeline conditions',
    visits: 'Visits',
};
export const splitVisitsAndActivities = (dependentActivities, currentTimelineId, timelines = {}) => {
    // we separate activities that are visit confirmations, and treat those as visits for display purposes
    const isOnboarding = currentTimelineId === DefaultTimelineId.ONBOARDING;
    const activities = [];
    const visits = [];
    dependentActivities.forEach((activity) => {
        const timelineName = isOnboarding && 'scheduleTimelineId' in activity ? timelines[activity.scheduleTimelineId]?.name : '';
        if (activity.name.includes('Visit Confirmation')) {
            const visitName = activity.name.split('Visit Confirmation')[0].trim().slice(0, -1);
            visits.push(`${timelineName ? `(${timelineName}) ` : ''}${visitName}`);
        }
        else {
            activities.push(`${timelineName ? `(${timelineName}) ` : ''}${activity.name}`);
        }
    });
    return { activities, visits };
};
export const getWarningDependencies = ({ message, dependentActivities = [], dependentNotifications = [], timelineConditionNames = [], dependentVisits = [], translate, currentTimelineId, timelines = {}, }) => {
    const { activities, visits } = splitVisitsAndActivities(dependentActivities, currentTimelineId, timelines);
    const isOnboarding = currentTimelineId === DefaultTimelineId.ONBOARDING;
    const depdendentVisitNames = dependentVisits.map((visit) => {
        const timelineName = isOnboarding ? timelines[visit.scheduleTimelineId]?.name : '';
        return `${timelineName ? `(${timelineName}) ` : ''}${visit.name}`;
    });
    const dependencyList = {
        activities,
        visits: [...visits, ...depdendentVisitNames],
        notifications: dependentNotifications.map((notification) => notification.name),
        timelineConditions: timelineConditionNames,
    };
    return (_jsxs("div", { className: 'flex flex-col gap-3', children: [_jsx(Text, { className: 'text-text-secondary mb-4', size: 'sm', children: message }), Object.entries(dependencyList)
                .reduce((acc, [dependencyType, dependencies]) => {
                if (dependencies.length > 0) {
                    acc.push(_jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(Text, { className: 'text-text-primary', children: translate(dependencyLabelMap[dependencyType]) }), _jsx("ul", { className: 'list-disc pl-3 ml-2 marker:text-text-secondary', children: dependencies.map((dependency) => (_jsx("li", { children: _jsx(Text, { size: 'sm', className: 'text-text-secondary', children: dependency }) }, dependency))) })] }, dependencyType));
                }
                return acc;
            }, [])
                .filter(Boolean)] }));
};
export const getActivityWarningMessage = ({ dependentActivities = [], dependentNotifications = [], timelineConditionNames = [], translate, isUnscheduling, currentTimelineId, timelines = {}, }) => {
    const hasDependencies = dependentActivities.length > 0 || dependentNotifications.length > 0 || timelineConditionNames.length > 0;
    let message = 'Are you sure you want to delete this activity? This action cannot be undone.';
    if (!hasDependencies) {
        return (_jsx(Text, { size: 'sm', className: 'text-text-secondary', children: translate(message) }));
    }
    const { activities, visits } = splitVisitsAndActivities(dependentActivities, currentTimelineId, timelines);
    // Count the number of true conditions
    const trueConditionsCount = [
        activities.length > 0,
        visits.length > 0,
        dependentNotifications.length > 0,
        timelineConditionNames.length > 0,
    ].filter(Boolean).length;
    switch (true) {
        case trueConditionsCount > 1:
            message = isUnscheduling
                ? 'This activity has dependencies. To unschedule this activity, please adjust the schedule settings for the dependencies listed below:'
                : 'This activity has several dependencies. To delete this activity from your schedule, please adjust the schedule settings for the dependencies listed below:';
            break;
        case activities.length > 0:
            message = isUnscheduling
                ? 'This activity has other dependent activities. To unschedule this activity, please adjust the schedule settings for the activities listed below:'
                : 'This activity has other dependent activities. To delete this activity from your schedule, please adjust the schedule settings for the activities listed below:';
            break;
        case visits.length > 0:
            message = isUnscheduling
                ? 'This activity has dependent visits. To unschedule this activity, please adjust the schedule settings for the visits listed below:'
                : 'This activity has dependent visits. To delete this activity from your schedule, please adjust the schedule settings for the visits listed below:';
            break;
        case dependentNotifications.length > 0:
            message = isUnscheduling
                ? 'This activity has dependent notifications. To unschedule this activity, please adjust the schedule settings for the notifications listed below:'
                : 'This activity has dependent notifications. To delete this activity from your schedule, please adjust the schedule settings for the notifications listed below:';
            break;
        case timelineConditionNames.length > 0:
            message = isUnscheduling
                ? 'This activity has dependent timeline conditions. To unschedule this activity, please adjust the schedule settings for the timeline conditions listed below:'
                : 'This activity has dependent timeline conditions. To delete this activity from your schedule, please adjust the schedule settings for the timeline conditions listed below:';
            break;
    }
    return getWarningDependencies({
        message,
        dependentActivities,
        dependentNotifications,
        timelineConditionNames,
        translate,
        currentTimelineId,
        timelines,
    });
};
