import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, useLocalizeMessage } from 'libs.nucleus';
export const BottomNavigation = ({ onHandlePreviousStep, onHandleNextStep, onHandleLumosTrainingCompletion, isTrainingCompleted, isOnCompletionScreen, isOnReviewMode, }) => {
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const location = useLocation();
    const onHandleCancel = () => {
        if (location.state?.prevLocation) {
            navigate(location.state.prevLocation, { state: '' });
        }
        else {
            navigate('/site/dashboard');
        }
    };
    return (_jsx("div", { className: 'mt-auto', children: _jsx("div", { className: 'border-t-1 pt-6 mb-2 md:mb-0 l:mb-0 xl:mb-0', children: _jsxs("div", { className: 'flex justify-between mx-8 xl:mx-16', children: [!isOnCompletionScreen && !isOnReviewMode && (_jsx(Button, { label: translate('generic.back'), variant: 'secondary', onClick: onHandlePreviousStep })), _jsx("div", { className: 'flex ml-auto gap-1.5', children: isOnReviewMode ? (_jsx(Button, { label: translate('generic.cancel'), variant: 'secondary', onClick: onHandleCancel })) : isOnCompletionScreen ? (_jsx(Button, { label: translate('generic.done'), variant: 'primary', onClick: onHandleLumosTrainingCompletion })) : (_jsx(Button, { disabled: !isTrainingCompleted, label: translate('generic.next'), variant: 'primary', onClick: onHandleNextStep })) })] }) }) }));
};
