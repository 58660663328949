import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useBasicTypeaheadTriggerMatch, MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $getNodeByKey, $nodesOfType } from 'lexical';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { AutocompletePlugin } from './autocomplete_plugin';
import { CustomTextNode } from '../nodes';
import { $createVariableNode, $isVariableNode, VariableNode } from '../nodes/variable_node';
class Option extends MenuOption {
    name;
    id;
    constructor(name, id) {
        super(name);
        this.name = name;
        this.id = id;
    }
}
const noop = () => { };
const emptyArray = [];
export const VariablesPlugin = ({ variables = emptyArray, onAddVariable = noop, onDeleteVariable = noop, }) => {
    const [editor] = useLexicalComposerContext();
    const [query, setQuery] = useState(null);
    const variablesMap = useRef(new Map());
    const triggerMatch = useBasicTypeaheadTriggerMatch('{', { minLength: 0 });
    const options = useMemo(() => {
        const variableNames = variables.map(({ name, id }) => new Option(name, id));
        return !query
            ? variableNames
            : variableNames.filter((variable) => variable.name.toLowerCase().includes(query.toLowerCase()));
    }, [variables, query]);
    const onSelectOption = useCallback((selectedOption, nodeToReplace, closeMenu) => {
        editor.update(() => {
            const variableNode = $createVariableNode(selectedOption.name, selectedOption.id);
            nodeToReplace && nodeToReplace.replace(variableNode);
            variableNode.select();
            closeMenu();
        });
    }, [editor]);
    useEffect(() => {
        return editor.registerNodeTransform(CustomTextNode, (node) => {
            if (node instanceof VariableNode) {
                return;
            }
            const textContent = node.getTextContent();
            if (!textContent.includes('{')) {
                return;
            }
            const pattern = /{(?!{)([^}]*)}/g;
            const matches = [...textContent.matchAll(pattern)];
            if (!matches.length) {
                return;
            }
            let lastIndex = 0;
            const nodes = [];
            matches.forEach((match) => {
                const [matched, variable] = match;
                const offset = match.index || 0;
                const found = variables.find((v) => variable === v.name);
                if (offset > lastIndex) {
                    const textBefore = textContent.slice(lastIndex, offset);
                    nodes.push(new CustomTextNode(textBefore));
                }
                nodes.push($createVariableNode(variable, found?.id, { error: !found }));
                lastIndex = offset + matched.length;
            });
            if (lastIndex && lastIndex < textContent.length) {
                nodes.push(new CustomTextNode(textContent.slice(lastIndex)));
            }
            const parentNode = node.getParent();
            if (!nodes.length || !parentNode) {
                return;
            }
            for (const node of nodes) {
                parentNode.append(node);
            }
            node.remove();
        });
    }, [editor, variables]);
    useEffect(() => {
        return editor.registerMutationListener(VariableNode, (mutatedNodes) => {
            for (const [nodeKey, mutation] of mutatedNodes) {
                editor.getEditorState().read(() => {
                    if (mutation === 'created') {
                        const createdNode = $getNodeByKey(nodeKey);
                        // call onAddVariable callback and add to the map only if variable exists
                        if ($isVariableNode(createdNode) && !createdNode.hasError()) {
                            onAddVariable(createdNode.getVariableId());
                            variablesMap.current.set(nodeKey, createdNode.getVariableId());
                        }
                    }
                    if (mutation === 'destroyed') {
                        const remainingVariableNodes = $nodesOfType(VariableNode);
                        const destroyedVariableId = variablesMap.current.get(nodeKey);
                        // When a VariableNode is destroyed, check if it's the last of its type and call onDeleteVariable
                        const isLastOfType = !remainingVariableNodes.some((node) => node.getVariableId() === destroyedVariableId);
                        variablesMap.current.delete(nodeKey);
                        onDeleteVariable(destroyedVariableId, isLastOfType);
                    }
                });
            }
        });
    }, [editor]);
    return (_jsx(AutocompletePlugin, { onQueryChange: setQuery, options: options, onSelectOption: onSelectOption, triggerFn: triggerMatch, menuRenderFn: (anchorRef, { selectOptionAndCleanUp, setHighlightedIndex, selectedIndex }) => {
            return (anchorRef.current &&
                createPortal(_jsx("div", { className: 'fixed nucleus-bg-white nucleus-text-sm nucleus-shadow-xl nucleus-outline-none nucleus-rounded-md nucleus-max-h-80 nucleus-overflow-y-auto', children: options.map((option, idx) => (_jsx("div", { ref: option.setRefElement, tabIndex: -1, role: 'option', "aria-selected": selectedIndex === idx, className: `${selectedIndex === idx ? 'bg-neutral-200' : ''} cursor-pointer nucleus-flex nucleus-items-center nucleus-border-solid nucleus-font-sans nucleus-px-4 nucleus-py-1.5 first:nucleus-rounded-t-md last:nucleus-rounded-b-md`, onClick: () => {
                            setHighlightedIndex(idx);
                            selectOptionAndCleanUp(option);
                        }, onMouseEnter: () => {
                            setHighlightedIndex(idx);
                        }, children: option.name }, option.key))) }), anchorRef.current));
        } }));
};
