import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState, createContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { I18nUtils } from '@medable/i18n-core';
import { Locale } from 'libs.i18n-core';
import { LocaleContext, ModalWindow, SetLocaleContext, Spinner, useLocalizeMessage } from 'libs.nucleus';
import { ApiClientService, AuthContext, AuthEndpoint, formatLocaleOption, LocalesDropdown, SITE_LOCALES, useApiClient, useBooleanState, } from 'libs.react';
import { updateMyAccountDetails } from '../../helpers/axon_user.helpers';
import { getUserLocaleLocalStorageKey } from '../../helpers/local_storage.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { AxonAccountContext } from '../axon_account';
import { SiteContext } from '../site';
export const UserLocaleContext = createContext({
    isUserLocaleChecked: false,
    openLocaleModal: () => { },
    getUserSelectedLocale: () => '',
});
export const UserLocaleProvider = ({ children }) => {
    const { environment } = useCurrentEnvironment();
    const currentLocale = useContext(LocaleContext);
    const { site } = useContext(SiteContext);
    const siteLocalesList = site?.c_site_supported_locales ?? SITE_LOCALES;
    const navigate = useNavigate();
    const [isLocaleModalOpen, setIsLocaleModalOpen, setIsLocaleModalClosed] = useBooleanState();
    const [selectedLocale, setSelectedLocale] = useState();
    const [isUserLocaleChecked, setIsUserLocaleChecked] = useState(false);
    const [isLoading, setIsLoading, setIsNotLoading] = useBooleanState();
    const { account: axonUser } = useContext(AxonAccountContext);
    const translate = useLocalizeMessage();
    const setLocale = useContext(SetLocaleContext);
    const { refreshIdentity } = useContext(AuthContext);
    const iamClient = useApiClient(ApiClientService.IAM);
    const userLocaleKey = getUserLocaleLocalStorageKey({
        environmentId: environment?.id || 0,
        // siteId: site?._id || '',
        axonUserId: axonUser?._id || '',
    });
    const setUserLocale = async (locale) => {
        if (!environment || !axonUser) {
            return;
        }
        setIsLoading();
        await updateMyAccountDetails({
            environment,
            accountId: axonUser?._id,
            payload: { locale },
        });
        await iamClient.put(`${AuthEndpoint.IDENTITIES}/me`, {
            locale,
        });
        localStorage.setItem(userLocaleKey, locale);
        //  refresh identity
        await refreshIdentity();
        setLocale?.(Locale[locale]);
        setSelectedLocale(formatLocaleOption(locale, locale));
        setIsUserLocaleChecked(true);
        setIsNotLoading();
    };
    const checkUserLocale = async () => {
        const userEnvironmentSelectedLocale = localStorage.getItem(userLocaleKey);
        if (!userEnvironmentSelectedLocale) {
            openLocaleModal();
            return;
        }
        //  If user current locale on account isn't in site locales list, open modal
        if (!siteLocalesList.includes(I18nUtils.i18nToCortexFormat(currentLocale))) {
            //  if the site doesnt contain the locale we previously selected for another site, we force them to select again
            setIsUserLocaleChecked(false);
            openLocaleModal();
            return;
        }
        //  Prevent always calling setUserLocale ( api calls ) if the locale is already set and is the same as the current locale
        if (I18nUtils.i18nToCortexFormat(userEnvironmentSelectedLocale) === I18nUtils.i18nToCortexFormat(currentLocale)) {
            setIsUserLocaleChecked(true);
            return;
        }
        setUserLocale(userEnvironmentSelectedLocale);
    };
    useEffect(() => {
        if (environment && axonUser && site) {
            checkUserLocale();
        }
    }, [environment, axonUser, site]);
    const updateUserLocale = async () => {
        if (!environment || !axonUser || !selectedLocale) {
            return;
        }
        const locale = I18nUtils.i18nToCortexFormat(selectedLocale.value);
        await setUserLocale(locale);
        // Update locale after training check
        // setLocale?.(Locale[locale as keyof typeof Locale]);
        //  TODO - Find a better way to seamlessly switch locales
        //  Right now using reload because setLocale doesnt trigger a translation of sitebar and table headers
        window.location.reload();
        setIsUserLocaleChecked(true);
        setIsLocaleModalClosed();
    };
    const getUserSelectedLocale = () => {
        const userEnvironmentSelectedLocale = localStorage.getItem(userLocaleKey);
        return userEnvironmentSelectedLocale ?? axonUser?.locale ?? currentLocale;
    };
    const onModalClose = () => {
        setIsLocaleModalClosed();
        if (!isUserLocaleChecked) {
            navigate('/site/dashboard');
        }
    };
    const openLocaleModal = () => {
        setIsLocaleModalOpen();
    };
    const value = useMemo(() => ({
        isUserLocaleChecked,
        openLocaleModal,
        getUserSelectedLocale,
    }), [isUserLocaleChecked, openLocaleModal, getUserSelectedLocale]);
    return (_jsxs(UserLocaleContext.Provider, { value: value, children: [(isLoading || !axonUser || !site) && _jsx(Spinner, { wrapper: 'full' }), children, _jsx(ModalWindow, { title: translate('generic.language'), isOpen: isLocaleModalOpen, closeWindow: () => { }, footerPrimaryActionButton: {
                    label: translate('generic.save'),
                    onClick: updateUserLocale,
                    disabled: !selectedLocale,
                }, footerSecondaryActionButtons: [{ label: translate('generic.cancel'), onClick: onModalClose }], width: 'sm', children: _jsx(LocalesDropdown, { locales: siteLocalesList, label: translate('generic.language'), currentLocale: currentLocale, value: selectedLocale ?? undefined, onChange: (value) => {
                        setSelectedLocale(value);
                    } }) })] }));
};
