import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CAN_USE_DOM } from '@lexical/utils';
import { $getSelection, $isRangeSelection, $isTextNode, COMMAND_PRIORITY_LOW, createCommand, TextNode, } from 'lexical';
import { useCallback, useEffect, useState, startTransition } from 'react';
import { CustomTextNode } from '../nodes';
import { LexicalMenu, useMenuAnchorRef } from './autocomplete_menu';
export const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const getDOMSelection = (targetWindow) => {
    return !CAN_USE_DOM ? null : (targetWindow || window).getSelection();
};
const getTextUpToAnchor = (selection) => {
    const { anchor } = selection;
    const anchorNode = anchor.getNode();
    if (!(anchorNode instanceof TextNode) || !(anchorNode instanceof CustomTextNode)) {
        return null;
    }
    if (!anchorNode.isSimpleText()) {
        return null;
    }
    const anchorOffset = anchor.offset;
    return anchorNode.getTextContent().slice(0, anchorOffset);
};
const tryToPositionRange = (leadOffset, range, editorWindow) => {
    const domSelection = getDOMSelection(editorWindow);
    if (domSelection === null || !domSelection.isCollapsed) {
        return false;
    }
    const { anchorNode } = domSelection;
    const startOffset = leadOffset;
    const endOffset = domSelection.anchorOffset;
    if (anchorNode == null || endOffset == null) {
        return false;
    }
    try {
        range.setStart(anchorNode, startOffset);
        range.setEnd(anchorNode, endOffset);
    }
    catch (error) {
        return false;
    }
    return true;
};
function getQueryTextForSearch(editor) {
    let text = null;
    editor.getEditorState().read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
            return;
        }
        text = getTextUpToAnchor(selection);
    });
    return text;
}
function isSelectionOnEntityBoundary(editor, offset) {
    if (offset !== 0) {
        return false;
    }
    return editor.getEditorState().read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const { anchor } = selection;
            const anchorNode = anchor.getNode();
            const prevSibling = anchorNode.getPreviousSibling();
            return $isTextNode(prevSibling) && prevSibling.isTextEntity();
        }
        return false;
    });
}
// Got from https://stackoverflow.com/a/42543908/2013580
export function getScrollParent(element, includeHidden) {
    let style = getComputedStyle(element);
    const excludeStaticParent = style.position === 'absolute';
    const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
    if (style.position === 'fixed') {
        return document.body;
    }
    for (let parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === 'static') {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
            return parent;
        }
    }
    return document.body;
}
export const SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND = createCommand('SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND');
export function useBasicTypeaheadTriggerMatch(trigger, { minLength = 1, maxLength = 75 }) {
    return useCallback((text) => {
        const validChars = '[^' + trigger + PUNCTUATION + '\\s]';
        const TypeaheadTriggerRegex = new RegExp('(^|\\s|\\()(' + '[' + trigger + ']' + '((?:' + validChars + '){0,' + maxLength + '})' + ')$');
        const match = TypeaheadTriggerRegex.exec(text);
        if (match !== null) {
            const maybeLeadingWhitespace = match[1];
            const matchingString = match[3];
            if (matchingString.length >= minLength) {
                return {
                    leadOffset: match.index + maybeLeadingWhitespace.length,
                    matchingString,
                    replaceableString: match[2],
                };
            }
        }
        return null;
    }, [maxLength, minLength, trigger]);
}
export const AutocompletePlugin = ({ options, onQueryChange, onSelectOption, onOpen, onClose, menuRenderFn, triggerFn, anchorClassName, commandPriority = COMMAND_PRIORITY_LOW, parent, }) => {
    const [editor] = useLexicalComposerContext();
    const [resolution, setResolution] = useState(null);
    const anchorElementRef = useMenuAnchorRef(resolution, setResolution, anchorClassName, parent);
    const closeTypeahead = useCallback(() => {
        setResolution(null);
        if (onClose != null && resolution !== null) {
            onClose();
        }
    }, [onClose, resolution]);
    const openTypeahead = useCallback((res) => {
        setResolution(res);
        if (onOpen != null && resolution === null) {
            onOpen(res);
        }
    }, [onOpen, resolution]);
    useEffect(() => {
        const updateListener = () => {
            editor.getEditorState().read(() => {
                // Check if editor is in read-only mode
                if (!editor.isEditable()) {
                    closeTypeahead();
                    return;
                }
                const editorWindow = editor._window || window;
                const range = editorWindow.document.createRange();
                const selection = $getSelection();
                const text = getQueryTextForSearch(editor);
                if (!$isRangeSelection(selection) || !selection.isCollapsed() || text === null || range === null) {
                    closeTypeahead();
                    return;
                }
                const match = triggerFn(text, editor);
                onQueryChange(match ? match.matchingString : null);
                if (match !== null && !isSelectionOnEntityBoundary(editor, match.leadOffset)) {
                    const isRangePositioned = tryToPositionRange(match.leadOffset, range, editorWindow);
                    if (isRangePositioned !== null) {
                        startTransition(() => openTypeahead({
                            getRect: () => range.getBoundingClientRect(),
                            match,
                        }));
                        return;
                    }
                }
                closeTypeahead();
            });
        };
        const removeUpdateListener = editor.registerUpdateListener(updateListener);
        return () => {
            removeUpdateListener();
        };
    }, [editor, triggerFn, onQueryChange, resolution, closeTypeahead, openTypeahead]);
    useEffect(() => editor.registerEditableListener((isEditable) => {
        if (!isEditable) {
            closeTypeahead();
        }
    }), [editor, closeTypeahead]);
    return resolution === null || editor === null || anchorElementRef.current === null ? null : (_jsx(LexicalMenu, { close: closeTypeahead, resolution: resolution, editor: editor, anchorElementRef: anchorElementRef, options: options, menuRenderFn: menuRenderFn, shouldSplitNodeWithQuery: true, onSelectOption: onSelectOption, commandPriority: commandPriority }));
};
