import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export const generatePDF = async (certificateRef, fileName) => {
    console.log('Generating PDF...');
    if (!certificateRef.current) {
        return;
    }
    try {
        // Get the element's dimensions
        const element = certificateRef.current;
        // Capture the component as canvas using html2canvas
        const canvas = await html2canvas(element, {
            scale: 2, // Higher scale for better quality
            useCORS: true,
            logging: false,
            backgroundColor: 'indigo',
        });
        // Calculate dimensions to maintain aspect ratio
        const imgWidth = 297; // A4 width in mm (landscape)
        const imgHeight = 210;
        // Create PDF instance (landscape A4)
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
        });
        // Add the captured canvas to PDF
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.setDisplayMode('fullpage', 'continuous', 'FullScreen');
        // Save the PDF
        pdf.save(`${fileName}.pdf`);
    }
    catch (error) {
        console.error('Error generating certificate of completion PDF:', error);
    }
};
