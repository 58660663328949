import { useContext } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useLocalizeMessage } from 'libs.nucleus';
import { MOMRegion } from '../../types/environment.types';
import { DeploymentUtils } from '../../utils/deployment/deployment.utils';
export const useDeploymentUtils = ({ studyId }) => {
    const translate = useLocalizeMessage();
    const { logEvent } = useContext(FirebaseAppContext);
    const getRegionName = (region) => {
        switch (region) {
            case MOMRegion.CN:
                return translate('CN');
            case MOMRegion.EU:
                return translate('EU');
            case MOMRegion.US:
            case MOMRegion.LOCAL:
            default:
                return translate('US');
        }
    };
    const getFullRegionName = (region) => {
        switch (region) {
            case MOMRegion.CN:
                return translate('China');
            case MOMRegion.EU:
                return translate('Europe');
            case MOMRegion.US:
            case MOMRegion.LOCAL:
            default:
                return translate('United States');
        }
    };
    const launchDeployment = ({ app, environment, isProd = false, }) => {
        logEvent(isProd ? 'launch_env_prod' : 'launch_env_sandbox', { study_id: studyId, env_name: environment.name });
        const url = DeploymentUtils.buildEnvironmentUrl(environment, app);
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return { getRegionName, getFullRegionName, launchDeployment };
};
