import { useLocalizeMessage } from 'libs.nucleus';
import { SiteUserRoles } from '../../types';
export const useSiteUserRoles = () => {
    const translate = useLocalizeMessage();
    const siteUserRolesObject = {
        [SiteUserRoles.SITE_RESEARCH_COORDINATOR]: translate('Clinical Research Coordinator'),
        [SiteUserRoles.SITE_PRINCIPAL_INVESTIGATOR]: translate('Principal Investigator'),
        [SiteUserRoles.SITE_CLINICAL_RESEARCH_ASSOCIATE]: translate('Clinical Research Associate'),
    };
    const siteUserRolesOptions = [
        {
            label: siteUserRolesObject[SiteUserRoles.SITE_RESEARCH_COORDINATOR],
            value: SiteUserRoles.SITE_RESEARCH_COORDINATOR,
        },
        {
            label: siteUserRolesObject[SiteUserRoles.SITE_PRINCIPAL_INVESTIGATOR],
            value: SiteUserRoles.SITE_PRINCIPAL_INVESTIGATOR,
        },
        {
            label: siteUserRolesObject[SiteUserRoles.SITE_CLINICAL_RESEARCH_ASSOCIATE],
            value: SiteUserRoles.SITE_CLINICAL_RESEARCH_ASSOCIATE,
        },
    ];
    const getSiteUserRolesLabel = (roles) => {
        return roles
            .filter((role) => siteUserRolesObject[role])
            .map((role) => siteUserRolesObject[role])
            .join(', ');
    };
    return {
        siteUserRolesObject,
        siteUserRolesOptions,
        getSiteUserRolesLabel,
    };
};
