import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { Button, useLocalizeMessage, Spinner, Table } from 'libs.nucleus';
import { SectionCard } from 'libs.react';
import { buildDocumentCreateUrl } from './participant_documents.utils';
import { useParticipantDocuments, useParticipantDocumentsTableColumns, } from './participants_documents_table_columns.hook';
import { isParticipantDeactivated, participantHasLinkedCaregivers } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useLumosAuth } from '../../hooks/use_lumos_auth';
import { useOrgContext } from '../../hooks/use_org_context';
import { LUMOS_PERMISSIONS } from '../../types/permissions.types';
export const ParticipantDocuments = ({ participant }) => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { participantDocumentsColumns } = useParticipantDocumentsTableColumns();
    const { isUserPermitted } = useLumosAuth();
    const org = useOrgContext();
    const shouldSelectUserManually = (org?.c_caregiver_enabled ?? false) && participantHasLinkedCaregivers(participant);
    const openCreateDocumentPage = () => {
        const createDocumentUrl = buildDocumentCreateUrl(participant?._id, environment, shouldSelectUserManually);
        window.open(createDocumentUrl, '_blank');
    };
    const { data: documents, isLoading } = useParticipantDocuments(participant);
    if (isLoading || !participant) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const canCreateDocument = !isParticipantDeactivated(participant) && isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_CREATE_CONSENTS);
    if (isEmpty(documents)) {
        return (_jsx(SectionCard, { children: _jsxs("section", { className: 'flex flex-col items-center justify-center gap-8 p-10', children: [_jsx("p", { className: 'text-sm text-secondary', children: translate('info.participant_documents.no_consents') }), canCreateDocument && (_jsx(Button, { label: translate('action.participant_documents.create_consent'), variant: 'secondary', onClick: openCreateDocumentPage }))] }) }));
    }
    return (_jsxs("div", { className: 'flex flex-col gap-4', children: [canCreateDocument && (_jsx("div", { className: 'flex justify-end', children: _jsx(Button, { label: translate('action.participant_documents.create_consent'), variant: 'secondary', onClick: openCreateDocumentPage }) })), _jsx(Table, { columns: participantDocumentsColumns, data: documents || [], hasPagination: true, initialPageSize: 10, isLoading: isLoading, labelNoResults: translate('label.participant_documents.empty'), paginationNavigationScreenReaderLabel: translate('info.participant_documents.table_navigation') })] }));
};
