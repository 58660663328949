import { QueryClientContext, useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiClientService, AuthContext, useApiClient } from '../../features';
export const useWorkspaceSiteMutation = (workspaceSiteId) => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const queryClient = useContext(QueryClientContext);
    const workspaceSiteMutation = useMutation({
        mutationFn: (data) => {
            if (workspaceSiteId) {
                return momClient.put(`/v1/entities/${entityId}/sites/${workspaceSiteId}`, data);
            }
            return momClient.post(`/v1/entities/${entityId}/sites`, data);
        },
        onSuccess: async () => {
            if (workspaceSiteId) {
                await queryClient?.invalidateQueries({
                    queryKey: ['entities', entityId.toString(), 'sites', workspaceSiteId.toString()],
                });
            }
            else {
                await queryClient?.invalidateQueries({
                    queryKey: ['entities', entityId.toString(), 'sites'],
                });
            }
        },
    });
    return {
        isPending: workspaceSiteMutation.isPending,
        mutateAsync: workspaceSiteMutation.mutateAsync,
        isError: workspaceSiteMutation.isError,
        error: workspaceSiteMutation.error,
    };
};
