import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDialog, useLocalizeMessage, useToastNotification } from 'libs.nucleus';
import { updateCaregiver } from '../../components/caregiver_form/helpers/caregiver_form.helpers';
import { getApiErrorMessage } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../use_current_environment';
export const useUpdateCaregiverStatus = (participant) => {
    const { environment } = useCurrentEnvironment();
    const queryClient = useQueryClient();
    const dialog = useDialog();
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const updateCaregiverMutation = useMutation({
        mutationFn: updateCaregiver,
    });
    const updateStatus = async (caregiver, isDisabling) => {
        if (!environment || !participant) {
            throw new Error('Environment or participant not defined');
        }
        return dialog.open({
            title: translate(isDisabling ? 'prompt.caregiver_disabling.confirm' : 'prompt.caregiver_enabling.confirm'),
            body: translate(isDisabling ? 'prompt.caregiver_disabling.body' : 'prompt.caregiver_enabling.body'),
            actionButtons: [
                {
                    label: translate(isDisabling ? 'generic.disable' : 'generic.enable'),
                    isDestructive: isDisabling,
                    onClick: async () => {
                        try {
                            await updateCaregiverMutation.mutateAsync({
                                environment,
                                form: {
                                    displayName: caregiver.c_caregiver_display_name,
                                    disableCaregiver: isDisabling,
                                },
                                participantId: participant._id,
                                caregiverId: caregiver.c_public_user._id,
                            });
                            await queryClient.invalidateQueries({ queryKey: ['participantDetails', participant._id] });
                            addNotification({
                                subtitle: translate(isDisabling ? 'status.caregiver.disabled' : 'status.caregiver.enabled'),
                                type: 'success',
                                title: '',
                            });
                            return true;
                        }
                        catch (e) {
                            addNotification({
                                subtitle: getApiErrorMessage(e) ?? translate('error.generic'),
                                type: 'error',
                                title: '',
                            });
                            return false;
                        }
                    },
                },
                { label: translate('generic.cancel') },
            ],
        });
    };
    return {
        enableCaregiver: (caregiver) => updateStatus(caregiver, false),
        disableCaregiver: (caregiver) => updateStatus(caregiver, true),
    };
};
