import { $applyNodeReplacement, } from 'lexical';
import { CustomTextNode } from './custom_text';
export const className = 'font-mono text-sm bg-neutral-100 px-0.5 border border-100-neutral rounded-md ';
export class VariableNode extends CustomTextNode {
    name;
    id;
    error;
    constructor(name, id, error, key) {
        super(name, key);
        this.name = name;
        this.id = id;
        this.error = error;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            name: this.name,
            id: this.id,
            error: Boolean(this.error),
            type: 'variable',
            version: 1,
        };
    }
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.className = `${className}${this?.error ? 'decoration-wavy decoration-red-300 underline' : ''}`;
        dom.spellcheck = false;
        dom.setAttribute('data-lexical-variable', 'true');
        return dom;
    }
    exportDOM(editor) {
        const { element } = super.exportDOM(editor);
        if (element?.textContent) {
            element.textContent = '{' + element.textContent + '}';
        }
        return { element };
    }
    getVariableId() {
        return this.id;
    }
    isTextEntity() {
        return true;
    }
    hasError() {
        return this.error;
    }
    canInsertTextBefore() {
        return false;
    }
    canInsertTextAfter() {
        return false;
    }
    static getType() {
        return 'variable';
    }
    static clone(node) {
        return new VariableNode(node.name, node.getVariableId(), node.hasError(), node.getKey());
    }
    static importJSON(serializedNode) {
        return $createVariableNode(serializedNode.name, serializedNode.id, { error: serializedNode.error });
    }
    static importDOM() {
        return {
            span: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-variable')) {
                    return null;
                }
                return {
                    conversion: $convertVariable,
                    priority: 1,
                };
            },
        };
    }
}
export const $createVariableNode = (name, id, options = { error: false }) => {
    const variableNode = new VariableNode(name, id, options.error, options.key);
    variableNode.setMode('segmented').toggleDirectionless();
    return $applyNodeReplacement(variableNode);
};
const $convertVariable = (domNode) => {
    const { textContent } = domNode;
    const variableName = domNode.getAttribute('data-lexical-variable-name');
    const id = domNode.getAttribute('data-lexical-variable-id');
    if (textContent !== null) {
        const node = $createVariableNode(typeof variableName === 'string' ? variableName : textContent, id ?? '');
        return { node };
    }
    return null;
};
export const $isVariableNode = (node) => {
    return node instanceof VariableNode;
};
