import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, useLocalizeMessage } from 'libs.nucleus';
import { AuthWrapper } from '../../components';
import { AuthContext } from '../../features';
export const SwitchWorkspacePage = () => {
    const translate = useLocalizeMessage();
    const { user, login, error, redirectToDefaultPage, setError } = useContext(AuthContext);
    const passwordRef = useRef(null);
    const { id } = useParams();
    const [password, setPassword] = useState('');
    const isSubmitDisabled = !password;
    const onPasswordChange = (event) => {
        setPassword(event.target.value);
        setError(undefined);
    };
    const onSubmit = async () => {
        if (!isSubmitDisabled && user && id) {
            try {
                await login(user.email, password, Number(id));
                await redirectToDefaultPage();
            }
            catch (error) {
                console.error('Error logging in:', error);
            }
        }
    };
    return (_jsx(AuthWrapper, { onSubmit: onSubmit, error: error, title: translate('Sign in to your account'), children: _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { "data-testid": 'login-password-input', id: 'password', label: translate('Password'), onChange: onPasswordChange, ref: passwordRef, required: true, hideRequiredIndicator: true, type: 'password', value: password, width: 'full' }), _jsx(Button, { label: translate('Continue'), disabled: isSubmitDisabled })] }) }));
};
