import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { useLocalizeMessage, Spinner, Text, ModalDialog, CalloutNotification, } from 'libs.nucleus';
export const StudyTranslationStatusModal = ({ onModalClose, onHandleStatusChange, isLoading, currentStatus, isUpgradeAvailable, }) => {
    const t = useLocalizeMessage();
    const isDraft = currentStatus === StudyLocalizationStatus.DRAFT || currentStatus === StudyLocalizationStatus.NOT_STARTED;
    const doneButton = {
        label: isDraft ? t('Enable translations') : t('Disable translations'),
        onClick: onHandleStatusChange,
        content: isLoading ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm', variant: 'alt' }) })) : undefined,
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onModalClose,
        // disabled: isLoading, // temperory commented
    };
    return (_jsx(ModalDialog, { width: 'lg', title: isDraft ? t('Enable translations') : t('Disable translations'), isOpen: true, actionButtons: [doneButton, closeButton], closeDialog: onModalClose, body: isDraft ? (_jsxs("div", { className: 'flex flex-col gap-6 mb-6', children: [_jsx(Text, { className: 'text-text-secondary', children: t('You are about to enable translations.') }), _jsxs("ol", { className: 'list-decimal ps-7 leading-8 marker:text-text-secondary', children: [_jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('Configuration of the study is read only.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('Translation capabilities will be enabled.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('Draft study assets will be assigned v1.') }) }), _jsx("li", { children: _jsx(Text, { className: 'text-text-secondary', children: t('Previously versioned study assets are re-versioned and statuses updated.') }) })] }), isUpgradeAvailable && (_jsx(CalloutNotification, { title: t('Note'), subtitle: t('You must disable translations if an update is required.') }))] })) : (_jsx("div", { children: _jsx(Text, { className: 'text-text-secondary', children: t('Disabling translations allows change of configuration that may invalidate your current translation progress.') }) })) }));
};
