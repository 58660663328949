export var IAMRole;
(function (IAMRole) {
    IAMRole["ADMIN"] = "admin";
    IAMRole["BUILDER"] = "builder";
    IAMRole["DATA_MANAGER"] = "dataManager";
    IAMRole["DATA_REVIEWER"] = "dataReviewer";
    IAMRole["DATA_TRANSFER_ADMIN"] = "dataAdmin";
    IAMRole["LIBRARIAN"] = "librarian";
    IAMRole["SITE_USER"] = "siteUser";
    IAMRole["TRANSLATOR"] = "translator";
    IAMRole["USER_AND_SITE_MANAGER"] = "userAndSiteManager";
})(IAMRole || (IAMRole = {}));
export var EnvironmentRole;
(function (EnvironmentRole) {
    EnvironmentRole["CONSENT_BUILDER"] = "roles/mom.consentBuilder";
    EnvironmentRole["DATA_MANAGER"] = "roles/mom.dataManager";
    EnvironmentRole["DATA_REVIEWER"] = "roles/mom.dataReviewer";
    EnvironmentRole["DATA_TRANSFER_ADMIN"] = "roles/mom.dtAdmin";
    EnvironmentRole["ENVIRONMENT_ADMIN"] = "roles/mom.environmentAdmin";
    EnvironmentRole["SITE_CLINICAL_RESEARCH_ASSOCIATE"] = "roles/mom.siteClinicalResearchAssociate";
    EnvironmentRole["SITE_PRINCIPAL_INVESTIGATOR"] = "roles/mom.sitePrincipalInvestigator";
    EnvironmentRole["SITE_RESEARCH_COORDINATOR"] = "roles/mom.siteResearchCoordinator";
})(EnvironmentRole || (EnvironmentRole = {}));
