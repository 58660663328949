import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { DeviceSelector, selectedDeviceAtom } from 'libs.preview';
export const DeviceSelection = ({ onDeviceSelection }) => {
    const selectedDeviceValue = useAtomValue(selectedDeviceAtom);
    const devices = useMemo(() => ({
        // TODO: comment for now, will be used in the future
        // androidLarge: {
        //   device: 'Android',
        //   width: 412,
        //   height: 915,
        //   scale: 1,
        //   hasLandscape: false,
        // },
        // iosLarge: {
        //   device: 'iOS',
        //   width: 375,
        //   height: 812,
        //   scale: 1,
        //   hasLandscape: false,
        // },
        web: {
            device: 'Web',
            width: 1920,
            height: 1080,
            scale: 0.58,
            hasLandscape: false,
        },
    }), []);
    const defaultViewport = 'web';
    useEffect(() => {
        const selectedDevice = Object.keys(devices).find((key) => devices[key] === selectedDeviceValue) || defaultViewport;
        const { width, height, scale } = devices[selectedDevice];
        const viewport = { width, height, scale };
        onDeviceSelection(selectedDevice, viewport);
    }, [selectedDeviceValue]);
    return (_jsx("div", { children: _jsx(DeviceSelector, { devices: devices, defaultDevice: devices[defaultViewport], showDimensions: true, width: 'lg' }) }));
};
