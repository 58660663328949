import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, Input } from 'libs.nucleus';
import { getCaregiverDisplayNameCount } from '../../../helpers/caregivers.helpers';
const FIELD_NAME = 'displayName';
export const DisplayNameField = ({ form, errors, onFieldChange, isFieldtouched }) => {
    const translate = useLocalizeMessage();
    const fieldLabel = translate('label.caregiver.display_name');
    return (_jsx(Input, { disabled: true, dataTestId: 'caregiver-display-name', errorMessage: errors?.[FIELD_NAME] && isFieldtouched(FIELD_NAME) ? errors?.[FIELD_NAME] : undefined, hasError: !!(errors?.[FIELD_NAME] && isFieldtouched(FIELD_NAME)), label: fieldLabel, onChange: onFieldChange, value: translate('label.caregiver.count', {
            count: getCaregiverDisplayNameCount(form?.[FIELD_NAME]),
        }), width: 'lg', id: FIELD_NAME, "data-testid": 'caregiver-display-name-input' }));
};
