import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
import { ApiClientService, AuthContext, PageHeader, PageWrapper, useApiClient } from 'libs.react';
import { UserReportstable } from '../../components/user_reports/user_reports_table.component';
const UserTrainingReportPage = () => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const translate = useLocalizeMessage();
    const getReportData = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/training`);
        if (Array.isArray(response.data)) {
            return response.data;
        }
        return response.data ? [response.data] : [];
    };
    const { data = [], isLoading } = useQuery({
        queryKey: ['entities', entityId, 'training'],
        queryFn: getReportData,
        enabled: !!entityId,
    });
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('User training'), subtitle: translate('Review the training completion records for site users.') }), _jsx(UserReportstable, { data: data, isLoading: isLoading })] }));
};
export default UserTrainingReportPage;
