import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Button, H1, useLocalizeMessage } from 'libs.nucleus';
import { AuthContext } from 'libs.react';
export const WelcomePage = ({ onHandleNextStep }) => {
    const translate = useLocalizeMessage();
    const { user } = useContext(AuthContext);
    const handleGettingStarted = () => onHandleNextStep();
    return (_jsx("div", { className: 'flex-1 mt-96 items-center justify-start pl-20', children: _jsxs("div", { className: 'max-w-[600px] mb-10', children: [_jsx(H1, { className: '', children: `${translate('label.salutation', { name: user?.first ?? '' })}` }), _jsx("p", { className: 'max-w-[550px] text-neutral-400 mb-4', children: translate('info.lumos_training.welcome_title') }), _jsx("p", { className: 'max-w-[550px] text-neutral-500 mb-4', children: translate('info.lumos_training.welcome_description') }), _jsx(Button, { label: translate('info.lumos_training.start_button'), variant: 'primary', onClick: handleGettingStarted })] }) }));
};
