import { stepResponseTypes } from '../constants/step_response_type.constants';
import { EStepTypes, EWebViewStepResponseTypes } from '../constants/step_type.enum';
const getWebViewResponseType = (resultType) => {
    switch (resultType) {
        case EWebViewStepResponseTypes.BOOLEAN:
            return stepResponseTypes.cBoolean.objectType;
        case EWebViewStepResponseTypes.NUMERIC:
            return stepResponseTypes.cNumeric.objectType;
        case EWebViewStepResponseTypes.INSTRUCTION:
            return stepResponseTypes.cInstruction.objectType;
        case EWebViewStepResponseTypes.TEXT:
        default:
            return stepResponseTypes.cText.objectType;
    }
};
export const getStepResponseType = (c_step) => {
    const { c_type, c_date_only } = c_step;
    switch (c_type) {
        case EStepTypes.COMPLETION:
            return stepResponseTypes.cCompletion.objectType;
        case EStepTypes.NUMERIC:
            return stepResponseTypes.cNumeric.objectType;
        case EStepTypes.TEXT:
            return stepResponseTypes.cText.objectType;
        case EStepTypes.BOOLEAN:
            return stepResponseTypes.cBoolean.objectType;
        case EStepTypes.TEXT_CHOICE:
            return stepResponseTypes.cTextChoice.objectType;
        case EStepTypes.PARTICIPANT_ID:
            return stepResponseTypes.cParticipantId.objectType;
        case EStepTypes.INSTRUCTION:
            return stepResponseTypes.cInstruction.objectType;
        case EStepTypes.DATETIME:
            return c_date_only ? stepResponseTypes.cDate.objectType : stepResponseTypes.cDatetime.objectType;
        case EStepTypes.WEB_VIEW:
            return getWebViewResponseType(c_step.c_result_type || '');
        default:
            return '';
    }
};
