import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage, ModalWindow, Spinner } from 'libs.nucleus';
import { WorkspaceSiteForm, useWorkspaceSiteForm } from 'libs.react';
export const WorkspaceSiteModal = ({ workspaceSite, onClose }) => {
    const translate = useLocalizeMessage();
    const { isEditMode, isPending, isSubmitDisabled, formValue, formErrors, setFormErrors, setFormValue, submitFrom } = useWorkspaceSiteForm({
        workspaceSite,
        onSuccess: onClose,
    });
    return (_jsxs(ModalWindow, { isOpen: true, dataTestId: 'create-site-modal', title: isEditMode ? translate('Edit site') : translate('Create site'), width: 'full', footerPrimaryActionButton: {
            id: 'submit-button',
            dataTestId: 'submit-button',
            disabled: isSubmitDisabled(),
            label: isEditMode ? translate('Save') : translate('Create'),
            onClick: submitFrom,
        }, footerSecondaryActionButtons: [
            {
                id: 'cancel-button',
                dataTestId: 'cancel-button',
                label: translate('Cancel'),
                onClick: onClose,
            },
        ], closeWindow: onClose, children: [isPending && _jsx(Spinner, { wrapper: 'full' }), _jsx(WorkspaceSiteForm, { formValue: formValue, formErrors: formErrors, onChange: setFormValue, onError: setFormErrors })] }));
};
