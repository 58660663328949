import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClientContext } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { CalloutNotification, ModalDialog, Text, useLocalizeMessage, useToastNotification, } from 'libs.nucleus';
import { ErrorUtils, ApiClientService, useApiClient, AuthContext, versionNumberToString, useProductTemplates, } from 'libs.react';
import { DEPLOYMENT_STATUS } from '../../constants/deployment.constants';
import { StudyConfigurationContext } from '../../contexts/study_configuration/study_configuration.context';
import { useJobChecker } from '../../hooks/use_job_checker/use_job_checker.hook';
export const StudyUpgradeModal = ({ onModalClose }) => {
    const momClient = useApiClient(ApiClientService.MOM);
    const [isLoading, setIsLoading] = useState(false);
    const { currentStudy, studyId, assignStudy, configId, getStudy } = useContext(StudyConfigurationContext);
    const { entityId } = useContext(AuthContext);
    const translate = useLocalizeMessage();
    const { logEvent } = useContext(FirebaseAppContext);
    const queryClient = useContext(QueryClientContext);
    const { checkJobStatus } = useJobChecker();
    const { addNotification } = useToastNotification();
    const { validatedTemplate } = useProductTemplates();
    const createNewVersion = async ({ versionName, versionDescription, isLatestVersion, studyConfigId, }) => {
        const upgradeTime = isLatestVersion ? 'post' : 'pre';
        const { data: response } = await momClient.post(`/v1/entities/${entityId}/studies/${studyId}/publish`, {
            spec: currentStudy.toRosetta(),
            studyConfigId,
            metadata: {
                description: versionDescription,
                name: versionName,
            },
        }, { no404Handling: true });
        const statusResponse = await checkJobStatus(response.data.jobId);
        if (statusResponse.status === DEPLOYMENT_STATUS.COMPLETED) {
            logEvent(`upgrade_study_${upgradeTime}_upgrade_version_created`, {
                study_id: studyId,
                version_name: versionName,
                version_description: versionDescription,
            });
        }
        else {
            const errorMessage = ErrorUtils.getResultErrorMessage(statusResponse.result);
            let toastMessage;
            if (errorMessage) {
                toastMessage = translate('Study software template updated successfully, however we were unable to generate new configuration versions due to the following errors:\n\n{errorMessage}\n\nPlease update the study configuration to resolve this error and proceed with creating versions on the latest software.', { errorMessage });
            }
            else {
                toastMessage = translate('Study software template updated successfully, however something went wrong when attempting to generate new configuration versions. Please update the study configuration to resolve potential issues and proceed with creating versions on the latest software.');
            }
            addNotification({
                title: translate('Update successful'),
                subtitle: toastMessage,
                type: 'warning',
            });
            logEvent(`upgrade_study_${upgradeTime}_upgrade_version_creation_failed`, {
                study_id: studyId,
                version_name: versionName,
                version_description: versionDescription,
            });
            return false;
        }
        return true;
    };
    const upgradeStudy = async () => {
        if (!isLoading) {
            setIsLoading(true);
            const latestTemplate = `R${versionNumberToString(validatedTemplate)}`;
            const currentTemplate = `R${versionNumberToString(currentStudy.productTemplate)}`;
            let versionName = translate('{currentTemplate} build (auto-created)', { currentTemplate });
            let versionDescription = translate('Auto-created version of pre-upgrade study configuration on {currentTemplate}', { currentTemplate });
            try {
                // Creating the version of the study configuration on the old software version
                const isOldVersionCreated = await createNewVersion({
                    versionName,
                    versionDescription,
                    isLatestVersion: false,
                    studyConfigId: configId,
                });
                if (isOldVersionCreated) {
                    const { newStudyConfigId } = await getStudy();
                    if (newStudyConfigId) {
                        // Creating the version of the study configuration on the latest software version
                        currentStudy.productTemplate = validatedTemplate;
                        versionName = translate('{latestTemplate} build (auto-created)', { latestTemplate });
                        versionDescription = translate('Auto-created version of study configuration on {latestTemplate}', {
                            latestTemplate,
                        });
                        const isNewVersionCreated = await createNewVersion({
                            versionName,
                            versionDescription,
                            isLatestVersion: true,
                            studyConfigId: newStudyConfigId,
                        });
                        if (isNewVersionCreated) {
                            addNotification({
                                title: translate('Update successful'),
                                subtitle: translate('System generated versions were created to reflect configuration before and after the update.'),
                                type: 'success',
                            });
                        }
                    }
                }
            }
            catch (error) {
                // @ts-expect-error - AxiosError type is not being caught by instanceof
                if (error.name === 'AxiosError' && !error.response?.data?.errorHandled) {
                    const message = error.response?.data?.error;
                    const subtitle = message
                        ? translate('The version of your study build could not be saved: \n{message}', { message })
                        : translate('The version of your study build could not be saved. Please try again.');
                    addNotification({ title: translate('Error creating study version'), subtitle, type: 'error' });
                }
                logEvent('version_creation_failed', {
                    study_id: studyId,
                    version_name: versionName,
                    version_description: versionDescription,
                });
            }
            finally {
                // we assign the study in finally because no matter the outcome of the old and new version creations, we still want to update the template
                const { newStudy } = await getStudy();
                if (newStudy && newStudy.productTemplate !== validatedTemplate) {
                    newStudy.productTemplate = validatedTemplate;
                    assignStudy(newStudy);
                }
                else if (currentStudy.productTemplate !== validatedTemplate) {
                    currentStudy.productTemplate = validatedTemplate;
                    assignStudy(currentStudy);
                }
                setIsLoading(false);
                await queryClient?.invalidateQueries({ queryKey: ['study-configs', studyId] });
            }
        }
    };
    const doneButton = {
        label: translate('Update now'),
        onClick: upgradeStudy,
        disabled: isLoading,
    };
    const closeButton = {
        label: translate('Cancel'),
        onClick: onModalClose,
        disabled: isLoading,
    };
    return (_jsx(ModalDialog, { width: 'lg', title: translate('Study configuration is locked'), isOpen: true, actionButtons: [doneButton, closeButton], closeDialog: onModalClose, willCloseOnOutsideClickAndEscapeKeyPress: false, body: _jsxs("section", { className: 'flex flex-col gap-6 mb-4', "data-autofocus": true, children: [_jsx(Text, { size: 'sm', className: 'text-text-secondary', children: translate('Accept platform update to continue configuring. When updated, the system generates two versions of the current configuration that represent before and after the update.') }), _jsx(Text, { size: 'sm', className: 'text-text-secondary', children: translate('You are not required to update to continue translating, testing, and deploying versions of your configuration to production.') }), _jsxs("div", { children: [_jsx(Text, { size: 'sm', className: 'text-text-secondary', children: translate('Current version: R{version}', {
                                version: versionNumberToString(currentStudy.productTemplate),
                            }) }), _jsx(Text, { size: 'sm', className: 'text-text-secondary', children: translate('New version: R{version}', {
                                version: versionNumberToString(validatedTemplate),
                            }) })] }), _jsx(CalloutNotification, { type: 'info', title: translate('Note'), subtitle: translate('Updating platform version does not automatically update associated test or production environments and will not change the workflow or content for sites and patients.') })] }) }));
};
