import { AclScope, useScopeValidator } from 'libs.react';
import { SITE_USER_PERMISSION_MAP } from '../../helpers/permissions.helpers';
import { useCurrentEnvironment } from '../use_current_environment';
export const useLumosAuth = () => {
    const { environment } = useCurrentEnvironment();
    const currentResource = environment?.sitePath;
    const isSiteUser = useScopeValidator(AclScope.CORTEX_ROLE_AXON_SITE_USER, {
        resource: currentResource,
    });
    const isSiteInvestigator = useScopeValidator(AclScope.CORTEX_ROLE_AXON_SITE_INVESTIGATOR, {
        resource: currentResource,
    });
    const isSiteMonitor = useScopeValidator(AclScope.CORTEX_ROLE_AXON_SITE_MONITOR, {
        resource: currentResource,
    });
    const collatedPermissions = [];
    if (isSiteUser) {
        collatedPermissions.push(...(SITE_USER_PERMISSION_MAP[AclScope.CORTEX_ROLE_AXON_SITE_USER] ?? []));
    }
    if (isSiteInvestigator) {
        collatedPermissions.push(...(SITE_USER_PERMISSION_MAP[AclScope.CORTEX_ROLE_AXON_SITE_INVESTIGATOR] ?? []));
    }
    if (isSiteMonitor) {
        collatedPermissions.push(...(SITE_USER_PERMISSION_MAP[AclScope.CORTEX_ROLE_AXON_SITE_MONITOR] ?? []));
    }
    const isUserPermitted = (permission) => {
        return collatedPermissions.includes(permission);
    };
    return {
        isUserPermitted,
    };
};
