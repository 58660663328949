import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Button, Text } from 'libs.nucleus';
import { AppBannerContext } from '../contexts/app_banner.context';
const BACKGROUND_COLORS = {
    note: 'bg-primary-200',
    warning: 'bg-yellow-300',
};
export const AppBanner = () => {
    const { bannerMessage } = useContext(AppBannerContext);
    if (!bannerMessage) {
        return null;
    }
    return (_jsxs("section", { "data-testid": 'app-banner', className: `flex items-center w-full min-h-10 p-2 justify-center relative ${BACKGROUND_COLORS[bannerMessage.variant || 'note']}`, children: [_jsx(Text, { size: 'sm', className: 'text-text-primary flex-1 text-center', title: bannerMessage.content, children: bannerMessage.content }), bannerMessage.actionButton && (_jsx(Button, { className: 'ml-auto hover:bg-transparent active:bg-transparent focus-visible:bg-transparent', size: 'sm', variant: 'transparent', ...bannerMessage.actionButton }))] }));
};
