import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
export const ActivityRunner = ({ basePreviewURL, participantId, visitId, activityId, }) => {
    const iframeRef = useRef(null);
    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            const handleLoad = () => {
                iframe.contentWindow?.postMessage({
                    type: 'LUMOS_ECOA_VARIABLES_INIT',
                    data: {
                        ecoa_hook_participant_id: participantId,
                        ecoa_hook_visit_id: visitId,
                        ecoa_hook_task_id: activityId,
                    },
                }, '*');
            };
            iframe.addEventListener('load', handleLoad);
            // Cleanup function to remove the event listener
            return () => {
                iframe.removeEventListener('load', handleLoad);
            };
        }
    }, [participantId, visitId, activityId]);
    return (_jsx("iframe", { ref: iframeRef, src: basePreviewURL, width: '100%', height: '100%', title: 'ecoa-activity-view', "data-testid": 'ecoa-iframe-view', id: 'ecoa-activity-view' }));
};
