import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FileUploaderViaFirebaseStorage, FirebaseAppContext } from 'libs.firebase_react';
import { JobTypes } from 'libs.jobs.common';
import { useCreateJob } from 'libs.jobs.react';
import { Button, Combobox, FormSections, Icon, Text, useLocalizeMessage, } from 'libs.nucleus';
import { AuthContext, ModalNav, MultiStepsModalWindow, getLocalesTableArray } from 'libs.react';
const SelectLocale = ({ files, localeMap, onLocaleChange }) => {
    const translate = useLocalizeMessage();
    const fileEntries = useMemo(() => {
        return Object.entries(files).map(([id, { file, status }]) => {
            return {
                id,
                name: file.name,
                status,
            };
        });
    }, [files]);
    const allLocales = useMemo(() => getLocalesTableArray().map(({ code, region, language }) => ({
        label: `${language} ${region} (${code})`,
        value: code,
    })), []);
    const changeLocale = useCallback((id, value) => onLocaleChange({ ...localeMap, [id]: value }), [onLocaleChange, localeMap]);
    const formSections = fileEntries.map((entry, index) => ({
        title: fileEntries.length === 1 ? 'Document' : `Document ${index + 1}`, // Sequential number
        content: (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(Text, { size: 'sm', children: entry.name }), " ", _jsx("div", { className: 'flex flex-row items-end', children: _jsx(Combobox, { value: localeMap[entry.id], label: translate('Locale'), noOptionsFoundLabel: translate('Unsupported locale'), options: allLocales, onChange: (value) => changeLocale(entry.id, value) }) })] }, entry.id)),
    }));
    return (_jsxs("div", { className: 'mb-10', children: [_jsx(Text, { size: 'sm', className: 'nucleus-text-text-secondary', children: translate('Select the language of each document to be migrated') }), _jsx(FormSections, { sections: formSections })] }));
};
export const InstantMigrationAction = ({ assetId }) => {
    const { studyId } = useParams();
    const [files, setFiles] = useState({});
    const [localeMap, setLocaleMap] = useState({});
    const translate = useLocalizeMessage();
    const { logEvent } = useContext(FirebaseAppContext);
    const auth = useContext(AuthContext);
    const createJob = useCreateJob();
    const stepStates = useMemo(() => {
        const filesEntries = Object.entries(files);
        return [
            {
                isEnabled: Boolean(filesEntries.length && filesEntries.every(([, { status }]) => status === 'success')),
            },
            {
                isEnabled: Boolean(filesEntries.length && filesEntries.every(([id]) => id in localeMap)),
            },
        ];
    }, [files, localeMap]);
    const isInstantMigrationEnabled = auth.isInternalUser ?? false;
    const disabled = !assetId;
    const handleFileChange = useCallback((files) => {
        const fileTypeCounts = Object.values(files).reduce((acc, { file }) => {
            const ext = file.name.split('.').pop();
            if (ext) {
                acc[ext] = (acc[ext] || 0) + 1;
            }
            return acc;
        }, {});
        logEvent('import_with_ai_upload_files', {
            assetId,
            fileTypeCounts,
        });
        setFiles(files);
    }, [setFiles, assetId]);
    const handleLocaleChange = useCallback((localeMap) => {
        logEvent('import_with_ai_locales', {
            assetId,
            locales: Object.keys(localeMap).join(', '),
        });
        setLocaleMap(localeMap);
    }, [setLocaleMap, assetId]);
    const handleSubmit = useCallback(() => {
        logEvent('import_with_ai_submit', {
            assetId,
            locales: Object.keys(localeMap).join(', '),
        });
        if (!localeMap || stepStates.some(({ isEnabled }) => !isEnabled)) {
            return;
        }
        for (const [id, file] of Object.entries(files)) {
            const locale = localeMap[id].value;
            createJob(JobTypes.TRANSLATION__INSTANT_MIGRATION, {
                asset_id: assetId,
                locale,
                document_url: file.firebaseUrl,
                document_filename: file.file.name,
            });
        }
    }, [stepStates, files, localeMap]);
    const handleClose = useCallback(() => {
        logEvent('import_with_ai_modal_cancel', {
            studyId,
            assetId,
        });
    }, [studyId, assetId]);
    const handleStepChange = useCallback((step) => {
        logEvent('import_with_ai_modal_step_changed', {
            studyId,
            assetId,
            step,
        });
    }, [studyId, assetId]);
    if (!isInstantMigrationEnabled) {
        return null;
    }
    return (_jsx(ModalNav, { actionButton: _jsx(Button, { variant: 'secondary', label: translate('Import with AI'), disabled: disabled, content: _jsxs("span", { className: 'font-sans m-0 text-sm flex items-center gap-2', children: [_jsx(Icon, { name: 'sparklesSolid', color: 'primary', size: 'sm' }), " ", translate('Import with AI')] }) }), modalWindow: _jsx(MultiStepsModalWindow, { title: translate('Import with AI'), submitLabel: translate('Import'), steps: [
                _jsx(FileUploaderViaFirebaseStorage, { storageBaseUrl: 'uploads', label: translate('Selected documents will be imported using Medable AI.'), accept: '.doc,.docx,.xlsx,.json', multiple: true, onFileChange: handleFileChange, description: translate('Upload individual or multiple files using the following formats: .doc, .docx, .xlxs, .json'), buttonLabel: translate('Upload') }, '1'),
                _jsx(SelectLocale, { files: files, localeMap: localeMap, onLocaleChange: handleLocaleChange }, '2'),
            ], stepStates: stepStates, onStepChange: handleStepChange }), onSubmit: handleSubmit, onClose: handleClose }));
};
