import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
const fetchEnvironments = async (get, entityId, studyId) => {
    const response = await get(`/v1/entities/${entityId}/studies/${studyId}/environments?withWorkflows=true&clusterTypes=dev,ephemeral,sandbox`);
    return response?.data?.data || [];
};
export const useEnvironments = () => {
    const { entityId, user } = useContext(AuthContext);
    const { studyId } = useParams();
    const { get } = useApiClient(ApiClientService.MOM);
    const queryKey = ['entities', entityId, 'environments'];
    const queryFn = () => fetchEnvironments(get, entityId, studyId);
    const enabled = !!user && !!entityId && !!studyId;
    return useQuery({ queryKey, queryFn, enabled });
};
