import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Icon, useLocalizeMessage } from 'libs.nucleus';
import { isParticipantDeactivated } from '../../helpers/participants.helpers';
export const NonVisitSchedule = ({ nonVisitData, participant, setCurrentVisit, setIsActivityListModalOpen, }) => {
    const translate = useLocalizeMessage();
    const onVisitScheduleClick = (visit) => {
        setCurrentVisit({
            c_visit: '',
            c_name: visit.c_name ?? '',
            c_group: visit?.c_group ?? '',
            c_window_start: Date.now(),
            c_window_end: Date.now(),
            c_open_queries: 0,
            c_completed_assignments: 0,
            c_completed_assignments_for_site: 0,
            c_sequence: 0,
            assignments: visit.assignments ?? 0,
            total_activities_count: visit.assignments ?? 0,
            customVisitType: 'non_visit_schedule',
        });
        setIsActivityListModalOpen();
    };
    return nonVisitData.map((visit) => {
        return (_jsxs("div", { "data-testid": `visit-schedule-${visit.c_group}`, className: 'grid grid-cols-3 grid-rows-1 gap-4 w-full p-4 bg-white border border-gray-200 rounded-lg shadow-sm', children: [_jsxs("div", { className: 'col-span-2', children: [_jsx("span", { className: 'text-primary-500', children: visit.c_name }), _jsx("div", { children: _jsx("span", { className: 'text-gray-500', "data-testid": `visit-assessments-${visit.c_group}`, children: translate('label.visit_schedule.activities', {
                                    assignments: visit.assignments ?? 0,
                                    count: visit.assignments ?? 0,
                                }) }) })] }), _jsx("div", { className: 'ml-auto col-span-1', style: { marginTop: '3px' }, children: !isParticipantDeactivated(participant) && (_jsx(Button, { label: translate('action.visit.view'), "aria-label": 'view-visit-schedule', variant: 'transparent', content: _jsx(Icon, { name: 'chevronRight', size: 24, color: 'primary' }), onClick: () => onVisitScheduleClick(visit) })) })] }, visit.c_group));
    });
};
