import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
import { useDateTime } from 'libs.react';
export const useUserReportTableColumn = () => {
    const translate = useLocalizeMessage();
    const { parseDateTimeShort } = useDateTime();
    const reportColumns = useMemo(() => {
        return [
            {
                header: translate('Email'),
                accessorKey: 'email',
            },
            {
                header: translate('Version'),
                accessorKey: 'version',
            },
            {
                header: translate('Type'),
                accessorKey: 'type',
            },
            {
                header: translate('Completed on'),
                accessorKey: 'completedOn',
                cell: (context) => parseDateTimeShort(context.getValue()),
            },
        ];
    }, []);
    return {
        reportColumns,
    };
};
