import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { InlineNotification, LocaleContext, useLocalizeMessage } from 'libs.nucleus';
import { ListInformation } from 'libs.react';
import { formatVisitWindow } from '../../helpers/participants.helpers';
export const VisitDetails = ({ visit, isFetchingParticipantActivities }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    return (_jsxs(_Fragment, { children: [isFetchingParticipantActivities && (_jsx(InlineNotification, { type: 'info', title: '', subtitle: translate('info.activities_list.activities.waiting') })), _jsx(ListInformation, { dataTestId: 'visit-details-list-information', items: [
                    {
                        title: translate('generic.date'),
                        description: formatVisitWindow({
                            windowStartDate: visit.c_window_start_date,
                            windowEndDate: visit.c_window_end_date,
                            currentLocale,
                        }),
                    },
                    {
                        title: translate('generic.activities'),
                        description: visit.assignments,
                    },
                ], useDefaultEmptyDescription: true })] }));
};
