/**
 * Deduplicate environment based on siteId
 * Users may have access to multiple sites per environment
 */
export const deduplicateEnvironments = (environmentSites) => {
    const seenEnvironmentIds = new Set();
    return environmentSites.reduce((acc, site) => {
        const { environment, study, path } = site;
        if (!seenEnvironmentIds.has(environment.id)) {
            seenEnvironmentIds.add(environment.id);
            acc.push({
                ...environment,
                siteMetadata: site.metadata,
                study,
                sitePath: path,
            });
        }
        return acc;
    }, []);
};
