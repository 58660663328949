import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { FormSections, useLocalizeMessage, ModalWindow, Spinner, useToastNotification } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { ParticipantEmailField } from './form_items/participant_email.component';
import { ParticipantLanguageField } from './form_items/participant_language.component';
import { ParticipantNumberField } from './form_items/participant_number.component';
import { createParticipant, getParticipantFormInitialValues } from './helpers/participant_form.helpers';
import { ParticipantInviteModal } from './participant_invite_modal.component';
import { getApiErrorMessage } from '../../helpers/api.helpers';
import { getParticipantDuplicateIndexErrorMessage } from '../../helpers/participants.helpers';
import { useAxonAccountContext } from '../../hooks/use_axon_account_context';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useForm } from '../../hooks/use_form';
import { useOrgContext } from '../../hooks/use_org_context';
import { useSiteContext } from '../../hooks/use_site_context';
export const ParticipantFormModal = ({ isModalOpen, participant, onClose }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const account = useAxonAccountContext();
    const { environment } = useCurrentEnvironment();
    const site = useSiteContext();
    const { form, isFieldtouched, onFieldChange, onSelectFieldChange, resetForm } = useForm({
        initialValues: getParticipantFormInitialValues(participant),
    });
    const [isSaving, , , toggleIsSaving] = useBooleanState(false);
    const [createdParticipant, setCreatedParticipant] = useState();
    const [isInviteModalOpen, setIsInviteModalOpen, setIsInviteModalClose] = useBooleanState(false);
    const { addNotification } = useToastNotification();
    const navigate = useNavigate();
    const onInviteModalDone = (participant) => {
        navigate(`/site/study/${environment?.id}/participants/${participant._id}`);
    };
    const onModalClose = () => {
        onClose();
        resetForm();
    };
    const errors = {
        email: form?.email && !isEmail(form?.email) ? translate('validation.invalid_email') : undefined,
        language: !form?.language?.value ? translate('validation.participant_form.language_required') : undefined,
    };
    if (org?.c_requires_econsent_field && !errors.email && !form?.email) {
        errors.email = translate('validation.field_required');
    }
    const formHasErrors = Object.values(errors).some((error) => !!error);
    const saveForm = async () => {
        if (!environment) {
            throw new Error('Environment not defined');
        }
        try {
            toggleIsSaving();
            const participant = await createParticipant({
                environment,
                org,
                account,
                site,
                form,
            });
            if (participant) {
                addNotification({
                    title: '',
                    subtitle: translate('info.participant.created'),
                    type: 'success',
                });
                //  If econsent is enabled, we need to redirect to the econsent tab
                if (org?.c_requires_econsent_field) {
                    navigate(`/site/study/${environment?.id}/participants/${participant._id}#documents`);
                    return;
                }
                setCreatedParticipant(participant);
                onModalClose();
                setIsInviteModalOpen();
            }
        }
        catch (e) {
            console.error('error', e);
            let message = getParticipantDuplicateIndexErrorMessage(e, translate);
            if (!message) {
                message = getApiErrorMessage(e);
            }
            addNotification({
                title: '',
                subtitle: message ?? translate('error.generic'),
                type: 'error',
            });
        }
        finally {
            toggleIsSaving();
        }
    };
    const formSections = [
        {
            title: translate('generic.details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(ParticipantLanguageField, { form: form, errors: errors, isFieldtouched: isFieldtouched, onSelectFieldChange: onSelectFieldChange }), _jsx(ParticipantEmailField, { form: form, errors: errors, onFieldChange: onFieldChange, isFieldtouched: isFieldtouched }), _jsx(ParticipantNumberField, { form: form, errors: errors, onFieldChange: onFieldChange, isFieldtouched: isFieldtouched })] })),
        },
    ];
    return (_jsxs(_Fragment, { children: [createdParticipant && (_jsx(ParticipantInviteModal, { isModalOpen: isInviteModalOpen, participant: createdParticipant, onClose: setIsInviteModalClose, onDone: () => onInviteModalDone(createdParticipant) })), _jsxs(ModalWindow, { title: translate('label.participant.create'), isOpen: isModalOpen, closeWindow: onModalClose, footerSecondaryActionButtons: [{ label: translate('generic.cancel'), onClick: onModalClose }], footerPrimaryActionButton: {
                    disabled: formHasErrors,
                    label: translate('generic.create', { tense: 'present' }),
                    onClick: saveForm,
                    id: 'create-participant-invite',
                    dataTestId: 'create-participant-invite',
                }, width: 'full', children: [isSaving && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'mt-10 mb-24', children: _jsx(FormSections, { sections: formSections }) })] })] }));
};
