import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
import { EnvironmentRole, IAMRole } from '../../types';
export const useRoleTranslations = () => {
    const translate = useLocalizeMessage();
    return useMemo(() => {
        return {
            [EnvironmentRole.CONSENT_BUILDER]: translate('Environment consent builder'),
            [EnvironmentRole.DATA_MANAGER]: translate('Environment data manager'),
            [EnvironmentRole.DATA_REVIEWER]: translate('Environment data reviewer'),
            [EnvironmentRole.DATA_TRANSFER_ADMIN]: translate('Environment data administrator'),
            [EnvironmentRole.ENVIRONMENT_ADMIN]: translate('Environment administrator'),
            [EnvironmentRole.SITE_CLINICAL_RESEARCH_ASSOCIATE]: translate('Clinical Research Associate'),
            [EnvironmentRole.SITE_PRINCIPAL_INVESTIGATOR]: translate('Principal Investigator'),
            [EnvironmentRole.SITE_RESEARCH_COORDINATOR]: translate('Clinical Research Coordinator'),
            [IAMRole.ADMIN]: translate('Administrator'),
            [IAMRole.BUILDER]: translate('Builder'),
            [IAMRole.DATA_MANAGER]: translate('Data manager'),
            [IAMRole.DATA_REVIEWER]: translate('Data reviewer'),
            [IAMRole.DATA_TRANSFER_ADMIN]: translate('Data administrator'),
            [IAMRole.LIBRARIAN]: translate('Librarian'),
            [IAMRole.SITE_USER]: translate('Site user'),
            [IAMRole.TRANSLATOR]: translate('Translator'),
            [IAMRole.USER_AND_SITE_MANAGER]: translate('Study access manager'),
        };
    }, [translate]);
};
