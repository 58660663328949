import { getApiInstance } from '../../../helpers/api.helpers';
import { getCurrentTimezone } from '../../../helpers/date.helpers';
export const onStartVisitConfirmation = async ({ environment, participantId, visitId, }) => {
    await getApiInstance(environment).post(`routes/visit/action`, {
        c_public_user: participantId,
        c_visit: visitId,
        action: 'start',
        c_timezone: getCurrentTimezone(),
    });
};
export const onSkipVisitConfirmation = async ({ environment, participantId, visitId, }) => {
    await getApiInstance(environment).post(`routes/visit/action`, {
        c_public_user: participantId,
        c_visit: visitId,
        action: 'skip',
        c_timezone: getCurrentTimezone(),
    });
};
