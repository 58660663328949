import { jsx as _jsx } from "react/jsx-runtime";
import axios from 'axios';
import { createContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalizeMessage, useToastNotification } from 'libs.nucleus';
import { ErrorCode } from '../../../types';
import { API_ERROR_MESSAGES } from '../types/api_client.constants';
const axiosClient = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...(process.env.SERVICE_VERSION && { 'X-Svc-Version': process.env.SERVICE_VERSION }),
    },
    withCredentials: true,
});
export const ApiClientContext = createContext({
    apiClient: undefined,
});
/**
 * The ApiClientProvider has the intention of being used as a wrapper for the whole application
 * It will provide a unique instance of the axios client to be used across the application
 * It is the intention to use this context through the useApiClient hook
 * that will allow consumers to not care about defining the necessary endpoints depending on the environment
 */
export const ApiClientProvider = ({ children }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const pauseNotifications = useRef(false);
    /**
     * Navigates to the login page when the session is expired
     * It handles tracking the current pathname to redirect the user back to the page they were trying to access
     * and prevents to do a double redirection for cases where the redirection was already executed
     */
    const navigateToLogin = () => {
        if (location.pathname !== '/login' && !location.pathname.includes('/switch-workspace')) {
            navigate(`/login?expired-session=true&redirect=${location.pathname}`);
            // We pause the notifications for half a second to avoid multiple notifications that usually
            // happen when the user is redirected to the login page from multiple requests
            // failing due to an invalid token. only the "session expired" notification will be shown
            pauseNotifications.current = true;
            setTimeout(() => {
                pauseNotifications.current = false;
            }, 500);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleCustomApiError = (errors) => {
        if (!pauseNotifications.current) {
            for (const error of errors) {
                const title = error.metadata?.filename ? `File "${error.metadata?.filename}" failed to import` : 'Bad Request';
                const subtitle = error.message;
                addNotification({
                    title,
                    subtitle,
                    type: 'error',
                });
            }
        }
    };
    useEffect(() => {
        axiosClient.interceptors.response.use((response) => response, (error) => {
            const errorResponseData = error.response?.data;
            const formattedError = JSON.parse(JSON.stringify(error));
            const errorNotification = errorResponseData?.errCode && API_ERROR_MESSAGES[errorResponseData.errCode];
            let errorHandled = false;
            if (errorNotification) {
                if (!pauseNotifications.current) {
                    addNotification({
                        title: translate(errorNotification.title),
                        subtitle: translate(errorNotification.subtitle),
                        type: 'error',
                    });
                }
                errorHandled = true;
                if (errorResponseData.errCode === ErrorCode.ACCESS_DENIED_INVALID_TOKEN) {
                    navigateToLogin();
                }
            }
            else {
                switch (formattedError.status) {
                    case 403:
                        if (!error.config.no403Handling) {
                            if (!pauseNotifications.current) {
                                addNotification({
                                    title: translate('Error'),
                                    subtitle: formattedError.reason ||
                                        translate('You do not have access to this feature. Please contact your administrator.'),
                                    type: 'error',
                                });
                            }
                            errorHandled = true;
                        }
                        break;
                    case 500:
                        if (!error.config.no500Handling) {
                            if (!pauseNotifications.current) {
                                addNotification({
                                    title: translate('Error'),
                                    subtitle: translate('There was an error processing your request. Please try again.'),
                                    type: 'error',
                                });
                            }
                        }
                        errorHandled = true;
                        break;
                    case 401:
                        if (!pauseNotifications.current) {
                            addNotification({
                                title: translate('Unauthorized'),
                                subtitle: translate('You do not have permission to access this resource. Please contact your administrator.'),
                                type: 'error',
                            });
                        }
                        errorHandled = true;
                        navigateToLogin();
                        break;
                    case 404:
                        if (!error.config.no404Handling) {
                            if (!pauseNotifications.current) {
                                addNotification({
                                    title: translate('Error'),
                                    subtitle: translate('The requested resource was not found.'),
                                    type: 'error',
                                });
                            }
                            errorHandled = true;
                        }
                        break;
                    case 400:
                        if (!error.config.no400Handling) {
                            if (errorResponseData.errors && errorResponseData.errors.length) {
                                handleCustomApiError(errorResponseData.errors);
                            }
                            else if (!pauseNotifications.current) {
                                addNotification({
                                    title: translate('Bad Request'),
                                    subtitle: translate('There was an error processing your request. Please try again.'),
                                    type: 'error',
                                });
                            }
                            errorHandled = true;
                        }
                        break;
                    case 413:
                        if (!pauseNotifications.current) {
                            addNotification({
                                title: translate('Bad Request'),
                                subtitle: translate('The file(s) uploaded exceed the maximum size limit'),
                                type: 'error',
                            });
                        }
                        errorHandled = true;
                        break;
                }
            }
            // We inject the errorHandled flag into the error object to indicate if the ApiClient was able to notify the error
            const rejectedError = {
                ...error,
                response: {
                    ...error.response,
                    data: {
                        ...errorResponseData,
                        ...error.data,
                        errorHandled,
                    },
                },
            };
            return Promise.reject(rejectedError);
        });
        return () => {
            axiosClient.interceptors.response.clear();
        };
    }, [location.pathname]);
    return _jsx(ApiClientContext.Provider, { value: { apiClient: axiosClient }, children: children });
};
