import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Button, useLocalizeMessage, Spinner } from 'libs.nucleus';
import { SectionCard, useBooleanState } from 'libs.react';
import { ParticipantCaregiversList } from './participant_caregivers_list.componet';
import { CaregiverStatus, getCaregiversByStatus } from '../../helpers/caregivers.helpers';
import { useLumosAuth } from '../../hooks/use_lumos_auth';
import { useStudyCode } from '../../hooks/use_study_code';
import { useUpdateCaregiverStatus } from '../../hooks/use_update_caregiver_status';
import { LUMOS_PERMISSIONS } from '../../types/permissions.types';
import { CaregiverFormModal } from '../caregiver_form/caregiver_form_modal.component';
import { CaregiversInviteModal } from '../caregiver_form/caregiver_invite_modal.component';
export const ParticipantCaregivers = ({ participant, participantDetailsLoading, }) => {
    const translate = useLocalizeMessage();
    const [isCaregiverFormOpen, setCaregiverFormOpen, setCaregiverFormClose] = useBooleanState();
    const [isCaregiverInviteOpen, setCaregiverInviteOpen, setCaregiverInviteClose] = useBooleanState();
    const [caregiversToInvite, setCaregiversToInvite] = useState([]);
    const { isUserPermitted } = useLumosAuth();
    const { data: studyCode } = useStudyCode();
    const { enableCaregiver, disableCaregiver } = useUpdateCaregiverStatus(participant);
    const handleView = (caregiver) => {
        setCaregiversToInvite([caregiver]);
        setCaregiverInviteOpen();
    };
    if (!participant || participantDetailsLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (!participant.c_caregiver_relationship || isEmpty(participant.c_caregiver_relationship?.c_caregivers_info)) {
        return (_jsxs(_Fragment, { children: [_jsx(SectionCard, { children: _jsxs("section", { className: 'flex flex-col items-center justify-center p-10 m-10', children: [_jsx("p", { "data-testid": 'empty-state-message', className: 'mb-8 text-sm text-secondary', children: translate('info.participant_caregiver.empty') }), isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_ADD_CAREGIVERS) && (_jsx(Button, { label: translate('action.caregiver.add'), variant: 'primary', onClick: setCaregiverFormOpen, dataTestId: 'add-caregiver-empty-state-button' }))] }) }), _jsx(CaregiverFormModal, { participant: participant, isModalOpen: isCaregiverFormOpen, onClose: setCaregiverFormClose, onView: handleView, dataTestId: 'add-caregiver-empty-state-modal-window' })] }));
    }
    const { c_caregiver_assignments, c_caregivers_info } = participant.c_caregiver_relationship;
    const activeCaregiverList = getCaregiversByStatus(c_caregivers_info, c_caregiver_assignments, [
        CaregiverStatus.ENABLED,
        CaregiverStatus.PENDING,
    ]);
    const canHaveMoreCaregivers = activeCaregiverList.length < 7;
    return (_jsxs("div", { className: 'flex flex-col', children: [_jsx("div", { className: 'flex justify-end mb-4', children: isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_ADD_CAREGIVERS) && canHaveMoreCaregivers && (_jsx(Button, { label: translate('action.caregiver.add'), variant: 'primary', onClick: setCaregiverFormOpen, dataTestId: 'add-caregiver-button' })) }), _jsx(ParticipantCaregiversList, { onView: handleView, studyCode: studyCode, participant: participant, onDisable: disableCaregiver, onEnable: enableCaregiver, canHaveMoreCaregivers: canHaveMoreCaregivers }), _jsx(CaregiverFormModal, { participant: participant, isModalOpen: isCaregiverFormOpen, onClose: setCaregiverFormClose, onView: handleView, dataTestId: 'add-caregiver-modal-window' }), _jsx(CaregiversInviteModal, { isModalOpen: isCaregiverInviteOpen, caregivers: caregiversToInvite, onClose: setCaregiverInviteClose, participant: participant })] }));
};
