import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, Link, Text } from 'libs.nucleus';
const PdfLink = (resource) => (_jsx(Link, { target: '_blank', href: resource.c_file_content?.url, children: _jsxs("div", { className: 'flex gap-2 items-center', children: [_jsx(Icon, { size: 'sm', color: 'red', name: 'fileDownloadSolid' }), " ", _jsx(Text, { children: resource.c_title }), ' ', _jsx(Icon, { size: 'sm', color: 'primary', name: 'externalLink' })] }) }));
export const getResourceItem = (resource, handleResourceClick) => {
    const resourceTypeMap = {
        c_web_link: (_jsxs(Link, { target: '_blank', href: resource.c_link, children: [resource.c_title, " ", _jsx(Icon, { size: 'sm', color: 'primary', name: 'externalLink' })] })),
        c_pdf: PdfLink(resource),
        c_site_training_pdf: PdfLink(resource),
        c_html: (_jsx(Link, { to: '#', onClick: () => handleResourceClick(resource), children: resource.c_title })),
        c_string: (_jsx(Link, { to: '#', onClick: () => handleResourceClick(resource), children: resource.c_title })),
    };
    return resourceTypeMap[resource.type] || resource.c_title;
};
