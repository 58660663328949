import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'libs.react';
import { useLumosTrainingProgress } from '../use_lumos_training/use_lumos_training_progress.hook';
export const useLumosTrainingNavigation = () => {
    const { entity, isInternalUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [cookies] = useCookies(['disableSiteTraining']);
    const siteTrainingIsRequired = entity?.configuration.siteTrainingRequired;
    const { allModulesCompleted } = useLumosTrainingProgress();
    useEffect(() => {
        /**
         * `disableSiteTraining` cookie can be set to `true`
         * If the user is an internal user and cookie is set to true, then training is disabled.
         * For testing purposes only.
         * nb: react-cookie coerces "true" as set from browser to true
         */
        const isTrainingDisabled = cookies.disableSiteTraining === true && isInternalUser;
        if (siteTrainingIsRequired && !allModulesCompleted && !isTrainingDisabled) {
            navigate('/site/training');
        }
    }, [navigate, siteTrainingIsRequired, allModulesCompleted, isInternalUser, cookies.disableSiteTraining]);
};
