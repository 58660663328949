import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { ModalWindow, useLocalizeMessage } from 'libs.nucleus';
import { RadioGroupSection } from '../index';
export const SwitchWorkspaceModal = ({ entities, onClose, onSubmit }) => {
    const options = entities.map((entity) => ({
        label: entity.displayName,
        value: entity.id.toString(),
        dataTestId: `workspace-${entity.id}`,
    }));
    const translate = useLocalizeMessage();
    const [selectedEntity, setSelectedEntity] = useState(options[0].value);
    const handleSelectionChange = (value) => {
        setSelectedEntity(value);
    };
    const handleSubmit = () => {
        const entity = entities.find((e) => e.id.toString() === selectedEntity);
        entity && onSubmit(entity);
    };
    return (_jsx(ModalWindow, { isOpen: true, dataTestId: 'switch-workspace-modal', title: translate('Choose Workspace'), subtitle: translate('You have access to the following sponsors'), closeWindow: () => null, footerPrimaryActionButton: {
            label: translate('Continue'),
            onClick: handleSubmit,
            dataTestId: 'switch-workspace-modal-continue-button',
        }, footerSecondaryActionButtons: [
            {
                label: translate('Cancel'),
                onClick: onClose,
                dataTestId: 'switch-workspace-modal-cancel-button',
            },
        ], width: 'full', children: _jsx("div", { className: 'flex items-center justify-center size-full', children: _jsx("div", { className: 'w-input-lg max-h-full', children: _jsx(RadioGroupSection, { dataTestId: 'workspace-radio-group', options: options, selectedValue: selectedEntity, onSelectionChange: handleSelectionChange }) }) }) }));
};
