import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssetRole, LocalizationStatus } from 'libs.localization.common';
import { useL10nMapFetch } from './use_l10nmap.hook';
import { useLibraryData } from './use_library_data.hook';
import { useNotificationsData } from './use_notifications_data.hook';
import { useResourcesData } from './use_resources_data.hook';
import { useResourcesPdfData } from './use_resourcespdf_data.hook';
import { useStudyStringsData } from './use_study_strings_data.hook';
// TODO: reuse for TranslationWorkbenchProvider
export const useStudyLocales = (study) => {
    const { studyId } = useParams();
    const [l10nMap, setL10nMap] = useState();
    const { data: l10nMapData } = useL10nMapFetch(study.l10nMapResourceId);
    const locales = Array.from(new Set([...study.participantLocales, ...study.siteLocales]));
    useEffect(() => {
        if (!study.l10nMapResourceId) {
            setL10nMap([]);
        }
    }, [study.l10nMapResourceId]);
    useEffect(() => {
        if (l10nMapData) {
            setL10nMap(l10nMapData);
        }
    }, [l10nMapData]);
    const { data: libraryAssets } = useLibraryData(study.getAllActivities(), study.participantLocales, study.siteLocales, l10nMap);
    const { data: resourcesAssets } = useResourcesData(study, study.participantLocales, study.siteLocales);
    const { data: resourcesPdfAssets } = useResourcesPdfData(study, study.participantLocales, study.siteLocales);
    const { data: studyAssets } = useStudyStringsData(study, study.participantLocales, study.siteLocales);
    const { data: notificationsAssets } = useNotificationsData(studyId, study.participantLocales, study.siteLocales);
    const allAssets = {
        ...libraryAssets,
        ...resourcesAssets,
        ...resourcesPdfAssets,
        ...studyAssets,
        ...notificationsAssets,
    };
    // TODO: Commenting for now, will implement it later
    // const getLocalizationStatusByRole = (locale: Locale, role: AssetRole) => {
    //   const relevantAssets = Object.values(allAssets).filter((asset) => asset.role === role);
    //   if (relevantAssets.length === 0) {
    //     return LocalizationStatus.NOT_STARTED;
    //   }
    //   const statuses = relevantAssets.map((asset) => {
    //     const entry = l10nMap?.find(
    //       (entry) => entry.translationWorkbenchId === asset.translationWorkbenchId && entry.locale === locale
    //     );
    //     return entry ? entry.status : LocalizationStatus.NOT_STARTED;
    //   });
    //   if (statuses.every((status) => status === LocalizationStatus.READY)) {
    //     return LocalizationStatus.READY;
    //   }
    //   // TODO: implement it later
    //   // if (statuses.some((status) => status === LocalizationStatus.IN_REVIEW)) {
    //   //   return LocalizationStatus.IN_REVIEW;
    //   // }
    //   // if (statuses.some((status) => status === LocalizationStatus.IN_PROGRESS)) {
    //   //   return LocalizationStatus.IN_PROGRESS;
    //   // }
    //   return LocalizationStatus.NOT_STARTED;
    // };
    // const _getLocalizationStatus = (locale: Locale) => {
    //   const siteStatus = study.siteLocales.includes(locale)
    //     ? getLocalizationStatusByRole(locale, AssetRole.SITE)
    //     : LocalizationStatus.NOT_STARTED;
    //   const participantStatus = study.participantLocales.includes(locale)
    //     ? getLocalizationStatusByRole(locale, AssetRole.PARTICIPANT)
    //     : LocalizationStatus.NOT_STARTED;
    //   if (siteStatus === LocalizationStatus.READY && participantStatus === LocalizationStatus.READY) {
    //     return LocalizationStatus.READY;
    //   }
    //   // TODO: implement it later
    //   // if (siteStatus === LocalizationStatus.IN_REVIEW || participantStatus === LocalizationStatus.IN_REVIEW) {
    //   //   return LocalizationStatus.IN_REVIEW;
    //   // }
    //   // if (siteStatus === LocalizationStatus.IN_PROGRESS || participantStatus === LocalizationStatus.IN_PROGRESS) {
    //   //   return LocalizationStatus.IN_PROGRESS;
    //   // }
    //   return LocalizationStatus.NOT_STARTED;
    // };
    const isAllLocalesAssetsReadyByRole = (locale, role) => {
        return Object.values(allAssets)
            .filter((asset) => asset.role === role)
            .every((asset) => l10nMap?.some((entry) => entry.translationWorkbenchId === asset.translationWorkbenchId &&
            entry.locale === locale &&
            entry.status === LocalizationStatus.READY));
    };
    const getLocalizationStatus = (locale) => {
        const isLocaleReady = (role, locales) => locales.includes(locale) ? isAllLocalesAssetsReadyByRole(locale, role) : true;
        const isAllSiteAssetsReady = isLocaleReady(AssetRole.SITE, study.siteLocales);
        const isAllParticipantAssetsReady = isLocaleReady(AssetRole.PARTICIPANT, study.participantLocales);
        return isAllSiteAssetsReady && isAllParticipantAssetsReady;
    };
    return {
        locales,
        getLocalizationStatus,
    };
};
