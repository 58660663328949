import { AclScope } from 'libs.react';
import { LUMOS_PERMISSIONS } from '../types/permissions.types';
export const SITE_USER_PERMISSION_MAP = {
    [AclScope.CORTEX_ROLE_AXON_SITE_USER]: Object.values(LUMOS_PERMISSIONS),
    [AclScope.CORTEX_ROLE_AXON_SITE_INVESTIGATOR]: Object.values(LUMOS_PERMISSIONS),
    [AclScope.CORTEX_ROLE_AXON_SITE_MONITOR]: [
        LUMOS_PERMISSIONS.PARTICIPANTS_LIST,
        LUMOS_PERMISSIONS.PARTICIPANTS_LIST_VISITS,
        LUMOS_PERMISSIONS.PARTICIPANTS_LIST_ACTIVITIES,
        LUMOS_PERMISSIONS.PARTICIPANTS_LIST_CAREGIVERS,
        LUMOS_PERMISSIONS.PARTICIPANTS_LIST_CONSENTS,
    ],
};
