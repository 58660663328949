import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Select, useLocalizeMessage } from 'libs.nucleus';
import { AuthContext } from 'libs.react';
import { IrbContext } from '../../contexts';
import { hasExpired } from '../../utils/environment_utils';
function isDeployed(env) {
    // TODO: Is there a better way to determine if an environment has been deployed?
    return env.workflows?.[0]?.status === 'completed' || env?.metadata?.packages?.length;
}
const defaultSelectedEnv = { label: '', value: '' };
export const EnvironmentSelector = ({ onEnvSelection, selectedStudyVersion }) => {
    const { getEnvironments } = useContext(IrbContext);
    const { user } = useContext(AuthContext);
    const translate = useLocalizeMessage();
    const allEnvironments = getEnvironments();
    const [selectedEnv, setSelectedEnv] = useState(defaultSelectedEnv);
    const environments = useMemo(() => allEnvironments.filter((env) => !hasExpired(env) &&
        isDeployed(env) &&
        env.metadata.studyConfigVersion?.toString() === selectedStudyVersion?.version?.toString() &&
        user?.id === env.ownerId), [allEnvironments, selectedStudyVersion, user]);
    useEffect(() => {
        const defaultEnv = environments.find((env) => !hasExpired(env));
        setSelectedEnv(defaultEnv ? { label: defaultEnv.name, value: defaultEnv.id } : defaultSelectedEnv);
        onEnvSelection(defaultEnv);
    }, [environments, onEnvSelection]);
    const environmentsMap = useMemo(() => new Map(environments.map((env) => [env.id, env])), [environments]);
    const environmentsItems = useMemo(() => environments.map((env) => ({
        label: env.name,
        value: env.id,
    })), [environments]);
    const handleChange = useCallback((selectedOption) => {
        setSelectedEnv(selectedOption);
        onEnvSelection(environmentsMap.get(selectedOption.value));
    }, [environmentsMap, onEnvSelection]);
    return (_jsx(Select, { options: environmentsItems, value: selectedEnv, onChange: handleChange, width: 'lg', required: true, label: translate('Test environment'), description: translate('The Study version will be taken from this test environment.'), errorMessage: translate('Error: No test environments available for the selected version. Please create one.'), hasError: !environments.length && !!selectedStudyVersion }));
};
