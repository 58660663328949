import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Checkbox, FormSections, InlineNotification, ModalWindow, Spinner, useLocalizeMessage, } from 'libs.nucleus';
import { EnvironmentSelector } from '../environment_selector';
import { InputFieldLabel } from '../input_field_label';
import { DeviceSelection } from './device_selection.component';
import { LocalesSelection } from './locales_selection.component';
import { StudyVersionsDropdown } from './study_versions_dropdown.component';
export const CriteriaSelectionModal = ({ onModalClose, onHandleCreatePackage, isProcessing, }) => {
    const { studyId } = useParams();
    const { logEvent } = useContext(FirebaseAppContext);
    const translate = useLocalizeMessage();
    const [selectedEnvironment, setSelectedEnvironment] = useState(undefined);
    const [isCoverPageIncluded, setIsCoverPageIncluded] = useState(true);
    const [selectedLocales, setSelectedLocales] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState('web');
    const [selectedStudyVersion, setSelectedStudyVersion] = useState(undefined);
    const [selectedViewport, setSelectedViewport] = useState({
        width: 1920,
        height: 1080,
        scale: 0.5,
    });
    const handleModalCancel = useCallback(() => {
        logEvent('submission_package_modal_cancel', {
            studyId,
        });
        onModalClose();
    }, [studyId]);
    const handleModalSubmit = useCallback(() => {
        logEvent('submission_package_modal_submit', {
            studyId,
            selectedLocales: selectedLocales.join(', '),
            selectedDevice,
            selectedEnvironment: selectedEnvironment.name,
            isCoverPageIncluded,
        });
        onHandleCreatePackage({
            selectedLocales,
            selectedDevice,
            selectedEnvironment,
            isCoverPageIncluded,
            selectedViewport,
        });
    }, [selectedLocales, selectedDevice, selectedEnvironment, isCoverPageIncluded, selectedViewport]);
    const handleSelectedStudyVersion = useCallback((studyVersion) => {
        logEvent('submission_package_modal_select_study_version', {
            studyId,
            studyVersionName: studyVersion?.metadata?.name,
            studyVersion: studyVersion?.externalVersion,
        });
        setSelectedStudyVersion(studyVersion);
    }, []);
    const handleSelectedEnvironment = useCallback((env) => {
        logEvent('submission_package_modal_select_environment', {
            studyId,
            environmentName: env?.name,
            environmentId: env?.id,
        });
        setSelectedEnvironment(env);
    }, []);
    const handleSelectedDevice = useCallback((device, viewport) => {
        logEvent('submission_package_modal_select_device', {
            studyId,
            device,
            viewport,
        });
        setSelectedDevice(device);
        setSelectedViewport(viewport);
    }, []);
    const handleSelectedLocales = useCallback((locales) => {
        logEvent('submission_package_modal_select_locales', {
            studyId,
            locales: locales.join(', '),
        });
        setSelectedLocales(locales);
    }, []);
    const closeButton = {
        label: translate('Cancel'),
        onClick: handleModalCancel,
    };
    const doneButton = {
        label: translate('Create'),
        disabled: isProcessing || (selectedLocales.length === 0 && !!selectedEnvironment),
        onClick: handleModalSubmit,
    };
    const formSections = useMemo(() => [
        {
            title: translate('Settings'),
            content: (_jsxs("div", { className: 'flex gap-6 flex-col', children: [_jsx("div", { children: _jsx(StudyVersionsDropdown, { setSelectedStudyVersion: handleSelectedStudyVersion, selectedStudyVersion: selectedStudyVersion }) }), _jsx("div", { children: _jsx(EnvironmentSelector, { onEnvSelection: handleSelectedEnvironment, selectedStudyVersion: selectedStudyVersion }) }), _jsxs("div", { children: [_jsx(InputFieldLabel, { label: translate('Device Size') }), _jsx(DeviceSelection, { onDeviceSelection: handleSelectedDevice })] }), _jsx("div", { children: _jsx(Checkbox, { label: translate('Include cover page'), description: translate('The cover page is a predefined template with metadata like Study name and ID, build version, device size, creation date, created by and locales selected.'), onChange: (value) => setIsCoverPageIncluded(value), checked: isCoverPageIncluded }) })] })),
        },
        {
            title: translate('Locales'),
            description: translate('For locales with assets that are not in a “Ready” status, the incomplete ones will not be included in the review package.'),
            content: (_jsxs("div", { className: 'flex gap-6 flex-col', children: [_jsx(InputFieldLabel, { label: translate('Available locales') }), _jsx(LocalesSelection, { onLocaleSelection: handleSelectedLocales, selectedStudyVersion: selectedStudyVersion })] })),
        },
    ], [isCoverPageIncluded, selectedStudyVersion]);
    return (_jsx(ModalWindow, { title: translate('Create review package'), isOpen: true, closeWindow: onModalClose, footerPrimaryActionButton: doneButton, footerSecondaryActionButtons: [closeButton], position: 'top', width: 'full', children: _jsxs("section", { className: 'px-8 py-2', children: [_jsx(InlineNotification, { title: translate('Note'), subtitle: translate('The review package will have all screens that a participant could see. Examples are : Sign-in, Authentication, Schedule, Activities, Notifications, Profile, Televisit and Resources.'), type: 'info' }), _jsx("hr", { className: 'mb-4' }), _jsx(FormSections, { sections: formSections }), isProcessing && (_jsx("div", { className: 'absolute inset-0 bg-neutral-900 bg-opacity-50 flex items-center justify-center z-10', children: _jsx(Spinner, {}) }))] }) }));
};
