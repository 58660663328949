import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
import { useRoleTranslations } from '../../features';
import { useDateTime } from '../../hooks';
import { ListInformation } from '../list_information';
export const UserDetailsSection = ({ entityId, user }) => {
    const translate = useLocalizeMessage();
    const roleTranslations = useRoleTranslations();
    const { parseDateTimeShort } = useDateTime();
    const listItems = useMemo(() => {
        const entityAccess = user.entitiesAccess.find((group) => group.entityId === entityId);
        const roles = entityAccess?.roleGroups.map((role) => roleTranslations[role]);
        return [
            { title: translate('First name'), dataTestId: 'profile-first-name', description: user.first },
            { title: translate('Last name'), dataTestId: 'profile-last-name', description: user.last },
            { title: translate('Roles'), dataTestId: 'profile-roles', description: roles },
            { title: translate('Email'), dataTestId: 'profile-email', description: user.email },
            { title: translate('Created'), dataTestId: 'profile-email', description: parseDateTimeShort(user.createdAt) },
        ];
    }, [user]);
    return _jsx(ListInformation, { items: listItems });
};
