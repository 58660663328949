import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
export const useEnvironmentCortexToken = () => {
    const { entityId } = useContext(AuthContext);
    const { studyId } = useParams();
    const { post } = useApiClient(ApiClientService.MOM);
    return useMutation({
        mutationKey: ['cortexToken'],
        mutationFn: async ({ envId }) => {
            const { data } = await post(`/v1/entities/${entityId}/studies/${studyId}/environments/${envId}/cortexToken`);
            return data.data;
        },
        retry: false,
    });
};
