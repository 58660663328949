import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button, useLocalizeMessage, Spinner } from 'libs.nucleus';
import { useDownloadFromGCS } from '../../hooks';
export const DownloadReportAction = ({ url, fileName }) => {
    const { studyId } = useParams();
    const { logEvent } = useContext(FirebaseAppContext);
    const translate = useLocalizeMessage();
    const { downloadFileFromGCSPath, isDownloading } = useDownloadFromGCS();
    const handleDownloadFile = useCallback(() => {
        logEvent('submission_package_download', {
            studyId,
            url,
            fileName,
        });
        if (url) {
            downloadFileFromGCSPath({ url, fileName });
        }
    }, [url, fileName]);
    if (!url) {
        return null;
    }
    return (_jsx("div", { className: 'flex justify-center', children: _jsx(Button, { variant: 'transparent', label: translate('Download'), disabled: isDownloading, onClick: handleDownloadFile, content: isDownloading ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm' }) })) : undefined }) }));
};
