export const DATA_MANAGEMENT_APP_FLAGS = {
    view_reports: {
        id: 'view_reports',
        value: false,
        description: 'Enables the view reports feature',
        owner: 'Clinical Data',
        name: 'View Reports',
        extra: { section: 'Data', dataTestId: 'view-reports-flag' },
    },
    build_reports: {
        id: 'build_reports',
        value: false,
        description: 'Enables the build reports feature',
        owner: 'Clinical Data',
        name: 'Build Reports',
        extra: { section: 'Data', dataTestId: 'build-reports-flag' },
    },
};
