import { jsx as _jsx } from "react/jsx-runtime";
import { QueryClientContext, useMutation, useQuery } from '@tanstack/react-query';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';
import { useLocalizeMessage, useDialog, useToastNotification } from 'libs.nucleus';
import { ApiClientService, AuthContext, LibraryEndpoint, LibraryResourceStatus, useApiClient, } from 'libs.react';
const missingProvider = () => {
    throw Error('Missing the studies provider');
};
export const StudiesContext = createContext({
    createStudy: missingProvider,
    isLoading: true,
    isUpdatingConfiguration: false,
    removeStudy: missingProvider,
    showStudyUpdatedWarning: missingProvider,
    studies: [],
    updateStudy: missingProvider,
    updateStudyMetadata: missingProvider,
});
export const StudiesProvider = ({ children }) => {
    const { entityId, user } = useContext(AuthContext);
    const queryClient = useContext(QueryClientContext);
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const dialog = useDialog();
    const momClient = useApiClient(ApiClientService.MOM);
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const [isUpdatingConfiguration, setIsUpdatingConfiguration] = useState(false);
    const retrieveStudies = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies`);
        return response.data;
    };
    const { data: studies, isLoading } = useQuery({
        queryKey: ['entities', entityId, 'studies'],
        queryFn: retrieveStudies,
        enabled: !!user && !!entityId,
    });
    const createStudyMutation = useMutation({
        mutationKey: ['createStudy'],
        mutationFn: (study) => momClient.post(`/v1/entities/${entityId}/studies`, {
            metadata: { description: study.description, protocolNumber: study.protocolNumber },
            name: study.name,
            spec: study.toRosetta(),
        }, { no400Handling: true }),
        onSuccess: () => queryClient?.invalidateQueries({ queryKey: ['entities', entityId, 'studies'] }),
    });
    const createStudy = useCallback(async (study) => {
        try {
            const { data: response } = await createStudyMutation.mutateAsync(study);
            return String(response.data.id);
        }
        catch (error) {
            // @ts-expect-error - AxiosError type is not being caught by instanceof
            if (error.name === 'AxiosError' && !error.response?.data?.errorHandled) {
                const errorMessage = error.response?.data?.error;
                const subtitle = errorMessage
                    ? translate('The study could not be created: \n{message}', { message: errorMessage })
                    : translate('The study could not be created. Please try again.');
                addNotification({ title: translate('Error creating study'), subtitle, type: 'error' });
            }
            console.error('Error creating study', error);
            throw error;
        }
    }, [createStudyMutation]);
    const removeStudyMutation = useMutation({
        mutationKey: ['removeStudy'],
        mutationFn: (studyId) => momClient.delete(`/v1/entities/${entityId}/studies/${studyId}`),
        onSuccess: () => queryClient?.invalidateQueries({ queryKey: ['entities', entityId, 'studies'] }),
        onError: (error) => {
            if (!error.response?.data.errorHandled) {
                addNotification({
                    title: translate('Error deleting study'),
                    subtitle: translate('An error occurred while deleting the study'),
                    type: 'error',
                });
            }
        },
    });
    /**
     * Given a studyId, it will call MOM service to remove the study
     */
    const removeStudy = useCallback(async (studyId) => {
        removeStudyMutation.mutate(studyId);
    }, [removeStudyMutation]);
    const showStudyUpdatedWarning = async (showAwayMessage = false) => {
        const body = showAwayMessage
            ? translate('The study configuration has been updated while you were away. Please refresh the page to see the changes.')
            : translate('The study configuration has been updated by another user. Please refresh the page to see the changes.');
        await dialog.open({
            title: translate('Study configuration updated'),
            body,
            actionButtons: [{ label: translate('Refresh') }],
        });
        window.location.reload();
    };
    const updateStudy = useThrottledCallback(async (studyId, studyConfig) => {
        setIsUpdatingConfiguration(true);
        const { data: response } = await libraryClient.put(`${LibraryEndpoint.GET_STUDY_CONFIGS}/${studyId}`, { data: studyConfig.toRosetta() });
        setIsUpdatingConfiguration(false);
        if (response.data.status === LibraryResourceStatus.PUBLISHED) {
            showStudyUpdatedWarning();
        }
    }, 500);
    const updateStudyMetadata = useCallback((studyId, { name, ...metadata }) => {
        momClient.put(`/v1/entities/${entityId}/studies/${studyId}`, { name, metadata });
    }, [entityId]);
    const value = useMemo(() => ({
        createStudy,
        isLoading: isLoading || removeStudyMutation.isPending,
        isUpdatingConfiguration,
        removeStudy,
        showStudyUpdatedWarning,
        studies: studies || [],
        updateStudy,
        updateStudyMetadata,
    }), [createStudy, entityId, isLoading, removeStudy, showStudyUpdatedWarning, studies, updateStudy, updateStudyMetadata]);
    return _jsx(StudiesContext.Provider, { value: value, children: children });
};
