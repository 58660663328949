import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApiClient, ApiClientService, AuthContext } from 'libs.react';
export const DataReportsContext = createContext({
    activeReportsTab: 0,
    setActiveReportsTab: () => { },
    drilldownLevel: 0,
    setDrilldownLevel: () => { },
    libRequestsParams: {},
    selectedTestEnv: { label: '', value: '' },
    setSelectedTestEnv: () => { },
    environments: [],
    isLoadingEnvironments: false,
    hasProductionEnvironments: false,
    setStudyId: () => { },
    testEnvironmentsOptions: [],
});
export const DataReportsProvider = ({ children }) => {
    const momClient = useApiClient(ApiClientService.MOM);
    const { entityId } = useContext(AuthContext);
    const [drilldownLevel, setDrilldownLevel] = useState(0);
    const [activeReportsTab, setActiveReportsTab] = useState(0);
    const [selectedTestEnv, setSelectedTestEnv] = useState({ label: '', value: '' });
    const [studyId, setStudyId] = useState('');
    const [searchParams] = useSearchParams();
    const env = searchParams.get('env');
    const getEnvsData = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies/${studyId}/environments`, {
            params: { withWorkflows: true },
        });
        return response.data;
    };
    const { data: environments = [], isLoading: isLoadingEnvironments } = useQuery({
        queryKey: ['entities', entityId, 'studies', studyId, 'environments'],
        queryFn: getEnvsData,
        enabled: !!entityId && !!studyId,
    });
    const hasProductionEnvironments = useMemo(() => environments.some((env) => env.deployment.isProd), [environments]);
    const testEnvironmentsOptions = useMemo(() => environments
        ?.filter((env) => env.deployment.isProd === false)
        .map((env) => ({
        label: env.name,
        value: env.cortexId,
    })), [environments]);
    useEffect(() => {
        if (!selectedTestEnv.value && environments?.length > 0) {
            setSelectedTestEnv(testEnvironmentsOptions?.[0] ?? { label: '', value: '' });
        }
    }, [environments]);
    const libRequestsParams = useMemo(() => {
        if (env === 'prod') {
            return { latest: true, status: 'PUBLISHED' };
        }
        if (!env && activeReportsTab === 1) {
            return { latest: true, status: 'PUBLISHED' };
        }
        return {};
    }, [activeReportsTab, env]);
    const value = useMemo(() => ({
        activeReportsTab,
        setActiveReportsTab,
        drilldownLevel,
        setDrilldownLevel,
        libRequestsParams,
        selectedTestEnv,
        setSelectedTestEnv,
        environments,
        isLoadingEnvironments,
        hasProductionEnvironments,
        setStudyId,
        testEnvironmentsOptions,
    }), [
        activeReportsTab,
        setActiveReportsTab,
        drilldownLevel,
        setDrilldownLevel,
        libRequestsParams,
        selectedTestEnv,
        setSelectedTestEnv,
        environments,
        isLoadingEnvironments,
        hasProductionEnvironments,
        setStudyId,
        testEnvironmentsOptions,
    ]);
    return _jsx(DataReportsContext.Provider, { value: value, children: children });
};
