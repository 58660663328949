import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useContext, useEffect, useMemo } from 'react';
import { Link, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { SideNav, Spinner, useLocalizeMessage, Text, Icon } from 'libs.nucleus';
import { NotFoundPage, useAppBanner, useBooleanState } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts/study_configuration/study_configuration.context';
import { useNavigation } from '../../hooks/use_navigation/use_navigation.hook';
import { StudyUpgradeModal } from '../study_upgrade_modal/study_upgrade_modal.component';
export const StudyWrapper = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { navItems } = useNavigation();
    const match = useMatch('/studies/:studyId/:section/*');
    const { showAppBanner, clearAppBanner } = useAppBanner();
    const translate = useLocalizeMessage();
    const { currentStudy, isInitialized, isLoading, isUpgradeAvailable } = useContext(StudyConfigurationContext);
    const [isUpgradeStudyModalOpen, openUpgradeStudyModal, closeUpgradeStudyModal] = useBooleanState();
    useEffect(() => {
        validateRoute();
        if (isUpgradeAvailable) {
            showAppBanner({
                variant: 'warning',
                content: translate('Study configuration is locked. Please accept the required update to continue editing.'),
                actionButton: {
                    label: translate('Learn more'),
                    content: (_jsxs("div", { className: 'flex items-center gap-1 text-text-primary', children: [_jsx(Text, { size: 'xs', children: translate('Learn more') }), _jsx(Icon, { name: 'arrowForward', color: 'current', size: 16 })] })),
                    onClick: openUpgradeStudyModal,
                },
            });
        }
        return () => {
            clearAppBanner();
        };
    }, [currentStudy, isInitialized, isUpgradeAvailable]);
    /**
     * Validates the current study route
     *  - If there is no route match means the route contains only a possible studyId without a subsection then we
     * redirect to the build page
     */
    const validateRoute = () => {
        if (!isInitialized && !match) {
            navigate('build');
        }
    };
    const studyCardProps = useMemo(() => {
        return { protocolNumber: currentStudy.protocolNumber, studyName: currentStudy.name };
    }, [currentStudy.name, currentStudy.protocolNumber]);
    return (_jsxs("div", { className: 'flex grow overflow-hidden', children: [_jsx("div", { className: 'flex flex-col bg-neutral-75', "data-testid": 'side-nav-menu', children: currentStudy.name !== '' && (_jsx(SideNav, { activeLinkPath: pathname, items: navItems, LinkComponent: Link, studyCard: studyCardProps })) }), _jsxs("div", { className: 'flex h-full w-full grow flex-col overflow-hidden bg-white', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), !currentStudy.name && isInitialized && _jsx(NotFoundPage, {}), currentStudy.name && (_jsx(Suspense, { fallback: _jsx("section", { className: 'flex items-center justify-center h-full', children: _jsx(Spinner, {}) }), children: _jsx(Outlet, {}) }))] }), isUpgradeStudyModalOpen && _jsx(StudyUpgradeModal, { onModalClose: closeUpgradeStudyModal })] }));
};
