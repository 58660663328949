import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, useLocalizeMessage, Badge, H6, Card } from 'libs.nucleus';
import { ListInformation } from 'libs.react';
import { getCaregiverDisplayNameCount, getCaregiverStatus, CaregiverStatus } from '../../helpers/caregivers.helpers';
import { getParticipantNumber } from '../../helpers/participants.helpers';
import { useLumosAuth } from '../../hooks/use_lumos_auth';
import { useOrgContext } from '../../hooks/use_org_context';
import { LUMOS_PERMISSIONS } from '../../types/permissions.types';
import { CaregiverIcon } from '../svgIcons/caregiver.icon';
export const ParticipantCaregiversListItem = ({ caregiver, studyCode, caregiverAssignments, onView, onDisable, onEnable, canHaveMoreCaregivers, }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const { isUserPermitted } = useLumosAuth();
    const handleClick = () => {
        onView(caregiver);
    };
    const handleDisable = () => {
        onDisable(caregiver);
    };
    const handleEnable = () => {
        onEnable(caregiver);
    };
    const detailItems = [
        {
            hidden: org?.c_no_pii,
            title: translate('generic.email'),
            description: _jsx("p", { className: 'nucleus-text-sm text-text-secondary', children: caregiver.c_email }),
        },
        {
            title: translate('label.invite_code'),
            description: caregiver.c_public_user.c_access_code && (_jsxs("div", { children: [_jsxs("p", { className: 'nucleus-text-sm text-text-secondary', children: [translate('label.study_code'), ": ", _jsx("span", { children: studyCode })] }), _jsxs("p", { className: 'nucleus-text-sm text-text-secondary', children: [translate('label.invite_code'), ": ", _jsx("span", { children: caregiver.c_public_user.c_access_code })] })] })),
            ...(caregiver.c_public_user.c_access_code
                ? {
                    actionContent: _jsx(Button, { onClick: handleClick, label: translate('generic.view'), variant: 'transparent' }),
                }
                : {}),
        },
    ];
    const displayName = translate('label.caregiver.count', {
        count: getCaregiverDisplayNameCount(caregiver.c_caregiver_display_name),
    });
    const status = getCaregiverStatus(caregiver, caregiverAssignments);
    const canEnableCaregiver = caregiver.c_public_user.c_access_code &&
        status === CaregiverStatus.DISABLED &&
        isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_ENABLE_CAREGIVERS);
    const canDisableCaregiver = caregiver.c_public_user.c_access_code &&
        status === CaregiverStatus.ENABLED &&
        isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_DISABLE_CAREGIVERS);
    const canInviteCaregiver = (!caregiver.c_public_user.c_access_code || !caregiver.c_public_user.c_access_code) &&
        isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_EMAIL_CAREGIVER_INVITE);
    return (_jsxs(Card.Content, { className: 'w-full flex flex-col gap-y-4 bg-neutral-50 p-4 border border-gray-200 rounded-lg shadow-sm sm:p-8', children: [_jsxs("div", { className: 'w-full flex flex-row justify-between', children: [_jsxs("div", { className: 'flex flex-row flex-none w-1/4 justify-start gap-x-2', children: [_jsx(CaregiverIcon, { className: 'text-text-secondary' }), _jsxs("div", { children: [_jsx("div", { className: 'flex flex-row gap-x-2', children: [CaregiverStatus.ENABLED, CaregiverStatus.PENDING].includes(status) ? (_jsx(H6, { children: displayName })) : (_jsxs(_Fragment, { children: [_jsx("h6", { className: 'nucleus-text-h6 text-text-secondary', children: displayName }), _jsx(Badge, { dataTestId: 'disabled-caregiver-badge', variant: 'danger', label: translate('generic.disabled') })] })) }), _jsxs("p", { className: 'nucleus-text-sm text-text-secondary', children: [translate('generic.id'), ": ", _jsx("span", { children: getParticipantNumber(caregiver.c_public_user) })] })] })] }), _jsx("div", { className: 'flex-none w-3/4 bg-white', children: _jsx(ListInformation, { dataTestId: 'participant-caregiver-details-list-information', items: detailItems, useDefaultEmptyDescription: true }) })] }), _jsxs("div", { className: 'w-full flex flex-row justify-end gap-x-2', children: [isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_UPDATE_CAREGIVERS) && (_jsx(Button, { disabled: (!!caregiver.c_public_user.c_access_code && status === CaregiverStatus.DISABLED) ||
                            org?.c_no_pii ||
                            !!caregiver.c_public_user.c_account, label: translate('generic.edit'), variant: 'secondary' })), canDisableCaregiver && (_jsx(Button, { label: translate('generic.disable'), variant: 'secondary', onClick: handleDisable })), canEnableCaregiver && canHaveMoreCaregivers && (_jsx(Button, { disabled: !caregiver.c_public_user.c_access_code, label: translate('generic.enable'), variant: 'secondary', onClick: handleEnable })), canInviteCaregiver && _jsx(Button, { label: translate('generic.invite'), variant: 'primary' })] })] }));
};
