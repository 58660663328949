import { useContext, useMemo } from 'react';
import { AuthContext } from '../../contexts';
import { IAMRole } from '../../types';
export const useUserRoles = () => {
    const { roleGroups } = useContext(AuthContext);
    return useMemo(() => {
        const isDataAdmin = roleGroups.includes(IAMRole.DATA_TRANSFER_ADMIN);
        const isDataManager = roleGroups.includes(IAMRole.DATA_MANAGER);
        const isDataReviewer = roleGroups.includes(IAMRole.DATA_REVIEWER);
        return {
            isAdmin: roleGroups.includes(IAMRole.ADMIN),
            isBuilder: roleGroups.includes(IAMRole.BUILDER),
            isDataUser: isDataAdmin || isDataManager || isDataReviewer,
            isDataAdmin,
            isDataManager,
            isDataReviewer,
            isLibrarian: roleGroups.includes(IAMRole.LIBRARIAN),
            isSiteUser: roleGroups.includes(IAMRole.SITE_USER),
            isUserAndSiteManager: roleGroups.includes(IAMRole.USER_AND_SITE_MANAGER),
        };
    }, [roleGroups]);
};
