import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { I18nUtils } from '@medable/i18n-core';
import { Locale, LocaleContext } from 'libs.nucleus';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
import { fetchLumosTrainingModules, fetchSiteUserTrainingProgress, } from '../../components/lumos_training/helpers/lumos_training_api.helpers';
export const useLumosTrainingProgress = () => {
    const [startingModuleIndex, setStartingModuleIndex] = useState(0);
    const [allModulesCompleted, setAllModulesCompleted] = useState(true);
    const [currentTrainingConfigId, setCurrentTrainingConfigId] = useState(0);
    const [trainingVersion, setTrainingVersion] = useState(undefined);
    const [localeNotSupported, setLocaleNotSupported] = useState(false);
    const [trainingModules, setTrainingModules] = useState([]);
    const currentLocale = useContext(LocaleContext);
    const { entityId, user } = useContext(AuthContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const { data: trainingModulesData, isLoading: isLoadingTrainingModules } = useQuery({
        queryKey: ['entities', entityId, 'trainingConfig', user?.id],
        queryFn: () => fetchLumosTrainingModules({ momClient }),
        enabled: !!entityId && !!user?.id,
    });
    const { data: siteUserModuleProgress, isLoading: isSiteUserProgressLoading } = useQuery({
        queryKey: ['entities', entityId, 'siteUserProgress', user?.id],
        queryFn: () => fetchSiteUserTrainingProgress({ entityId, momClient, indentityId: user?.id || 0 }),
        enabled: !!entityId && !!user?.id,
    });
    const computeSiteUserProgressOnLumosTraining = () => {
        let startingIndex = 0;
        const siteUserCompletedModules = siteUserModuleProgress?.metadata?.moduleCompletionLogs || [];
        if (siteUserModuleProgress?.completedOn) {
            startingIndex = trainingModules.length ? trainingModules.length - 1 : 0; // start from the last module to indicate all modules are completed with tick mark icons
        }
        else if (siteUserCompletedModules.length) {
            startingIndex = trainingModules.reduce((acc, trainingModule, index) => {
                const completedModule = siteUserCompletedModules.find((completedModule) => completedModule.completedOn !== '' && completedModule.code === trainingModule.code);
                return completedModule ? acc : index;
            }, 0);
        }
        setStartingModuleIndex(startingIndex);
        // Check if all modules are completed
        const allCompleted = !!siteUserModuleProgress?.completedOn;
        setAllModulesCompleted(allCompleted);
    };
    const setupLocaleRelatedTraining = (allModulesWithLocales) => {
        const localeRelatedModules = allModulesWithLocales.value.find((moduleWrapper) => moduleWrapper.locales.includes(I18nUtils.i18nToCortexFormat(currentLocale)));
        if (!localeRelatedModules) {
            const localeWithEnglishFallback = allModulesWithLocales.value.find((moduleWrapper) => moduleWrapper.locales.includes(I18nUtils.i18nToCortexFormat(Locale.en_US)));
            setLocaleNotSupported(true);
            setTrainingModules(localeWithEnglishFallback?.modules || []);
            return;
        }
        setTrainingModules(localeRelatedModules?.modules || []);
    };
    useEffect(() => {
        if (!isLoadingTrainingModules && !isSiteUserProgressLoading && trainingModulesData?.id) {
            setupLocaleRelatedTraining(trainingModulesData);
            setCurrentTrainingConfigId(trainingModulesData.id);
            setTrainingVersion(trainingModulesData.version);
        }
        return () => {
            setTrainingModules([]);
            setCurrentTrainingConfigId(0);
            setTrainingVersion(undefined);
        };
    }, [trainingModulesData, siteUserModuleProgress, isLoadingTrainingModules, isSiteUserProgressLoading]);
    useEffect(() => {
        if (trainingModules.length) {
            computeSiteUserProgressOnLumosTraining();
        }
    }, [allModulesCompleted, startingModuleIndex, trainingModules, siteUserModuleProgress]);
    return {
        startingModuleIndex,
        allModulesCompleted,
        currentTrainingConfigId,
        isLoadingTrainingModules,
        trainingModules,
        siteUserModuleProgress,
        localeNotSupported,
        trainingVersion,
    };
};
