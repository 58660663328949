import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { v4 as uuidv4 } from 'uuid';
import { Button, useLocalizeMessage, Input, InlineNotification, H3 } from 'libs.nucleus';
import { convertFormatSpecSubjectIdIntoArray, siteNumber, protocol, countryCode, setupResponseValue, setupInitialValues, } from './helpers/format_spec_id.helpers';
import { hasCorrectParticipantIdPattern } from './helpers/participant_id_validation.helpers';
import { useForm } from '../../../../../hooks/use_form';
import { useOrgContext } from '../../../../../hooks/use_org_context';
import { useSiteContext } from '../../../../../hooks/use_site_context';
import { shouldDisplayParticipantNumber } from '../../../../participant_form/helpers/participant_form.helpers';
export const ParticipantIdStep = ({ currentStep, currentActivityResponse, startDate, handleGoToNextStep, }) => {
    const org = useOrgContext();
    const site = useSiteContext();
    // const isStepMisconfigured = false; // TODO: uncomment this function shouldDisplayParticipantNumber(org);
    const isStepMisconfigured = shouldDisplayParticipantNumber(org);
    const translate = useLocalizeMessage();
    const fields = convertFormatSpecSubjectIdIntoArray(org.c_format_spec_subject_id || '')?.map((field, index) => {
        if (field === '{PROTOCOL}') {
            return { format: '{PROTOCOL}', value: protocol(org, translate) };
        }
        if (field === '{SITE}') {
            return { format: '{SITE}', value: siteNumber(site, translate) };
        }
        if (field === '{COUNTRY}') {
            return { format: '{COUNTRY}', value: countryCode(site, translate) };
        }
        // show any other character if not {PROTOCOL}, {SITE}, {COUNTRY} or @, #
        if (field.includes('#') || field.includes('@')) {
            return { format: field, value: '', fieldIndex: `pid-${index}` };
        }
        return { format: field, value: field };
    });
    const currentStepResponse = currentActivityResponse.getStepResponse({ c_step: currentStep._id });
    let fieldsThatRequireInput = {};
    let initialValues = {};
    if (currentStepResponse?.c_value) {
        const stepResponse = fields
            ? setupResponseValue(fields, currentStepResponse.c_value)
            : { fieldsThatRequireInput: {}, initialValues: {} };
        fieldsThatRequireInput = stepResponse.fieldsThatRequireInput;
        initialValues = stepResponse.initialValues;
    }
    else {
        const initialStepResponse = fields ? setupInitialValues(fields) : { fieldsThatRequireInput: {}, initialValues: {} };
        fieldsThatRequireInput = initialStepResponse.fieldsThatRequireInput;
        initialValues = initialStepResponse.initialValues;
    }
    const { form, isFieldtouched, onFieldChange } = useForm({
        initialValues,
    });
    const errors = () => {
        const errorsToShow = {};
        for (const key in form) {
            if (form[key] === '') {
                errorsToShow[key] = translate('validation.field_required');
            }
            else {
                if (!hasCorrectParticipantIdPattern(form[key], fieldsThatRequireInput[key].format)) {
                    errorsToShow[key] = translate('validation.activity.participant_id.incorrect_pattern');
                }
            }
        }
        return errorsToShow;
    };
    const formHasErrors = Object.values(errors()).some((error) => !!error);
    const onNext = () => {
        const fullParticipantId = fields
            ?.map((field, index) => {
            if (field.format.includes('#') || field.format.includes('@')) {
                return form[`pid-${index}`];
            }
            return field.value;
        })
            .join('');
        const response = {
            c_step: currentStep,
            c_value: fullParticipantId,
            type: 'c_participant_id',
            c_start_date: startDate,
            c_end_date: new Date(),
            c_skipped: false,
        };
        currentActivityResponse.updateStepResponse(response);
        handleGoToNextStep();
    };
    return (_jsxs("div", { className: 'nucleus-px-10', children: [_jsx(H3, { className: 'text-xl text-center py-6', children: currentStep.c_text || currentStep.c_name }), isStepMisconfigured ? (_jsx(InlineNotification, { subtitle: translate('validation.activity.participant_id.misconfiguration'), title: '', type: 'error' })) : (_jsxs(_Fragment, { children: [_jsx("form", { "data-testid": 'participant-id-step', children: _jsx("div", { className: 'flex flex-row items-start justify-center flex-wrap', children: fields?.map((field, index) => {
                                if (field.format.includes('#') || field.format.includes('@')) {
                                    return (_jsx(Input, { dataTestId: `participant-id-${index}`, onChange: onFieldChange, value: form[`pid-${index}`] ?? '', type: 'text', placeholder: field.format, maxLength: field.format.length, name: `pid-${index}`, id: `pid-${index}`, width: 'sm', required: true, errorMessage: errors()[`pid-${index}`] && isFieldtouched(`pid-${index}`)
                                            ? errors()[`pid-${index}`]
                                            : undefined, hasError: !!errors()[`pid-${index}`] && isFieldtouched(`pid-${index}`) }, field.fieldIndex));
                                }
                                if (['{PROTOCOL}', '{SITE}', '{COUNTRY}'].includes(field.format)) {
                                    return (_jsx("div", { style: { padding: '10px 4px' }, "data-testid": `pre-set-${field.format}`, children: field.value }, uuidv4()));
                                }
                                return (_jsx("div", { style: { padding: '10px 4px' }, "data-testid": 'rest-pid-format', children: field.value }, uuidv4()));
                            }) }) }), _jsx("div", { className: 'min-h-80' }), _jsx("div", { className: 'nucleus-w-80', style: { margin: '0 auto' }, children: _jsx(Button, { dataTestId: 'participant-id-step-next', onClick: onNext, label: translate('generic.next'), disabled: formHasErrors, size: 'full', className: 'h-10' }) })] }))] }));
};
