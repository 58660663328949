import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes, COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical';
import { useEffect, useMemo } from 'react';
import { Dropdown, useLocalizeMessage } from 'libs.nucleus';
import { FormatterWrapper } from '../formatters/formatter_wrapper';
import { $createVariableNode, VariableNode } from '../nodes/variable_node';
export const INSERT_VARIABLE_COMMAND = createCommand('INSERT_VARIABLE_COMMAND');
export const VariableSelector = ({ variables = [] }) => {
    const t = useLocalizeMessage();
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor.hasNode(VariableNode)) {
            throw new Error('VariablesSelector: VariableNode has not been registered on the editor');
        }
        return editor.registerCommand(INSERT_VARIABLE_COMMAND, ({ name, id }) => {
            const variableNode = $createVariableNode(name, id);
            $insertNodes([variableNode]);
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    const dropdownItems = useMemo(() => variables.map(({ name, id }) => ({
        id,
        content: name,
        onClick: () => editor.dispatchCommand(INSERT_VARIABLE_COMMAND, { name, id }),
        ariaLabel: name,
    })), [variables]);
    return (_jsx(FormatterWrapper, { children: _jsx(Dropdown, { width: 'full', selectorClassName: 'border-0', label: t('Variables'), "aria-label": 'Add variable', items: dropdownItems }, 'variables') }));
};
