import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button, useLocalizeMessage, ModalDialog } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
export const ViewErrorLogAction = ({ error_message, locale }) => {
    const { studyId } = useParams();
    const { logEvent } = useContext(FirebaseAppContext);
    const translate = useLocalizeMessage();
    const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false);
    const handleOpenViewLogs = useCallback(() => {
        logEvent('submission_package_view_logs', {
            studyId,
            locale,
            error_message,
        });
        openDialog();
    }, [studyId, locale, error_message]);
    return (_jsxs("div", { className: 'flex justify-center', children: [_jsx(Button, { variant: 'transparent', label: translate('See logs'), onClick: handleOpenViewLogs }), isDialogOpen && (_jsx(ModalDialog, { title: translate('Error logs'), body: error_message, actionButtons: [
                    {
                        label: translate('Ok'),
                        onClick: closeDialog,
                    },
                ], isOpen: true, closeDialog: closeDialog }))] }));
};
