import { ActivityDependencyTriggerType, DefaultActivityId, DefaultTimelineId, } from '@axon/rosetta-sdk';
/**
 * Checks if the given activity has any dependent notifications
 * @param activityOrVisitId - Activity id
 */
export const getDependentNotifications = (activityOrVisitId, currentStudy, timelineId) => {
    const { notifications } = currentStudy;
    return notifications.filter((notification) => {
        if (notification.encounterId === activityOrVisitId) {
            return true;
        }
        // if it's the activity id that matches, we need to check that the visit id exists in the provided timeline
        if (notification.activityId === activityOrVisitId) {
            if (notification.encounterId) {
                const visit = currentStudy.getTimelineVisits(timelineId).find((visit) => visit.id === notification.encounterId);
                return !!visit;
            }
            // if there is no notification encounter id, and it matched the activity id, we assume it's a non-visit activity and return a match
            return true;
        }
        return false;
    });
};
/**
 * Checks if the given activity has any dependent activities
 * @param activityId id of the activity to check
 * @param instanceId id of the instance to check, if provided
 * @returns boolean indicating if the activity has any dependent activities
 */
export const getDependentActivities = (activityId, currentStudy, currentTimelineId) => {
    const isOnboarding = currentTimelineId === DefaultTimelineId.ONBOARDING;
    // if the current timeline is onboarding, we should check all timelines for a dependency,
    // as any timeline can have a dependency on an onboarding activity
    const instancesToCheck = isOnboarding
        ? currentStudy.getAllActivityStudyInstances()
        : currentStudy.getAllActivityTimelineInstances(currentTimelineId);
    const onboardingVisit = currentStudy.getTimelineVisits(DefaultTimelineId.ONBOARDING)[0];
    const onboardingActivityInstanceId = onboardingVisit.getActivitySequenceByActivityId(activityId)?.instanceId;
    // this check function checks the following:
    // - if the condition is not from the activity itself
    // - if the dependency is the activity
    // - if an onboarding activity, we check if the dependency is on the onboarding instance
    // - if a non-onboarding activity, we check if the dependency is not the onboarding instance
    const checkFunc = (condition) => condition.activityId !== activityId &&
        condition.triggerType !== ActivityDependencyTriggerType.STUDY_CREATION &&
        condition.triggerType !== ActivityDependencyTriggerType.END_OF_DAY &&
        condition.triggerType !== ActivityDependencyTriggerType.VISIT_STARTS &&
        condition.triggerType !== ActivityDependencyTriggerType.DURATION &&
        condition.dependencyId === activityId &&
        (!condition.dependencyInstanceId ||
            (isOnboarding
                ? condition.dependencyInstanceId === onboardingActivityInstanceId
                : condition.dependencyInstanceId !== onboardingActivityInstanceId));
    return instancesToCheck.filter((activity) => {
        const hasDependency = activity.timing.startConditions.some(checkFunc) || activity.timing.endConditions.some(checkFunc);
        // we also need to check for unpause dependencies
        let hasUnpauseDependecy = false;
        if (!isOnboarding && activity.timing.occurrence === 'adhoc' && activity.timing.unpauseConditions) {
            hasUnpauseDependecy = activity.timing.unpauseConditions.some((condition) => 'dependencyId' in condition && condition.dependencyId === activityId);
        }
        return hasDependency || hasUnpauseDependecy;
    });
};
/**
 * Checks if the given activity id has a schedule timeline condition
 */
export const getScheduleTimelineConditions = (activityOrTimelineId, currentStudy) => {
    return currentStudy.scheduleTimelineConditions.filter((timelineCondition) => {
        return (timelineCondition.activityId === activityOrTimelineId ||
            timelineCondition.scheduleTimelineId === activityOrTimelineId);
    });
};
/**
 * Checks if the given status id has a dependent activity
 */
export const getActivityStatusDependencies = (statusId, currentStudy) => {
    return currentStudy.participantStatusConditions.filter((statusCondition) => {
        return statusCondition.updatesParticipantStatusTo === statusId;
    });
};
/**
 * Checks if an activity has a dependency on the given visit id
 */
export const getVisitDependencies = (visitId, currentStudy, currentTimelineId) => {
    const remoteInstances = currentStudy.scheduledActivities[currentTimelineId];
    return remoteInstances
        .filter((instance) => {
        return (instance.timing.startConditions.some((condition) => condition.encounterId === visitId) ||
            instance.timing.endConditions.some((condition) => condition.encounterId === visitId));
    })
        .reduce((acc, instance) => {
        const activity = currentStudy.getActivityById(instance.activityId);
        if (activity) {
            acc.push(activity);
        }
        return acc;
    }, []);
};
/**
 * Checks if the given visit has a dependent visit, validating the visit window
 */
export const getDependentVisitWindows = (visitId, study, currentTimelineId) => {
    const visitConfirmation = study.visits[currentTimelineId][visitId].getActivitySequenceByActivityId(DefaultActivityId.VISIT_CONFIRMATION);
    if (!visitConfirmation) {
        return Object.values(study.visits[currentTimelineId]).filter((visit) => visit.details.windowEncounterId === visitId);
    }
    return Object.values(study.visits[currentTimelineId]).filter((visit) => visit.details.windowInstanceId === visitConfirmation.instanceId);
};
