import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { ApiClientService, LibraryEndpoint, LibraryResourceStatus, useApiClient, } from 'libs.react';
export const useStudyVersion = () => {
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const { studyId } = useParams();
    const retrieveVersions = async () => {
        if (!studyId) {
            return {
                versions: new Map(),
            };
        }
        const searchParams = new URLSearchParams({ studyId });
        searchParams.append('status', LibraryResourceStatus.APPROVED);
        searchParams.append('status', LibraryResourceStatus.PUBLISHED);
        const endpoint = `${LibraryEndpoint.GET_STUDY_CONFIGS}?${searchParams.toString()}`;
        const { data: response } = await libraryClient.get(endpoint);
        const versions = new Map();
        response.data.forEach((version) => {
            const metadata = typeof version.metadata === 'string' ? JSON.parse(version.metadata) : version.metadata;
            versions.set(version.id, {
                ...version,
                metadata,
            });
        });
        return { versions };
    };
    const { data = { versions: new Map() }, isError, isFetching, isLoading, } = useQuery({
        queryKey: ['study-configs', studyId],
        queryFn: retrieveVersions,
        placeholderData: keepPreviousData,
    });
    return {
        isError,
        isFetching,
        isLoading,
        versions: data.versions,
    };
};
