import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MessageResource } from '@medable/i18n-core';
import { Locale } from 'libs.i18n-core';
import { AssetTypes } from 'libs.localization.common';
import { useLocalization } from 'libs.localization.react';
import { I18nContextProvider, Spinner } from 'libs.nucleus';
import { AuthContext, NotFoundPage } from 'libs.react';
import { SitesWrapper } from './components/sites_wrapper';
import { AxonAccountProvider } from './contexts/axon_account/axon_account.context';
import { EnvironmentsProvider } from './contexts/environments/environments.context';
import { OrgProvider } from './contexts/org/org.context';
import { SiteProvider } from './contexts/site/site.context';
import { StudyTrainingProvider } from './contexts/study_training/study_training.context';
import { UserLocaleProvider } from './contexts/user_locale/user_locale.context';
import { getApiInstance } from './helpers/api.helpers';
import { useLumosTrainingNavigation } from './hooks/use_lumos_training';
import { HelpSupportPage } from './pages/help_support/help_support.page';
import { LumosTraining } from './pages/lumos_training/lumos_training.page';
import { ParticipantDetailsPage } from './pages/participant_details/participant_details.page';
import { ParticipantsList } from './pages/participants/participants_list.page';
import { VisitDetailsPage } from './pages/visit_details/visit_details.page';
// import {
//   ChatContextProvider,
//   DeploymentProvider,
//   StudyConfigurationProvider,
//   StudiesProvider,
//   VariablesProvider,
// } from './contexts-back';
const StudiesDashboardPage = lazy(() => import('./pages/studies_dashboard/studies_dashboard.page'));
window.getApiInstance = getApiInstance;
const ProvidersWrapper = ({ children }) => {
    const { user } = useContext(AuthContext);
    const localeAsset = {
        type: AssetTypes.PRODUCT_STRINGS,
        id: 'lumos',
        version: '1',
        human_id: 'product_strings.lumos~1',
    };
    const { isLoading: isloadingLocalization, localizationEntry: localeResources } = useLocalization(localeAsset);
    if (isloadingLocalization) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const messageProvider = (locale) => {
        const localeToUse = localeResources?.localization_resources?.[locale]?.main || {};
        return new MessageResource(locale, localeToUse);
    };
    return (_jsx(I18nContextProvider, { locale: Locale[user?.locale || 'en_US'], messageProvider: messageProvider, children: _jsx(EnvironmentsProvider, { children: _jsx(AxonAccountProvider, { children: _jsx(SiteProvider, { children: _jsx(OrgProvider, { children: children }) }) }) }) }));
};
export const SiteApp = () => {
    useLumosTrainingNavigation();
    return (_jsx(ProvidersWrapper, { children: _jsxs(Routes, { children: [_jsx(Route, { path: 'training', element: _jsx(LumosTraining, {}) }), _jsx(Route, { index: true, path: 'dashboard', element: _jsx(StudiesDashboardPage, {}) }), _jsxs(Route, { path: '/study/:studyId', element: _jsx(UserLocaleProvider, { children: _jsx(StudyTrainingProvider, { children: _jsx(SitesWrapper, {}) }) }), children: [_jsx(Route, { index: true, path: 'participants', element: _jsx(ParticipantsList, {}) }), _jsx(Route, { path: 'participants/:participantId', element: _jsx(ParticipantDetailsPage, {}) }), _jsx(Route, { path: 'participants/:participantId/visit/:visitId', element: _jsx(VisitDetailsPage, {}) }), _jsx(Route, { path: 'help-support', element: _jsx(HelpSupportPage, {}) })] }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] }) }));
};
export { SITE_FLAGS } from './constants/developer_flags.constants';
