import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Button, Icon, useLocalizeMessage } from 'libs.nucleus';
import { getOnboardingConfig } from '../../helpers/study.helpers';
import { useOrgContext } from '../../hooks/use_org_context';
export const OnboardingSchedule = ({ onboardingScheduleActivities, setCurrentVisit, setIsActivityListModalOpen, }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const onboardingGroupId = getOnboardingConfig(org)?.c_group_id;
    const onOnboardingScheduleClick = () => {
        setCurrentVisit({
            c_visit: '',
            c_name: translate('generic.onboarding'),
            c_group: onboardingGroupId ?? '',
            c_window_start: Date.now(),
            c_window_end: Date.now(),
            c_open_queries: 0,
            c_completed_assignments: 0,
            c_completed_assignments_for_site: 0,
            c_sequence: 0,
            assignments: onboardingScheduleActivities.length,
            total_activities_count: onboardingScheduleActivities.length,
            customVisitType: 'onboarding',
        });
        setIsActivityListModalOpen();
    };
    const totalCompletedAssignment = onboardingScheduleActivities.filter((activity) => activity.completed_count === 1).length;
    return (_jsx(_Fragment, { children: _jsxs("div", { "data-testid": `visit-schedule-onboarding`, className: 'grid grid-cols-2 grid-rows-1 gap-4 w-full p-4 bg-white border border-gray-200 rounded-lg shadow-sm', children: [_jsxs("div", { children: [_jsxs("div", { "data-testid": `visit-title-onboarding`, className: 'text-primary-500 flex gap-2', children: [_jsx("div", { "data-testid": 'visit-name-onboarding', children: translate('generic.onboarding') }), onboardingScheduleActivities.length === totalCompletedAssignment ? (_jsx(Badge, { className: 'ml-2', label: translate('generic.completed') })) : ('')] }), _jsx("div", { "data-testid": 'onboarding-assessments', className: 'text-gray-500', children: translate('label.visit_schedule.site_activities', {
                                assignments: onboardingScheduleActivities.length,
                                count: onboardingScheduleActivities.length,
                            }) })] }), _jsx("div", { className: 'ml-auto', style: { marginTop: '3px' }, children: _jsx(Button, { label: translate('action.onboarding.view'), "aria-label": 'view-onboarding-schedule', variant: 'transparent', content: _jsx(Icon, { name: 'chevronRight', size: 24, color: 'primary' }), onClick: onOnboardingScheduleClick }) })] }) }));
};
