import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';
import { useLocalizeMessage, Link, Badge } from 'libs.nucleus';
import { useDateTime } from 'libs.react';
import { buildDocumentUrl } from './participant_documents.utils';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { useAxonAccountContext } from '../../hooks/use_axon_account_context';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useOrgContext } from '../../hooks/use_org_context';
import { useTranslateDisplayName } from '../../hooks/use_translate_display_name';
const statusMap = {
    cancelled: 'danger',
    complete: 'success',
    sent: 'incomplete',
    partial: 'common',
    voided: 'common',
};
export const useParticipantDocumentsTableColumns = () => {
    const translate = useLocalizeMessage();
    const { parseDateTimeShort } = useDateTime();
    const { environment } = useCurrentEnvironment();
    const participantDocumentsColumns = useMemo(() => {
        return [
            {
                header: translate('generic.title'),
                accessorKey: 'ec__document_template.ec__title',
                cell: (context) => {
                    const title = context.getValue();
                    const link = buildDocumentUrl(context.row.original._id, environment);
                    return (_jsx(Link, { href: link, target: '_blank', children: title }));
                },
            },
            {
                header: translate('label.participant_documents.required_signers'),
                accessorKey: 'ec__required_signers',
            },
            {
                header: translate('generic.create', { tense: 'past' }),
                accessorKey: 'created',
                cell: (context) => parseDateTimeShort(context.getValue()),
            },
            {
                header: translate('generic.status'),
                accessorKey: 'ec__status',
                cell: (context) => {
                    const status = context.getValue();
                    return _jsx(Badge, { variant: statusMap[status], label: status });
                },
            },
        ];
    }, []);
    return { participantDocumentsColumns };
};
const fetchParticipantDocuments = async (subject, apiInstance, accountId, siteId) => {
    if (!subject.id) {
        return [];
    }
    const response = await apiInstance.get(`/v2/account/${accountId}/c_sites/${siteId}/c_subjects/${subject.id}/ec__documents`, {
        params: {
            where: JSON.stringify({ ec__site: siteId }),
            paths: ['ec__document_template.ec__title'],
            include: ['ec__status', 'ec__required_signers', 'created'],
        },
    });
    if (!response?.data?.data) {
        return [];
    }
    return response.data.data.map((document) => {
        if (!subject.displayName) {
            return document;
        }
        return {
            ...document,
            ec__document_template: {
                ...document.ec__document_template,
                ec__title: `${subject.displayName} • ${document.ec__document_template.ec__title}`,
            },
        };
    });
};
export const useParticipantDocuments = (participant) => {
    const account = useAxonAccountContext();
    const { site } = useContext(SiteContext);
    const { environment } = useCurrentEnvironment();
    const org = useOrgContext();
    const translateDisplayName = useTranslateDisplayName();
    const isCaregiverFeatureEnabled = org?.c_caregiver_enabled ?? false;
    const getParticipantDocumentsData = async () => {
        if (!environment) {
            return [];
        }
        try {
            const apiInstance = getApiInstance(environment);
            const subjects = [
                { id: participant?._id, displayName: undefined },
                ...(isCaregiverFeatureEnabled ? participant?.c_caregiver_relationship?.c_caregivers_info || [] : []).map(({ c_public_user, c_caregiver_display_name }) => ({
                    id: c_public_user?._id,
                    displayName: translateDisplayName(c_caregiver_display_name),
                })),
            ].filter(({ id }) => !!id);
            const documentsPromises = subjects.map((subject) => fetchParticipantDocuments(subject, apiInstance, account?._id, site?._id));
            const results = await Promise.allSettled(documentsPromises);
            return results
                .filter((result) => result.status === 'fulfilled')
                .flatMap((result) => result.value);
        }
        catch (error) {
            return [];
        }
    };
    return useQuery({
        queryKey: ['participantDocuments', participant?._id],
        queryFn: getParticipantDocumentsData,
        enabled: !!participant?._id,
    });
};
