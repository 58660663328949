import { max, min } from 'date-fns';
import { find } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DateTimeUtils } from '@medable/i18n-core';
import { EStepTypes } from '../../../constants/step_type.enum';
import { getApiInstance } from '../../../helpers/api.helpers';
import { getStepResponseType } from '../../../helpers/step_response.helpers';
export class ActivityResponse {
    stepResponses = [];
    c_task;
    c_study;
    c_account;
    c_public_user;
    c_group;
    c_locale;
    c_site;
    c_start;
    c_event;
    c_visit;
    c_success;
    c_uuid;
    constructor({ c_task, c_study, c_account, c_public_user, c_group, c_locale, c_site, c_start, c_event, c_visit, }) {
        this.c_task = c_task;
        this.c_study = c_study;
        this.c_account = c_account;
        this.c_public_user = c_public_user;
        this.c_group = c_group;
        this.c_locale = c_locale;
        this.c_site = c_site;
        this.c_start = c_start;
        this.c_event = c_event;
        this.c_visit = c_visit;
        this.c_success = true;
    }
    updateStepResponse(props) {
        const { c_step, c_value, c_skipped, c_start_date, c_end_date, c_original_value, c_skipped_type, c_skipped_reason, c_unit, } = props;
        const existingResponse = find(this.stepResponses, { c_step: c_step._id });
        if (c_step.c_type === EStepTypes.COMPLETION) {
            this.c_success = !!c_step.c_success;
        }
        const stepResponse = {
            c_step: c_step._id,
            c_study: this.c_study || '',
            c_public_user: this.c_public_user || '',
            c_group: this.c_group || '',
            c_task: this.c_task || '',
            c_original_value,
            c_unit,
            type: getStepResponseType(c_step),
            ...(c_skipped ? { c_skipped: true, c_skipped_type, c_skipped_reason } : { c_value }),
            c_start_date: existingResponse ? min([c_start_date, existingResponse.c_start_date]) : c_start_date,
            c_end_date: existingResponse ? max([c_end_date, existingResponse.c_end_date]) : c_end_date,
        };
        if (existingResponse) {
            // we clear both keys in order to make sure the step response is clean
            delete existingResponse.c_value;
            delete existingResponse.c_skipped;
            Object.assign(existingResponse, stepResponse);
        }
        else {
            this.stepResponses.push(stepResponse);
        }
    }
    getStepResponse = (match) => find(this.stepResponses, match);
    async upload({ environment }) {
        const { c_task, c_study, c_public_user, c_group, c_locale, c_site, c_start, c_visit } = this;
        // We generate a uuid for this activity response on the first try,
        // then it sticks to the instance in order to have the POST request idempotent
        if (!this.c_uuid) {
            this.c_uuid = uuidv4();
        }
        const c_task_response = {
            c_public_user,
            c_uuid: this.c_uuid,
            c_study,
            c_task,
            c_group,
            c_completed: true,
            c_success: this.c_success,
            c_locale,
            c_step_responses: this.stepResponses,
            c_start,
            c_end: new Date(),
            c_tz: DateTimeUtils.getClientTimezone(),
            ...(c_visit ? { c_visit } : {}),
        };
        await getApiInstance(environment).post('/v2/routes/create_task_response', {
            c_task_response,
            c_site,
        });
    }
    clearStepResponses() {
        this.stepResponses = [];
        this.c_task = null;
        this.c_study = null;
        this.c_account = null;
        this.c_public_user = null;
        this.c_group = null;
        this.c_locale = null;
        this.c_site = null;
        this.c_start = null;
        this.c_event = null;
        this.c_visit = null;
        this.c_uuid = '';
    }
}
