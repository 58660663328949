import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocalizeMessage, ModalWindow, H3 } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { CaregiverInvite } from './caregiver_invite.component';
import { getStudyShortCode } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const CaregiversInviteModal = ({ isModalOpen, caregivers, onClose, onDone, title, participant }) => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const queryClient = useQueryClient();
    const [isBusy, setIsBusy, unsetIsBusy] = useBooleanState(false);
    const getStudyCode = async () => {
        return await getStudyShortCode(window.location.hostname, environment?.cortexURL ?? '', environment?.code ?? '');
    };
    const { data: studyCode } = useQuery({
        queryKey: ['getStudyCode', environment?.code],
        queryFn: getStudyCode,
        enabled: !queryClient.getQueryData(['getStudyCode', environment?.code]),
    });
    return (_jsx(ModalWindow, { title: title ?? translate('generic.invite'), isOpen: isModalOpen, closeWindow: onClose, footerPrimaryActionButton: {
            label: translate('generic.done'),
            disabled: isBusy,
            onClick: onDone ?? onClose,
            id: 'caregivers-invite-modal-done',
            dataTestId: 'caregivers-invite-modal-done',
        }, width: 'full', dataTestId: 'invite-caregiver-modal-window', children: _jsxs("div", { className: 'flex flex-col h-full justify-center gap-y-8', children: [_jsxs("section", { className: 'text-center', children: [_jsx(H3, { children: title ?? translate('generic.invite_information') }), _jsx("p", { className: 'text-text-secondary', children: translate('generic.invite_code.share') })] }), _jsx("div", { className: 'flex flex-row justify-center w-full gap-x-4', children: caregivers.map((caregiver) => (_jsx(CaregiverInvite, { onDone: unsetIsBusy, onBusy: setIsBusy, studyCode: studyCode, caregiver: caregiver, participant: participant }, caregiver.c_public_user._id))) })] }) }));
};
