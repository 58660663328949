import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import { useEnvironments } from '../hooks';
export const IrbContext = createContext({
    getEnvironments: () => { },
});
export const IrbProvider = ({ children }) => {
    const { data: environments } = useEnvironments();
    return _jsx(IrbContext.Provider, { value: { getEnvironments: () => environments }, children: children });
};
