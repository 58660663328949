import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useRef } from 'react';
import { useLocalizeMessage, ModalWindow, Spinner, } from 'libs.nucleus';
import { StepIndicator } from '../step_indicator';
const noop = () => { };
export const MultiStepsModalWindow = ({ showSteps = true, isLoading, onCancel = noop, onSubmit = noop, stepStates, steps, submitLabel, title, subtitle, onStepChange, }) => {
    const stepContainerRef = useRef(null);
    const translate = useLocalizeMessage();
    const lastStep = steps.length - 1;
    const [currentStep, setCurrentStep] = useState(0);
    const disabled = !stepStates[currentStep]?.isEnabled;
    const goBack = useCallback(() => {
        const step = Math.max(0, currentStep - 1);
        setCurrentStep(step);
        scrollToTop();
        if (onStepChange) {
            onStepChange(step);
        }
    }, [currentStep]);
    const goForward = useCallback(() => {
        const step = Math.min(currentStep + 1, lastStep);
        setCurrentStep(step);
        scrollToTop();
        if (onStepChange) {
            onStepChange(step);
        }
    }, [currentStep, lastStep]);
    const scrollToTop = () => {
        stepContainerRef.current?.scrollTo({ top: 0, behavior: 'instant' });
    };
    const backButton = { label: translate('Back'), onClick: goBack };
    const continueButton = {
        label: translate('Continue'),
        onClick: goForward,
        disabled,
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const doneButton = {
        label: submitLabel ?? translate('Save'),
        onClick: onSubmit,
        disabled,
    };
    return (_jsxs(ModalWindow, { title: title, isOpen: true, closeWindow: onCancel, footerSecondaryActionButtons: currentStep === 0 ? [cancelButton] : [cancelButton, backButton], footerPrimaryActionButton: currentStep === lastStep ? doneButton : continueButton, footerCenterContent: showSteps && _jsx(StepIndicator, { totalSteps: steps.length, currentStep: currentStep + 1 }), width: 'full', subtitle: subtitle, modalContentRef: stepContainerRef, children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), steps[currentStep]] }));
};
