import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, Input } from 'libs.nucleus';
import { useOrgContext } from '../../../hooks/use_org_context';
export const ParticipantEmailField = ({ form, errors, description, onFieldChange, isFieldtouched, }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    //  If org is a no pii org, dont show email field
    if (org?.c_no_pii) {
        return null;
    }
    return (_jsx(Input, { dataTestId: 'participant-email', id: 'email', name: 'email', label: translate('generic.email'), onChange: onFieldChange, value: form?.email ?? '', width: 'lg', required: org?.c_requires_econsent_field, description: description ?? translate('info.participant.email_acknowledgment'), errorMessage: errors?.email && isFieldtouched('email') ? errors?.email : undefined, hasError: !!(errors?.email && isFieldtouched('email')) }));
};
