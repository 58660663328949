import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus';
import { PageHeader, PageWrapper, useBooleanState } from 'libs.react';
import { ParticipantFormModal } from '../../components/participant_form/participant_form_modal.component';
import { ParticipantsTable } from '../../components/participants_table/participants_table.component';
import { useLumosAuth } from '../../hooks/use_lumos_auth';
import { LUMOS_PERMISSIONS } from '../../types/permissions.types';
export const ParticipantsList = () => {
    const translate = useLocalizeMessage();
    const [isParticipantFormOpen, setParticipantFormOpen, setParticipantFormClose] = useBooleanState();
    const { isUserPermitted } = useLumosAuth();
    const headerOptions = [];
    if (isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_CREATE)) {
        headerOptions.push({
            name: translate('action.participant.create'),
            onClick: setParticipantFormOpen,
            variant: 'primary',
            dataTestId: 'create-participant-button',
        });
    }
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('generic.participants'), options: headerOptions }), _jsx(ParticipantFormModal, { isModalOpen: isParticipantFormOpen, onClose: setParticipantFormClose }), _jsx(ParticipantsTable, {})] }));
};
