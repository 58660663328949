import { jsx as _jsx } from "react/jsx-runtime";
import merge from 'lodash/merge';
import { Bar, Bubble, Doughnut, Line, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
const COMMON_OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            position: 'right',
        },
    },
};
const PIE_OPTIONS = {
    ...COMMON_OPTIONS,
    plugins: {
        ...COMMON_OPTIONS.plugins,
        legend: {
            ...COMMON_OPTIONS.plugins.legend,
            labels: {
                usePointStyle: true,
            },
        },
    },
};
const BAR_OPTIONS = {
    ...COMMON_OPTIONS,
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
    },
    plugins: {
        ...COMMON_OPTIONS.plugins,
        legend: {
            ...COMMON_OPTIONS.plugins.legend,
            display: false,
        },
    },
};
const STACKED_BAR_OPTIONS = {
    ...COMMON_OPTIONS,
    scales: {
        x: {
            stacked: true,
            grid: {
                display: false,
            },
        },
        y: {
            stacked: true,
        },
    },
    plugins: {
        ...COMMON_OPTIONS.plugins,
        legend: {
            ...COMMON_OPTIONS.plugins.legend,
            labels: {
                usePointStyle: true,
            },
        },
    },
};
const BUBBLE_OPTIONS = {
    ...COMMON_OPTIONS,
    plugins: {
        ...COMMON_OPTIONS.plugins,
        legend: {
            ...COMMON_OPTIONS.plugins.legend,
            labels: {
                usePointStyle: true,
            },
        },
    },
};
function renderChart({ type, data, onClick, options }) {
    const pieOptions = merge({}, PIE_OPTIONS, options, { onClick });
    const doughnutOptions = merge({}, PIE_OPTIONS, options, { onClick });
    const barOptions = merge({}, BAR_OPTIONS, options, { onClick });
    const stackedBarOptions = merge({}, STACKED_BAR_OPTIONS, options, { onClick });
    const lineOptions = merge({}, BAR_OPTIONS, options, { onClick });
    const bubbleOptions = merge({}, BUBBLE_OPTIONS, options, { onClick });
    const stackedAreaOptions = merge({}, STACKED_BAR_OPTIONS, options, { onClick });
    switch (type) {
        case 'pie':
            return _jsx(Pie, { data: data, onClick: onClick, options: pieOptions });
        case 'doughnut':
            return (_jsx(Doughnut, { data: data, onClick: onClick, options: doughnutOptions }));
        case 'bar':
            return _jsx(Bar, { data: data, onClick: onClick, options: barOptions });
        case 'stackedBar':
            return (_jsx(Bar, { data: data, onClick: onClick, options: stackedBarOptions }));
        case 'line':
            return _jsx(Line, { data: data, onClick: onClick, options: lineOptions });
        case 'bubble':
            return (_jsx(Bubble, { data: data, onClick: onClick, options: bubbleOptions }));
        case 'stackedArea':
            return (_jsx(Line, { data: data, onClick: onClick, options: stackedAreaOptions }));
        default:
            return _jsx("div", {});
    }
}
export const ReportingChart = ({ type = 'bar', data, onClick, options = {} }) => (_jsx("div", { className: 'flex flex-1 min-w-0', children: renderChart({ type, data, onClick, options }) }));
