import { Locale } from 'libs.i18n-core';
// as defined in SSP-671 https://jira.devops.medable.com/browse/SSP-671, the locales that are supported by Lumos are:
export const SITE_LOCALES = [
    Locale.en_US,
    Locale.ja_JP,
    Locale.es_US,
    Locale.zh_Hans_CN,
    Locale.ko_KR,
    Locale.fr_FR,
    Locale.zh_Hant_TW,
];
