import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import isEmail from 'validator/lib/isEmail';
import { ModalWindow, Spinner, useLocalizeMessage, useToastNotification } from 'libs.nucleus';
import { useBooleanState } from 'libs.react';
import { ParticipantEmailField } from './form_items/participant_email.component';
import { getParticipantFormInitialValues, updateParticipant } from './helpers/participant_form.helpers';
import { getApiErrorMessage } from '../../helpers/api.helpers';
import { getParticipantDuplicateIndexErrorMessage } from '../../helpers/participants.helpers';
import { useAxonAccountContext } from '../../hooks/use_axon_account_context';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useForm } from '../../hooks/use_form';
import { useSiteContext } from '../../hooks/use_site_context';
export const ParticipantEditFormModal = ({ isModalOpen, participant, onClose }) => {
    const translate = useLocalizeMessage();
    const [isSaving, , , toggleIsSaving] = useBooleanState(false);
    const account = useAxonAccountContext();
    const { environment } = useCurrentEnvironment();
    const site = useSiteContext();
    const queryClient = useQueryClient();
    const { form, isFieldtouched, onFieldChange, resetForm } = useForm({
        initialValues: getParticipantFormInitialValues(participant),
    });
    const { addNotification } = useToastNotification();
    const errors = {
        email: form?.email && !isEmail(form?.email) ? translate('validation.invalid_email') : undefined,
    };
    if (!errors.email && !form?.email) {
        errors.email = translate('validation.field_required');
    }
    const formHasErrors = !!Object.values(errors).find((error) => error);
    const onModalClose = () => {
        onClose();
        resetForm();
    };
    const onSave = async () => {
        if (!environment) {
            throw new Error('Environment not defined');
        }
        try {
            toggleIsSaving();
            await updateParticipant({
                environment,
                account,
                site,
                form,
                participantId: participant._id,
            });
            if (participant) {
                addNotification({
                    title: '',
                    subtitle: translate('info.participant.updated'),
                    type: 'success',
                });
                queryClient.invalidateQueries({
                    queryKey: ['participantDetails', participant._id],
                });
                onModalClose();
            }
        }
        catch (e) {
            console.error('error', e);
            let message = getParticipantDuplicateIndexErrorMessage(e, translate);
            if (!message) {
                message = getApiErrorMessage(e);
            }
            addNotification({
                title: '',
                subtitle: message ?? translate('error.generic'),
                type: 'error',
            });
        }
        finally {
            toggleIsSaving();
        }
    };
    return (_jsxs(ModalWindow, { title: '', isOpen: isModalOpen, closeWindow: onModalClose, footerPrimaryActionButton: {
            disabled: formHasErrors,
            label: translate('generic.save'),
            onClick: onSave,
            id: 'update-participant-button',
            dataTestId: 'update-participant-button',
        }, footerSecondaryActionButtons: [
            {
                label: translate('generic.cancel'),
                onClick: onModalClose,
                id: 'cancel-update-participant-button',
                dataTestId: 'cancel-update-participant-button',
            },
        ], width: 'sm', children: [isSaving && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { children: _jsx(ParticipantEmailField, { form: form, description: '', errors: errors, onFieldChange: onFieldChange, isFieldtouched: isFieldtouched }) })] }));
};
