import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { IrbStatus } from 'libs.irb.common';
import { Badge, Spinner, Table, useLocalizeMessage } from 'libs.nucleus';
import { FormattedDateTime } from 'libs.react';
import { TranslationWorkbenchContext } from 'libs.translation_workbench';
import { useIrbJobs } from '../../hooks/use_irb_jobs.hook';
import { EmptySection } from '../section_empty';
import { DownloadReportAction } from './download_report_action.component';
import { ViewErrorLogAction } from './view_error_log_action.component';
export const JobList = () => {
    const translate = useLocalizeMessage();
    const { study } = useContext(TranslationWorkbenchContext);
    const { data: jobs, isLoading } = useIrbJobs();
    const packages = jobs?.packages ?? {};
    const isPackageAvaialble = Object.keys(packages).length > 0;
    const data = useMemo(() => Object.keys(packages)
        .map((key) => {
        const irbPackage = packages[key];
        if (!irbPackage) {
            return null;
        }
        const { status, locale, user, created_at, buildVersion, url, error_message } = irbPackage;
        return {
            status,
            locale,
            created_at: created_at?.toDate(),
            user,
            buildVersion,
            action: {
                url,
                error_message,
            },
        };
    })
        .filter(Boolean)
        .sort((a, b) => {
        if (!a?.locale || !b?.locale) {
            return 0;
        }
        const localeCompare = a.locale.localeCompare(b.locale);
        if (localeCompare !== 0) {
            return localeCompare;
        }
        return b.created_at.getTime() - a.created_at.getTime();
    }), [packages]);
    const getBadgeDisplayAttributes = (status) => {
        switch (status) {
            case IrbStatus.PROCESSING:
                return { variant: 'incomplete', label: 'Processing...' };
            case IrbStatus.SUCCESS:
                return { variant: 'success', label: 'Succeeded' };
            case IrbStatus.FAILURE:
                return { variant: 'danger', label: 'Failed' };
            default:
                return { variant: 'incomplete', label: 'Processing...' };
        }
    };
    const columns = [
        {
            header: translate('Locales'),
            accessorKey: 'locale',
        },
        {
            header: translate('Study version'),
            accessorKey: 'buildVersion',
        },
        {
            header: translate('Created by'),
            accessorKey: 'user',
        },
        {
            header: translate('Creation date'),
            accessorKey: 'created_at',
            cell: ({ row }) => row.original.created_at ? (_jsx("div", { className: 'min-w-32', children: _jsx(FormattedDateTime, { date: row.original.created_at }) })) : ('—'),
        },
        {
            header: translate('Status'),
            accessorKey: 'status',
            cell: (value) => {
                const status = value.getValue();
                const { label, variant } = getBadgeDisplayAttributes(status);
                return _jsx(Badge, { variant: variant, label: label });
            },
        },
        {
            header: ' ',
            accessorKey: 'action',
            cell: ({ row }) => {
                const { status, locale } = row.original;
                const { url, error_message } = row.original.action || {};
                if (status === IrbStatus.SUCCESS && url) {
                    return (_jsx(DownloadReportAction, { url: url, fileName: `${(study.displayName || study.name).replace(/\s+/g, '-')}_${row.original.buildVersion}_${row.original.locale}_${row.original.created_at.toISOString()}.pdf` }));
                }
                if (status === IrbStatus.FAILURE && error_message) {
                    return _jsx(ViewErrorLogAction, { error_message: error_message, locale: locale });
                }
                return null;
            },
        },
    ];
    if (isLoading) {
        return (_jsx("div", { className: 'flex items-center justify-center min-h-48 relative', children: _jsx(Spinner, {}) }));
    }
    if (!isPackageAvaialble) {
        return _jsx(EmptySection, { text: translate('Review packages will be listed here.') });
    }
    return (_jsx("div", { children: _jsx(Table, { data: data, columns: columns, labelNoResults: translate('-'), hasPagination: true, initialPageSize: 10, paginationNavigationScreenReaderLabel: translate('Locale table navigation') }) }));
};
