import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getStudyShortCode } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../use_current_environment';
export const useStudyCode = () => {
    const { environment } = useCurrentEnvironment();
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: ['getStudyCode', environment?.code],
        queryFn: async () => getStudyShortCode(window.location.hostname, environment?.cortexURL ?? '', environment?.code ?? ''),
        enabled: !queryClient.getQueryData(['getStudyCode', environment?.code]),
    });
};
