import { useState } from 'react';
/**
 * Hook to get and set a value in localStorage
 *
 * @param keyName - The key to use in localStorage
 * @param defaultValue - The default value to use if the key does not exist in localStorage
 */
export function usePersistentValue(keyName, defaultValue) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const previousValue = window.localStorage.getItem(keyName);
            if (previousValue) {
                const parsedValue = JSON.parse(previousValue);
                if (typeof parsedValue !== 'undefined' && parsedValue !== null) {
                    return parsedValue;
                }
            }
            window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
            return defaultValue;
        }
        catch (err) {
            console.warn(err);
            window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
            return defaultValue;
        }
    });
    const setValue = (newValue) => {
        try {
            window.localStorage.setItem(keyName, JSON.stringify(newValue));
        }
        catch (err) { }
        setStoredValue(newValue);
    };
    return [storedValue, setValue];
}
