export var LUMOS_PERMISSIONS;
(function (LUMOS_PERMISSIONS) {
    LUMOS_PERMISSIONS["PARTICIPANTS_LIST"] = "lumos.participants.list";
    LUMOS_PERMISSIONS["PARTICIPANTS_CREATE"] = "lumos.participants.create";
    LUMOS_PERMISSIONS["PARTICIPANTS_UPDATE"] = "lumos.participants.update";
    LUMOS_PERMISSIONS["PARTICIPANTS_LIST_VISITS"] = "lumos.participants.listVisits";
    LUMOS_PERMISSIONS["PARTICIPANTS_LIST_ACTIVITIES"] = "lumos.participants.listActivities";
    LUMOS_PERMISSIONS["PARTICIPANTS_START_ACTIVITY"] = "lumos.participant.startActivity";
    LUMOS_PERMISSIONS["PARTICIPANTS_START_VISIT"] = "lumos.participants.startVisit";
    LUMOS_PERMISSIONS["PARTICIPANTS_SKIP_VISIT"] = "lumos.participants.skipVisit";
    LUMOS_PERMISSIONS["PARTICIPANTS_INVITE"] = "lumos.participants.invite";
    LUMOS_PERMISSIONS["PARTICIPANTS_DEACTIVATE"] = "lumos.participants.deactivate";
    LUMOS_PERMISSIONS["PARTICIPANTS_EMAIL_INVITE"] = "lumos.participants.emailInviteCodes";
    LUMOS_PERMISSIONS["PARTICIPANTS_CANCEL_INVITE"] = "lumos.participants.cancelInvite";
    LUMOS_PERMISSIONS["PARTICIPANTS_RESET_PASSWORD"] = "lumos.participants.resetPassword";
    LUMOS_PERMISSIONS["PARTICIPANTS_LIST_CAREGIVERS"] = "lumos.participants.listCaregivers";
    LUMOS_PERMISSIONS["PARTICIPANTS_ADD_CAREGIVERS"] = "lumos.participants.addCaregiver";
    LUMOS_PERMISSIONS["PARTICIPANTS_UPDATE_CAREGIVERS"] = "lumos.participants.updateCaregiver";
    LUMOS_PERMISSIONS["PARTICIPANTS_ENABLE_CAREGIVERS"] = "lumos.participants.enableCaregiver";
    LUMOS_PERMISSIONS["PARTICIPANTS_DISABLE_CAREGIVERS"] = "lumos.participants.disableCaregiver";
    LUMOS_PERMISSIONS["PARTICIPANTS_EMAIL_CAREGIVER_INVITE"] = "lumos.participants.emailCaregiverInviteCodes";
    LUMOS_PERMISSIONS["PARTICIPANTS_LIST_CONSENTS"] = "lumos.participants.listConsents";
    LUMOS_PERMISSIONS["PARTICIPANTS_CREATE_CONSENTS"] = "lumos.participants.createConsent";
})(LUMOS_PERMISSIONS || (LUMOS_PERMISSIONS = {}));
