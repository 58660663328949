import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, InlineNotification, Spinner, Badge } from 'libs.nucleus';
import { AvailableActivities } from './activities_list_components/available_activities.component';
import { CompletedActivities } from './activities_list_components/completed_activities.component';
import { isActivityAvailableForCompletion } from './helpers/activity_scheduling.helpers';
import { fetchParticipantActivities, fetchParticipantVisitEvents, fetchSiteActivities, fetchVisitEvent, } from './helpers/fetch_activities.helpers';
import { hasVisitEventBeenSkipped, isVisitEnabled, hasVisitEventStarted, hasEndOfStudyBeenStarted, } from './helpers/visit_activities.helpers';
import { DefaultRefetchIntervalForGenerating } from '../../constants/participant_generating.constants';
import { SiteContext } from '../../contexts/site';
import { getParticipantVisitScheduleData, isOutsideVisitWindowRange } from '../../helpers/visit.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useLumosAuth } from '../../hooks/use_lumos_auth';
import { LUMOS_PERMISSIONS } from '../../types/permissions.types';
import { VisitConfirmation } from '../visit_confirmation';
export const SiteTasksList = ({ isVisitEventSkipped, isVisitEventStarted, shouldDisableActivityStartButtons, activities, hasError, visit, setVisitActivity, setIsActivityModalOpen, visitEventHasError, endOfStudyHasBeenStarted, }) => {
    const translate = useLocalizeMessage();
    const { isUserPermitted } = useLumosAuth();
    const disableActivities = shouldDisableActivityStartButtons;
    const hasStartActivityPermissions = isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_START_ACTIVITY);
    if (hasError) {
        return _jsx(InlineNotification, { subtitle: translate('error.activities_list.site'), title: '', type: 'error' });
    }
    if (activities.length === 0) {
        return (_jsx("section", { className: 'flex items-center p-4', children: _jsx("span", { className: 'px-2', children: translate('info.activities_list.site_participants.empty') }) }));
    }
    return activities
        .filter((activity) => !activity.c_group_task.c_assignment?.c_visit_confirmation)
        .map((activity, key) => {
        const isAvailable = isActivityAvailableForCompletion(activity, activity?.last_response);
        const isAlwaysAvailableActivity = activity.c_group_task.c_schedule === 'always_available';
        let isActivityDisabled = !!disableActivities || visitEventHasError;
        if (!isVisitEventStarted || !isAvailable) {
            isActivityDisabled = true;
        }
        //  Exception for always available activities
        //  Logic in ticket: https://jira.devops.medable.com/browse/SSP-1918
        //  If activity is always available and is actionable (isAvailable) and the visit has started
        //  And the end of study has not been started then we enable the activity
        if (isAlwaysAvailableActivity) {
            isActivityDisabled = !isAvailable || !isVisitEventStarted || endOfStudyHasBeenStarted;
        }
        if (!isAvailable && activity.completed_count > 0) {
            return (_jsx(CompletedActivities, { siteActivity: activity, isSiteActivity: true }, `${activity.c_group_task.c_assignment._id}-${key}`));
        }
        if (isAvailable && activity.completed_count > 0) {
            return (_jsxs("div", { children: [_jsx(AvailableActivities, { siteActivity: activity, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, visit: visit, isActivityDisabled: isActivityDisabled, isSiteActivity: true, showStartButton: !isVisitEventSkipped && hasStartActivityPermissions }), _jsx(CompletedActivities, { siteActivity: activity, isSiteActivity: true })] }, `${activity.c_group_task.c_assignment._id}-${activity.last_response?._id}`));
        }
        //  TODO - Uncomment once decided whether we want to hide or show not available activities
        /**
         * If the activity is just not available even after the completion and completed count checks
         * It means this activity is dependent on another activity that is not completed
         * So we just return an empty div here to hide the activity
         */
        // if (!isAvailable && !isAlwaysAvailableActivity) {
        //   return <div key={`${activity.c_group_task.c_assignment._id}-${activity.last_response?._id}`}></div>;
        // }
        return (_jsx(AvailableActivities, { siteActivity: activity, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, visit: visit, isActivityDisabled: isActivityDisabled, isSiteActivity: true, showStartButton: !isVisitEventSkipped && hasStartActivityPermissions }, `${activity.c_group_task.c_assignment._id}-${key}`));
    });
};
export const ParticipantTasksList = ({ activities, hasError, visit, }) => {
    const translate = useLocalizeMessage();
    if (hasError) {
        return _jsx(InlineNotification, { subtitle: translate('error.activities_list.participant'), title: '', type: 'error' });
    }
    if (activities.length === 0) {
        return _jsx("div", {});
    }
    return activities
        .filter((activity) => activity && activity?.c_visit_id === visit.c_visit)
        .map((activity) => {
        if (activity.c_completed) {
            return _jsx(CompletedActivities, { participantActivity: activity, isSiteActivity: false }, activity._id);
        }
        return (_jsx(AvailableActivities, { participantActivity: activity, setVisitActivity: () => { }, setIsActivityModalOpen: () => { }, visit: visit, isActivityDisabled: true, isSiteActivity: false, showStartButton: false }, activity._id));
    });
};
export const ActivitiesList = ({ visit, setVisitActivity, setIsActivityModalOpen, onActionComplete, isOnboarding = false, isNonVisitSchedule = false, onboardingSchedule, participant, }) => {
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const translate = useLocalizeMessage();
    const queryClient = useQueryClient();
    const match = useMatch('/site/study/:studyId/participants/:participantId/*');
    const participantId = match?.params.participantId;
    let visitId = visit.c_visit;
    if (!visitId) {
        visitId = visit.c_group;
    }
    const getParticipantVisitsSchedule = async () => {
        if (!environment || !participantId || !site?._id) {
            return [];
        }
        const visits = await getParticipantVisitScheduleData({ environment, participantId, siteId: site?._id });
        return visits?.data?.data || [];
    };
    const { data: visitEvent, isLoading: isVisitEventLoading, isError: visitEventHasError, } = useQuery({
        queryKey: ['visitEvent', visitId, participantId],
        queryFn: () => environment && visitId && participantId && fetchVisitEvent({ environment, visitId, participantId }),
        enabled: !isOnboarding && !!visitId,
    });
    const { data: allParticipantVisitEvents } = useQuery({
        queryKey: ['visitEvent', participantId],
        queryFn: () => {
            if (environment && participantId) {
                return fetchParticipantVisitEvents({ environment, participantId });
            }
            return [];
        },
        enabled: !queryClient.getQueryData(['visitEvent', participantId]),
    });
    const results = useQueries({
        queries: [
            {
                queryKey: ['siteActivities', participantId, visitId, site?._id],
                queryFn: () => fetchSiteActivities({ environment, participantId, visit, site }),
                enabled: (!isOnboarding && !!visitId) || isNonVisitSchedule,
            },
            {
                queryKey: ['participantActivities', participantId],
                queryFn: () => fetchParticipantActivities({ environment, participantId, visit }),
                enabled: !isOnboarding && !!visitId,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                refetchInterval: (query) => {
                    if (query.state.data?.length) {
                        return query.state.data[0]?.generating ? DefaultRefetchIntervalForGenerating : false;
                    }
                    return false;
                },
            },
        ],
    });
    const { data: visitScheduleData } = useQuery({
        queryKey: ['participantVisits', participantId, participant?.c_number],
        queryFn: getParticipantVisitsSchedule,
        enabled: !queryClient.getQueryData(['participantVisits', participantId, participant?.c_number]),
    });
    const visitScheduleWithEvents = visitScheduleData
        ?.map((visit) => {
        visit.visitEvents = allParticipantVisitEvents?.filter((visitEvent) => visitEvent?.c_schedule_visit?._id === visit.c_visit);
        return visit;
    })
        .sort((a, b) => a.c_sequence - b.c_sequence);
    const endOfStudyHasBeenStarted = hasEndOfStudyBeenStarted(visitScheduleWithEvents || []);
    const canVisitBeActedOn = isVisitEnabled({
        currentVisit: visit,
        visitScheduleWithEvents: visitScheduleWithEvents || [],
    });
    const isLoading = results.some((query) => query.isLoading);
    const isSiteFetching = results[0].isFetching;
    const isParticipantFetching = results[1].isFetching || results[1].data?.[0]?.generating;
    if (isVisitEventLoading || isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const shouldDisableActivityButtons = (!isOnboarding && !visitEvent) || isNonVisitSchedule;
    const isVisitEventStarted = hasVisitEventStarted(visitEvent) || isNonVisitSchedule;
    const isVisitEventSkipped = hasVisitEventBeenSkipped(visitEvent);
    const visitIsOutsideVisitWindowRange = isOutsideVisitWindowRange(new Date(), visit);
    return (_jsxs("div", { className: 'bg-neutral-100 p-4 h-full', children: [isSiteFetching && _jsx(Spinner, { wrapper: 'full' }), visitEventHasError && (_jsx("div", { className: 'py-2', children: _jsx(InlineNotification, { subtitle: translate('error.activities_list.visit_event'), title: '', type: 'error' }) })), !isOnboarding && visit.c_group && visitIsOutsideVisitWindowRange && (_jsx("section", { className: 'flex items-center pb-4', children: _jsx(InlineNotification, { subtitle: translate('info.activities_list.visit.outside_window'), title: '', type: 'warning' }) })), !isOnboarding && !isVisitEventStarted && !isVisitEventSkipped && canVisitBeActedOn && (_jsx(VisitConfirmation, { visitIsOutsideVisitWindowRange: visitIsOutsideVisitWindowRange, participantId: participantId ?? '', visit: visit, siteId: site?._id ?? '', environment: environment, onActionComplete: onActionComplete })), isVisitEventSkipped && (_jsx("div", { className: 'pb-2', children: _jsx(Badge, { variant: 'incomplete', label: translate('info.activities_list.visit.skipped') }) })), _jsx(SiteTasksList, { isVisitEventStarted: !!isVisitEventStarted, isVisitEventSkipped: !!isVisitEventSkipped, shouldDisableActivityStartButtons: (shouldDisableActivityButtons && !isVisitEventStarted) || isVisitEventSkipped || !canVisitBeActedOn, activities: isOnboarding ? onboardingSchedule : results[0].data || [], hasError: results[0].isError, visit: visit, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, visitEventHasError: visitEventHasError, endOfStudyHasBeenStarted: endOfStudyHasBeenStarted }), isParticipantFetching && (_jsx(InlineNotification, { subtitle: translate('info.activities_list.activities.waiting'), title: '', type: 'info' })), !isOnboarding && (_jsx(ParticipantTasksList, { hasError: results[1].isError, activities: results[1].data || [], visit: visit }))] }));
};
