import { doc } from 'firebase/firestore';
import { useContext, useMemo } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { FirebaseAppContext, FirebaseAuthContext } from 'libs.firebase_react';
export function useIrbJobs() {
    const { db } = useContext(FirebaseAppContext);
    const { studyId } = useParams();
    const { userClaims, loading: loadingAuth, error: errorAuth } = useContext(FirebaseAuthContext);
    const entityId = userClaims?.entityId;
    const _doc = useMemo(() => {
        if (loadingAuth || errorAuth || !db || !entityId) {
            return null;
        }
        return doc(db, `entities/${entityId}/irb/study.${studyId}`);
    }, [loadingAuth, errorAuth, db, entityId]);
    const [value, loading, error] = useDocumentData(_doc, {
        snapshotListenOptions: { includeMetadataChanges: true },
    });
    return { data: value, isLoading: loading, error };
}
