import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, Table } from 'libs.nucleus';
export const SelectLocalesStep = ({ locales, selectedLocales, onRowSelectionChange }) => {
    const translate = useLocalizeMessage();
    const localesColumns = [
        { header: translate('Code'), accessorKey: 'code' },
        { header: translate('Language/Script'), accessorKey: 'language' },
        { header: translate('Country/Region'), accessorKey: 'region' },
    ];
    return (_jsx(Table, { data: locales, columns: localesColumns, labelNoResults: translate('No locales'), getRowId: 'code', hasPagination: true, initialPageSize: 10, paginationNavigationScreenReaderLabel: translate('Select locales table navigation'), isSelectable: true, initialSelectedIds: selectedLocales, onRowSelectionChange: onRowSelectionChange, useInternalSearch: true, labelSearchField: translate('Search locales') }));
};
