import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus';
import { PageHeader, PageWrapper, useBooleanState } from 'libs.react';
import { WorkspaceSiteModal, WorkspaceSitesTable } from '../../components';
export const WorkspaceSiteList = () => {
    const translate = useLocalizeMessage();
    const [isCreationModalOpen, setCreationModalOpen, setCreationModalClose] = useBooleanState();
    const headerOptions = [
        {
            name: translate('Create site'),
            variant: 'primary',
            onClick: setCreationModalOpen,
        },
    ];
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('Sites'), subtitle: translate('Create and manage sites in your workspace.'), options: headerOptions }), _jsx(WorkspaceSitesTable, {}), isCreationModalOpen && _jsx(WorkspaceSiteModal, { onClose: setCreationModalClose })] }));
};
