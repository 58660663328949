import { getApiInstance } from '../../../helpers/api.helpers';
export const fetchSiteActivities = async ({ environment, visit, participantId, site }) => {
    if (!environment || (visit && !visit.c_group)) {
        return [];
    }
    const responseSiteTasks = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_groups/${visit?.c_group}/c_task_assignments`);
    return responseSiteTasks?.data.data;
};
export const fetchParticipantActivities = async ({ environment, participantId }) => {
    if (!environment || !participantId) {
        return [];
    }
    const responseParticipantActivities = await getApiInstance(environment).get(`/v2/routes/c_public_users/${participantId}/c_events`, {
        params: {
            paths: [
                'c_start',
                'c_end',
                'c_task.c_name',
                'c_task.c_type',
                'c_completed',
                'type',
                'c_missed',
                'c_task_assignment.c_schedule_rules',
                'c_auto_start',
                'c_canceled',
                'c_title',
                'c_timezone',
                'c_task_assignment.c_order',
                'c_order',
                'c_visit_id',
                'c_visit_name',
                'c_enforce_order',
                'c_task_assignment.c_visit',
                'c_modality',
            ],
            include: ['c_task_response'],
        },
    });
    if (responseParticipantActivities?.data.generating) {
        return [{ generating: true }];
    }
    return responseParticipantActivities?.data.data;
};
export const fetchVisitEvent = async ({ environment, visitId, participantId }) => {
    if (!environment || !participantId) {
        return [];
    }
    const responseVisitEventDetails = await getApiInstance(environment).get('/v2/c_events', {
        params: {
            where: {
                c_schedule_visit: visitId,
                c_public_user: participantId,
                type: 'c_visit_event',
            },
        },
    });
    const visitEvent = responseVisitEventDetails?.data.data.find((visitEvent) => visitEvent?.c_schedule_visit?._id === visitId);
    return visitEvent || null;
};
export const fetchParticipantVisitEvents = async ({ environment, participantId, }) => {
    if (!environment || !participantId) {
        return [];
    }
    const responseVisitEvents = await getApiInstance(environment).get('/v2/c_events', {
        params: {
            where: {
                c_public_user: participantId,
                type: 'c_visit_event',
            },
        },
    });
    return responseVisitEvents?.data.data;
};
