export const isValidResource = (resource) => {
    return resource && (resource === '*' || (resource.includes('/') && resource.split('/').length % 2 === 0));
};
const validateInput = (scopes, permission, resource) => {
    if (!scopes) {
        throw new TypeError('Invalid scopes');
    }
    if (!permission) {
        throw new TypeError(`Invalid permission"`);
    }
    if (resource && resource !== true && !isValidResource(resource)) {
        throw new TypeError(`Invalid resource "${resource}"`);
    }
};
/**
 *
 * @param scopes The scopes to validate against.
 * @param permission The permission to check.
 * @param resource The resource being accessed.
 * @param anyResource Used to validate for any resource for the given permission
 * @returns boolean indicating whether the identity has the permission on the resource.
 */
export const checkScopes = ({ scopes, permission, resource, anyResource }) => {
    validateInput(scopes, permission, anyResource || resource);
    const resourcesForPermission = scopes[permission];
    if (!resourcesForPermission || resourcesForPermission.length === 0) {
        return false;
    }
    if (anyResource) {
        return true;
    }
    const [, appliesTo] = permission.split('.');
    if (!appliesTo) {
        throw new TypeError(`Invalid permission "${permission}"`);
    }
    return resourcesForPermission.some((allowedResource) => {
        let newAllowedResource = allowedResource;
        if (newAllowedResource === '*') {
            return true;
        }
        // remove trailing wildcard if present
        if (newAllowedResource.endsWith('/*')) {
            newAllowedResource = newAllowedResource.slice(0, -2);
        }
        const resourceParts = resource.split('/');
        const allowedParts = newAllowedResource.split('/');
        const parentResource = findParent(allowedResource, appliesTo);
        // Check for downward propagation (allowed resource is a parent of or equal to the resource)
        // e.g. resource = 'entity/123/project/xyz/environment/abc'
        //      allowedResource = 'entity/123'
        // check for upward propagation (the permission applies to a parent)
        // e.g. resource = 'entity/123'
        //      allowedResource = 'entity/123/project/xyz/environment/abc'
        //      permission = 'mom.entity.get'
        //      parentResource = 'entity/123'
        return (resourceParts.slice(0, allowedParts.length).join('/') === newAllowedResource ||
            (allowedResource !== parentResource && resource === parentResource) // upward propagation
        );
    });
};
/**
 * Looks for a specific target in the given resource
 * if found, it returns the parent resource
 * if not found, it returns the original resource
 *
 * e.g. resource = 'entity/123/project/xyz/environment/abc'
 *      target = 'project'
 *      returns 'entity/123/project/xyz'
 */
const findParent = (resource, target) => {
    const parts = resource.split('/');
    const index = parts.indexOf(target);
    if (index === -1 || index === parts.length - 1) {
        // If not found or no ID after target, return original
        return resource;
    }
    // return the parent resource, ignoring whatever is after the target (index + 2 means ignoring the target and its id)
    return parts.slice(0, index + 2).join('/');
};
