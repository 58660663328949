import { AxiosError } from 'axios';
import { isEmpty, isEqual, mapValues, omitBy, trim } from 'lodash';
import { useState } from 'react';
import { useLocalizeMessage, useToastNotification } from 'libs.nucleus';
import { validateFormFieldOnBlur, validateFormFieldOnChange } from './workspace_site_form.helpers';
import { useWorkspaceSiteMutation } from '../../hooks';
export const useWorkspaceSiteForm = ({ workspaceSite, onSuccess, onError }) => {
    const formInitValue = {
        name: workspaceSite?.name || '',
        number: workspaceSite?.number || '',
        timezone: workspaceSite?.timezone || '',
        email: workspaceSite?.email || '',
        phone: workspaceSite?.address.phone[0]?.number || '',
        countryCode: workspaceSite?.address.countryCode || '',
        addressLine: workspaceSite?.address.addressLine || '',
        city: workspaceSite?.address.city || '',
        region: workspaceSite?.address.region || '',
        postalCode: workspaceSite?.address.postalCode || '',
    };
    const [form, setForm] = useState(formInitValue);
    const [errors, setErrors] = useState({});
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { isPending, mutateAsync } = useWorkspaceSiteMutation(workspaceSite?.id);
    const isEditMode = !isEmpty(workspaceSite);
    const isSubmitDisabled = () => {
        // check if mutation/saving is pending
        if (isPending) {
            return true;
        }
        // check if there are any validation errors
        if (!isEmpty(errors)) {
            return true;
        }
        // check if initial values are equal to form values
        if (isEditMode) {
            const preparedFromValue = mapValues(form, (value) => (typeof value === 'string' ? trim(value) : value));
            return isEqual(preparedFromValue, formInitValue);
        }
        return false;
    };
    const validateFormOnSubmit = () => {
        const errors = {};
        Object.keys(form).forEach((key) => {
            const errorOnChange = validateFormFieldOnChange(key, form[key]);
            const errorOnBlur = validateFormFieldOnBlur(key, form[key]);
            const error = errorOnBlur || errorOnChange;
            if (error) {
                errors[key] = translate(error);
            }
        });
        return errors;
    };
    const onSubmit = async () => {
        const errors = validateFormOnSubmit();
        if (!isEmpty(errors)) {
            setErrors(errors);
            return;
        }
        try {
            const preparedForm = omitBy(form, isEmpty);
            const payload = {
                name: preparedForm.name,
                number: preparedForm.number,
                timezone: preparedForm.timezone,
                email: preparedForm.email,
                address: {
                    countryCode: preparedForm.countryCode,
                    addressLine: preparedForm.addressLine,
                    city: preparedForm.city,
                    region: preparedForm.region,
                    postalCode: preparedForm.postalCode,
                    phone: preparedForm.phone
                        ? [
                            {
                                type: '24-hour',
                                number: preparedForm.phone,
                            },
                        ]
                        : undefined,
                },
            };
            const response = await mutateAsync(payload);
            addNotification({
                title: isEditMode ? translate('Edit workspace site') : translate('Create workspace site'),
                subtitle: isEditMode ? translate('Site record has been updated.') : translate('Site record has been created.'),
                type: 'success',
            });
            onSuccess && onSuccess(response.data.data);
            if (isEditMode) {
                setErrors({});
            }
            else {
                setForm(formInitValue);
                setErrors({});
            }
        }
        catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
                const title = '';
                const subtitle = '';
                const defaultErrorTitle = isEditMode
                    ? translate('Edit workspace site error')
                    : translate('Create workspace site error');
                const defaultErrorMessage = isEditMode
                    ? translate('Site record was not updated')
                    : translate('Site record was not created');
                addNotification({
                    title: title || defaultErrorTitle,
                    subtitle: subtitle || defaultErrorMessage,
                    type: 'error',
                });
            }
            else {
                addNotification({
                    title: isEditMode ? translate('Edit workspace site') : translate('Create workspace site'),
                    subtitle: isEditMode ? translate('Site record was not updated') : translate('Site record was not created'),
                    type: 'error',
                });
            }
            onError && onError();
        }
    };
    return {
        isEditMode,
        isPending,
        isSubmitDisabled,
        formValue: form,
        formErrors: errors,
        setFormValue: setForm,
        setFormErrors: setErrors,
        submitFrom: onSubmit,
    };
};
