import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { Spinner, Tabs, useDialog, useLocalizeMessage, useToastNotification, } from 'libs.nucleus';
import { PageHeader, PageWrapper, useBooleanState, useTabNavigation } from 'libs.react';
import { ParticipantCaregivers } from '../../components/participant_caregivers/participant_caregivers.components';
import { ParticipantDetails } from '../../components/participant_details/participant_details.component';
import { ParticipantDocuments } from '../../components/participant_documents/participant_documents.component';
import { ParticipantVisits } from '../../components/participant_visits/participant_visits.component';
import { SiteContext } from '../../contexts/site';
import { getApiErrorMessage, getApiInstance } from '../../helpers/api.helpers';
import { getParticipantDetails, getParticipantNumber, isParticipantACaregiver, isParticipantAuthenticated, shouldShowTelevistButton, } from '../../helpers/participants.helpers';
import { extractClassicAppsBaseUrl } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useLumosAuth } from '../../hooks/use_lumos_auth';
import { useOrgContext } from '../../hooks/use_org_context';
import { EParticipantInviteStatus } from '../../types/participants.type';
import { LUMOS_PERMISSIONS } from '../../types/permissions.types';
export const ParticipantDetailsPage = () => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const org = useOrgContext();
    const [isBusy, setIsbusy, unsetIsBusy] = useBooleanState(false);
    const { isUserPermitted } = useLumosAuth();
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const { addNotification } = useToastNotification();
    const dialog = useDialog();
    const queryClient = useQueryClient();
    const [activeTab, setActiveTab] = useTabNavigation([
        '#visits',
        '#details',
        '#documents',
        '#caregivers',
        '#audit-history',
    ]);
    const getParticipantData = async () => {
        if (!environment) {
            return {};
        }
        return await getParticipantDetails({
            environment,
            siteId: site?._id ?? '',
            participantId: participantId ?? '',
        });
    };
    const handleInviteCancel = async () => {
        const ok = await dialog.open({
            title: translate('label.participant_details.cancel_invite'),
            body: translate('prompt.participant_details.cancel_confirmation'),
            actionButtons: [
                {
                    isDestructive: true,
                    label: translate('action.participant_details.cancel_invite_accept'),
                    dataTestId: 'cancel-invite-accept',
                },
                { label: translate('action.participant_details.cancel_invite_decline'), dataTestId: 'cancel-invite-decline' },
            ],
        });
        if (ok && environment) {
            try {
                setIsbusy();
                await getApiInstance(environment).post('/routes/cancel_invite', {
                    c_site: site?._id,
                    c_public_user: participantId,
                });
                addNotification({
                    title: '',
                    subtitle: translate('status.participant_details.invite_canceled'),
                    type: 'success',
                });
                queryClient.invalidateQueries({
                    queryKey: ['participantDetails', participantId],
                });
            }
            catch (e) {
                const message = getApiErrorMessage(e);
                addNotification({
                    title: '',
                    subtitle: message ?? translate('error.generic'),
                    type: 'error',
                });
            }
            finally {
                unsetIsBusy();
            }
        }
    };
    const onHandleNavigationToTelevist = () => {
        if (!environment) {
            return '';
        }
        window
            .open(`${extractClassicAppsBaseUrl(environment.cortexURL, 'site')}/participants/${participantId}?orgCode=${environment.code}`, '_blank')
            ?.focus();
    };
    const { data: participantData, isLoading } = useQuery({
        queryKey: ['participantDetails', participantId],
        queryFn: getParticipantData,
        enabled: true,
    });
    const breadcrumbsPages = [
        { name: translate('generic.participants'), path: `/site/study/${environment?.id}/participants` },
        { name: participantData?.c_number || translate('label.participant_details.detail'), path: '' },
    ];
    const hasEconsent = org?.c_requires_econsent_field;
    const isCaregiverFeatureEnabled = (org?.c_caregiver_enabled ?? false) && !isParticipantACaregiver(participantData);
    const headerOptions = [];
    const canCancelInvite = isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_CANCEL_INVITE) &&
        !isParticipantAuthenticated(participantData) &&
        participantData?.c_invite !== EParticipantInviteStatus.EXPIRED;
    //  If participant isnt authenticated and invite is not expired, show cancel invite button
    if (canCancelInvite) {
        headerOptions.push({
            name: translate('action.participant_details.cancel_invite'),
            onClick: handleInviteCancel,
            variant: 'secondary',
            dataTestId: 'cancel-invite-button',
        });
    }
    if (shouldShowTelevistButton(participantData, org)) {
        headerOptions.push({
            name: translate('action.participant_details.redirect_to_TV'),
            onClick: onHandleNavigationToTelevist,
            variant: 'primary',
            dataTestId: 'televist-button',
            iconName: 'externalLink',
        });
    }
    const tabItems = [
        {
            content: _jsx(ParticipantVisits, { participantNumber: participantData?.c_number || '' }, 'participant-visits'),
            label: translate('generic.visits'),
        },
        {
            content: _jsx(ParticipantDetails, { participant: participantData, participantDetailsLoading: isLoading }),
            label: translate('generic.details'),
        },
        {
            content: _jsx(ParticipantDocuments, { participant: participantData }),
            label: translate('generic.consent'),
            hidden: !hasEconsent,
        },
        {
            content: _jsx(ParticipantCaregivers, { participant: participantData, participantDetailsLoading: isLoading }),
            label: translate('generic.caregivers'),
            hidden: !isCaregiverFeatureEnabled,
        },
    ];
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { breadcrumbPages: breadcrumbsPages, title: participantData ? getParticipantNumber(participantData) : '', options: headerOptions }), isBusy && _jsx(Spinner, { wrapper: 'full' }), _jsx(Tabs, { tabItems: tabItems, defaultIndex: activeTab, onTabChange: setActiveTab })] }));
};
