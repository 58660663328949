import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Button, Spinner, useDialog, useLocalizeMessage, useToastNotification } from 'libs.nucleus';
import { onSkipVisitConfirmation, onStartVisitConfirmation } from './helpers/api_visit_confirmation_helpers';
import { getApiErrorMessage } from '../../helpers/api.helpers';
import { useLumosAuth } from '../../hooks/use_lumos_auth';
import { LUMOS_PERMISSIONS } from '../../types/permissions.types';
export const VisitConfirmation = ({ environment, participantId, siteId, visit, visitIsOutsideVisitWindowRange, onActionComplete, }) => {
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { addNotification } = useToastNotification();
    const queryClient = useQueryClient();
    const { isUserPermitted } = useLumosAuth();
    const visitId = visit.c_visit;
    const invalidateCache = () => {
        queryClient.invalidateQueries({
            queryKey: ['visitEvent', visitId, participantId],
        });
        queryClient.invalidateQueries({
            queryKey: ['visitEvent', participantId],
        });
        queryClient.invalidateQueries({
            queryKey: ['siteActivities', participantId, visitId, siteId],
        });
        queryClient.invalidateQueries({
            queryKey: ['participantActivities', participantId],
        });
    };
    const onHandleStartVisit = async () => {
        const onStartVisit = await dialog.open({
            title: translate('prompt.visit_confirmation.start'),
            body: (_jsxs("div", { className: 'font-sans m-0 text-sm text-text-secondary', children: [visitIsOutsideVisitWindowRange && (_jsx("p", { "data-testid": 'outside-visit-range', children: translate('info.visit_confirmation.start_notice') })), _jsx("p", { "data-testid": 'activities-become-enabled', children: translate('info.visit_confirmation.activities_available') })] })),
            actionButtons: [
                {
                    label: translate('generic.start'),
                    dataTestId: 'start-visit-confirm',
                },
                { label: translate('generic.cancel'), dataTestId: 'start-visit-cancel' },
            ],
            willCloseOnOutsideClickAndEscapeKeyPress: false,
        });
        if (onStartVisit) {
            setIsSubmitting(true);
            try {
                await onStartVisitConfirmation({ environment, participantId, visitId });
                invalidateCache();
                setIsSubmitting(false);
                addNotification({
                    title: '',
                    subtitle: translate('status.visit_confirmation.started'),
                    type: 'success',
                });
                onActionComplete?.('confirm');
            }
            catch (error) {
                console.error(error);
                setIsSubmitting(false);
                addNotification({
                    title: '',
                    subtitle: getApiErrorMessage(error) ?? translate('error.visit_confirmation.failed'),
                    type: 'error',
                });
            }
        }
    };
    const onHandleSkipVisit = async () => {
        const onSkipVisit = await dialog.open({
            title: translate('prompt.visit_confirmation.skip'),
            body: translate('info.visit_confirmation.skip_notice'),
            actionButtons: [
                {
                    label: translate('generic.skip'),
                    dataTestId: 'skip-visit-confirm',
                },
                {
                    label: translate('generic.cancel'),
                    dataTestId: 'skip-visit-cancel',
                },
            ],
            willCloseOnOutsideClickAndEscapeKeyPress: false,
        });
        if (onSkipVisit) {
            setIsSubmitting(true);
            try {
                await onSkipVisitConfirmation({ environment, participantId, visitId });
                invalidateCache();
                addNotification({
                    title: '',
                    subtitle: translate('status.visit_confirmation.skipped'),
                    type: 'success',
                });
                onActionComplete?.('skip');
            }
            catch (error) {
                addNotification({
                    title: '',
                    subtitle: getApiErrorMessage(error) ?? translate('error.visit_confirmation.skip_failed'),
                    type: 'error',
                });
            }
            finally {
                setIsSubmitting(false);
            }
        }
    };
    return (_jsxs("div", { className: 'flex py-4 justify-between', children: [isSubmitting && _jsx(Spinner, { wrapper: 'full' }), _jsx("div", { className: 'content-center', children: _jsx("p", { children: translate('info.visit_confirmation.activities_enabled') }) }), _jsxs("div", { className: 'flex gap-2', children: [visit.c_optional && isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_SKIP_VISIT) && (_jsx(Button, { onClick: onHandleSkipVisit, dataTestId: 'skip-visit', label: translate('action.visit.skip'), variant: 'secondary' })), isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_START_VISIT) && (_jsx(Button, { onClick: onHandleStartVisit, dataTestId: 'start-visit', label: translate('action.visit.start') }))] })] }));
};
