import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocalizeMessage, Link } from 'libs.nucleus';
import { useDateTime } from 'libs.react';
import { getParticipantNextVisitWindow, getParticipantNumber, getParticipantStatus, } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const useParticipantsTableColumns = () => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { parseDateTimeShort } = useDateTime();
    const participantsColumns = useMemo(() => {
        return [
            {
                header: translate('label.participant_id'),
                accessorKey: 'c_number',
                cell: (context) => {
                    const row = context.row.original;
                    return (_jsx(Link, { LinkComponent: RouterLink, to: `/site/study/${environment?.id}/participants/${row._id}`, children: getParticipantNumber(row) }));
                },
            },
            {
                header: translate('generic.create', { tense: 'past' }),
                accessorKey: 'created',
                cell: (context) => parseDateTimeShort(context.getValue()),
            },
            {
                header: translate('label.participants_table.next_visit_window'),
                accessorKey: 'c_visit_schedule',
                cell: (context) => {
                    return getParticipantNextVisitWindow(context.row.original);
                },
            },
            {
                header: translate('generic.status'),
                accessorKey: 'c_status',
                cell: (context) => {
                    const status = context.getValue();
                    return getParticipantStatus(status);
                },
            },
        ];
    }, []);
    return {
        participantsColumns,
    };
};
