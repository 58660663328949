export const hasVisitEventStarted = (visitEvent) => {
    return visitEvent?.c_started !== null && visitEvent?.c_active;
};
export const hasVisitEventBeenSkipped = (visitEvent) => {
    return visitEvent?.c_skipped;
};
export const hasEndOfStudyBeenStarted = (visitScheduleWithEvents) => {
    const EndOfStudyVisit = visitScheduleWithEvents.find((visitEvent) => visitEvent.c_type === 'end_of_study');
    const firstVisitEvent = EndOfStudyVisit?.visitEvents?.[0];
    return firstVisitEvent ? hasVisitEventStarted(firstVisitEvent) : false;
};
export const isVisitEnabled = ({ currentVisit, visitScheduleWithEvents, }) => {
    //  If its an onboarding visit then allow it
    if (currentVisit.c_visit === '' && currentVisit.customVisitType === 'onboarding') {
        if (hasEndOfStudyBeenStarted(visitScheduleWithEvents)) {
            return false;
        }
        return true;
    }
    if (['standard', 'unscheduled'].includes(currentVisit?.c_type ?? '') ||
        currentVisit.customVisitType === 'non_visit_schedule') {
        if (hasEndOfStudyBeenStarted(visitScheduleWithEvents)) {
            return false;
        }
    }
    // end of study, follow up visits and unscheduled visits are not actionable until the first visit has started
    if (['end_of_study', 'follow_up', 'unscheduled'].includes(currentVisit?.c_type ?? '')) {
        const firstVisit = visitScheduleWithEvents[0];
        const associatedVisitEvent = firstVisit?.visitEvents?.[0];
        if (associatedVisitEvent &&
            (hasVisitEventStarted(associatedVisitEvent) || hasVisitEventBeenSkipped(associatedVisitEvent))) {
            return true;
        }
        return false;
    }
    const currentVisitScheduleWithEvents = visitScheduleWithEvents.find((visitEvent) => visitEvent.c_visit === currentVisit.c_visit);
    if (!currentVisitScheduleWithEvents) {
        return false;
    }
    const standardVisits = visitScheduleWithEvents.filter((visitEvent) => visitEvent.c_type === 'standard');
    const prevVisit = standardVisits.find((visitEvent) => visitEvent.c_sequence === currentVisitScheduleWithEvents.c_sequence - 1);
    const nextVisit = standardVisits.find((visitEvent) => visitEvent.c_sequence === currentVisitScheduleWithEvents.c_sequence + 1);
    //  If there is no previous visit and no next visit, then allow it
    //  This is the case for the first visit
    if (!prevVisit && !nextVisit?.visitEvents?.length) {
        return true;
    }
    //  If there is a next visit and it has started, then don't allow it
    //  This is to prevent activities on current visit from being started if the next visit has started
    if (nextVisit && nextVisit?.visitEvents?.length) {
        return false;
    }
    if (prevVisit && prevVisit?.visitEvents?.length) {
        return true;
    }
    return false;
};
