import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, useToastNotification } from 'libs.nucleus';
import { ApiClientService, AuthContext, useApiClient, useBooleanState } from 'libs.react';
import { ContinueToStudyTrainingPrompt } from '../../components/studies_list/continue_to_study_training.prompt.component';
import { fetchSiteUserStudySpecificTraining, fetchStudySpecificTraining, } from '../../components/studies_list/helpers/study_resource.helpers';
import { ViewStudyTraining } from '../../components/studies_list/view_study_training.component';
import { getApiErrorMessage } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { OrgContext } from '../org';
import { UserLocaleContext } from '../user_locale/user_locale.context';
const StudyTrainingContext = createContext({
    isLoading: false,
    hasPassedTrainingCheck: false,
});
export const StudyTrainingProvider = ({ children }) => {
    const { environment } = useCurrentEnvironment();
    const { org } = useContext(OrgContext);
    const navigate = useNavigate();
    const momClient = useApiClient(ApiClientService.MOM);
    const { entityId, user } = useContext(AuthContext);
    const { addNotification } = useToastNotification();
    const { isUserLocaleChecked, getUserSelectedLocale } = useContext(UserLocaleContext);
    const [isTrainingLoading, setIsTrainingLoading, setIsNotTrainingLoading] = useBooleanState();
    const [isPromptOpen, setIsPromptOpen] = useState(false);
    const [studyPDF, setStudyPDF] = useState(null);
    const [hasPassedTrainingCheck, setHasPassedTrainingCheck] = useState(false);
    const [isStudyTrainingModalWindowOpen, setIsStudyTrainingModalWindowOpen, setIsStudyTrainingModalModalWindowClosed] = useBooleanState(false);
    const { data: studyTraining = [], isFetching } = useQuery({
        queryKey: ['studyTraining', environment?.id?.toString(), 'org', org?._id],
        refetchOnWindowFocus: false,
        queryFn: () => {
            if (environment && org) {
                return fetchStudySpecificTraining({ environment, studyId: org._id, locale: getUserSelectedLocale() });
            }
            return null;
        },
        enabled: !!environment && !!org,
    });
    const checkIfSiteUserHasCompletedStudySpecificTraining = async ({ environment }) => {
        try {
            setIsTrainingLoading();
            const response = await fetchSiteUserStudySpecificTraining({ entityId, environment, momClient });
            if (response && response.identityId === user?.id && !!response.completedOn) {
                // TODO: redirect to home /// or leave as is because you are on a route
                setHasPassedTrainingCheck(true);
            }
            else {
                setHasPassedTrainingCheck(false);
                setIsPromptOpen(true);
            }
        }
        catch {
            addNotification({
                type: 'error',
                title: 'Error',
                subtitle: 'Failed to fetch user training status',
            });
        }
        finally {
            setIsNotTrainingLoading();
        }
    };
    const checkForStudySpecificTraining = async () => {
        try {
            const studyResource = studyTraining;
            if (studyResource && studyResource.length) {
                setStudyPDF(studyResource[0]);
                await checkIfSiteUserHasCompletedStudySpecificTraining({ environment: environment });
            }
            else {
                // TODO: redirect to home /// or leave as is because you are on a route
                setHasPassedTrainingCheck(true);
            }
        }
        catch (e) {
            const message = getApiErrorMessage(e);
            addNotification({
                type: 'error',
                title: 'Error',
                subtitle: message ?? 'Failed to fetch study training',
            });
        }
    };
    const contineToStudyTraining = async () => {
        setIsPromptOpen(false);
        setIsStudyTrainingModalWindowOpen();
    };
    const onTrainingComplete = async () => {
        //  Reloading the page to get the latest data and to recheck for training
        //  just closing the modal causes a PDF on the page to be rendered
        //  TODO?? - maybe find a better solution when there is time
        window.location.reload();
    };
    useEffect(() => {
        if (environment && studyTraining) {
            checkForStudySpecificTraining();
        }
    }, [studyTraining]);
    const value = useMemo(() => ({
        isLoading: isFetching,
        hasPassedTrainingCheck,
    }), [isFetching, hasPassedTrainingCheck]);
    if (!isUserLocaleChecked) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(StudyTrainingContext.Provider, { value: value, children: [(isFetching || isTrainingLoading) && _jsx(Spinner, { wrapper: 'full' }), children, _jsx(ContinueToStudyTrainingPrompt, { isPromptOpen: isPromptOpen, onHandleContinue: contineToStudyTraining, onCancel: () => {
                    setIsPromptOpen(false);
                    navigate('/site/dashboard');
                }, onClosePrompt: () => {
                    setIsPromptOpen(false);
                } }), studyPDF && (_jsx(ViewStudyTraining, { selectedEnvironment: environment, isStudyTrainingModalWindowOpen: isStudyTrainingModalWindowOpen, onCancel: () => {
                    setIsStudyTrainingModalModalWindowClosed();
                    setIsPromptOpen(true);
                }, onDone: onTrainingComplete, studyPDF: studyPDF }))] }));
};
