import { postLumosTrainingLog } from './helpers/lumos_training_api.helpers';
import { LumoTrainingModules } from './lumos_training.modules.component';
import { LumosTrainingComplete } from './lumos_training_complete.component';
import { WelcomePage } from './welcome_page.component';
export const LumosTrainingSteps = [
    {
        component: WelcomePage,
        showBottomNavigation: false,
    },
    {
        component: LumoTrainingModules,
        showBottomNavigation: true,
    },
    {
        component: LumosTrainingComplete,
        showBottomNavigation: true,
    },
];
export const isDesktop = (screenWidth) => {
    return screenWidth >= 1284;
};
export const extractStoryLaneModuleId = (urlString) => {
    const url = new URL(urlString);
    const pathParts = url.pathname.split('/').filter((part) => part !== '');
    return pathParts.pop() || '';
};
export const submitLumosTrainingLog = async ({ momClient, entityId, trainingConfigId, trainingModules, currentModuleStep, siteUserModuleProgress, queryClient, currentModuleCompletionDate, locale, userId, }) => {
    // Check if module is already completed
    if (siteUserModuleProgress?.metadata?.moduleCompletionLogs.find((module) => module.code === trainingModules[currentModuleStep].code)) {
        return;
    }
    await postLumosTrainingLog({
        momClient,
        entityId,
        trainingConfigId,
        codeCompleted: trainingModules[currentModuleStep].code,
        currentModuleCompletionDate,
        locale,
        lastModule: currentModuleStep === trainingModules.length - 1,
    });
    queryClient.invalidateQueries({
        queryKey: ['entities', entityId, 'siteUserProgress', userId],
    });
};
