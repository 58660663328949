import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState, useRef } from 'react';
import { ModalWindow, useLocalizeMessage, useToastNotification, Logo, LocaleContext } from 'libs.nucleus';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
import { StudyPDFViewer } from './components/study_pdf_viewer.component';
import { StudyTrainingComplete } from './components/study_training_complete.component';
import { markStudySpecificTrainingAsCompleted } from './helpers/study_resource.helpers';
import { generatePDF } from '../../helpers/generate_pdf.helpers';
import { CertificateOfCompletion } from '../lumos_training/components/certifiicate_of_completion.component';
export const ViewStudyTraining = ({ selectedEnvironment, isStudyTrainingModalWindowOpen, onDone, studyPDF, onCancel, }) => {
    const currentLocale = useContext(LocaleContext);
    const certificateRef = useRef(null);
    const [agreeToTraining, setAgreeToTraining] = useState(false);
    const [showStudyTrainingComplete, setShowStudyTrainingComplete] = useState(false);
    const [markTrainingAsComplete, setMarkTrainingAsComplete] = useState(false);
    const [completedOn, setCompletedOn] = useState('');
    const [viewCertificate, setViewCertificate] = useState(false);
    const translate = useLocalizeMessage();
    const momClient = useApiClient(ApiClientService.MOM);
    const { addNotification } = useToastNotification();
    const { user } = useContext(AuthContext);
    const PDFUrl = studyPDF.c_file_content.url;
    const onHandleDownloadCertificate = () => {
        setViewCertificate(true);
    };
    const completionDate = new Date(completedOn ?? '').toLocaleDateString(currentLocale, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
    const onHandlePrimaryClick = async () => {
        if (!showStudyTrainingComplete) {
            setShowStudyTrainingComplete(true);
            setMarkTrainingAsComplete(true);
            try {
                const response = await markStudySpecificTrainingAsCompleted({
                    entityId: selectedEnvironment?.entityId || 0,
                    environment: selectedEnvironment,
                    momClient,
                    resourceId: studyPDF._id,
                    locale: studyPDF.c_file_content.locale,
                });
                if (response && !!response.completedOn) {
                    setCompletedOn(response.completedOn);
                    setMarkTrainingAsComplete(false);
                }
            }
            catch (e) {
                setMarkTrainingAsComplete(false);
                addNotification({
                    type: 'error',
                    title: translate('generic.error'),
                    subtitle: translate('error.study_training.user_fetch'),
                });
            }
        }
        else {
            onDone();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalWindow, { title: selectedEnvironment?.study?.name || '', isOpen: isStudyTrainingModalWindowOpen, hideCloseIcon: true, closeWindow: () => { }, footerPrimaryActionButton: {
                    disabled: !agreeToTraining || !PDFUrl || markTrainingAsComplete,
                    label: !showStudyTrainingComplete ? translate('generic.continue') : translate('generic.done'),
                    content: undefined,
                    onClick: onHandlePrimaryClick,
                }, ...(viewCertificate && {
                    footerSecondaryActionButtons: [
                        {
                            disabled: false,
                            label: translate('generic.download'),
                            content: undefined,
                            onClick: () => generatePDF(certificateRef, 'study_training_certificate_of_completion'),
                        },
                    ],
                }), ...(!showStudyTrainingComplete && {
                    footerSecondaryActionButtons: [
                        {
                            disabled: !PDFUrl,
                            label: translate('generic.download'),
                            content: undefined,
                            onClick: () => window.open(`${PDFUrl}`),
                        },
                        {
                            disabled: false,
                            label: translate('generic.cancel'),
                            content: undefined,
                            onClick: onCancel,
                        },
                    ],
                }), width: 'full', removeBodyPadding: true, children: viewCertificate ? (_jsx(CertificateOfCompletion, { user: user, completionDate: completionDate, studyName: selectedEnvironment?.study?.name || '' })) : showStudyTrainingComplete ? (_jsx(StudyTrainingComplete, { loading: markTrainingAsComplete, onDownloadCertificate: onHandleDownloadCertificate })) : (_jsx(StudyPDFViewer, { PDFUrl: PDFUrl, agreeToTraining: agreeToTraining, setAgreeToTraining: setAgreeToTraining })) }), viewCertificate && (_jsx("div", { ref: certificateRef, className: `w-full bg-white my-16 p-8 border border-gray-300 rounded-lg shadow-md`, style: {
                    height: '663.7007874px',
                    width: '950px',
                    display: viewCertificate ? 'block' : 'none',
                }, children: _jsxs("div", { className: 'bg-indigo-50 p-12 rounded-lg flex flex-col items-center justify-center text-center min-h-96', children: [_jsx("div", { className: 'mb-6', style: { width: '120px', height: '45px' }, children: _jsx(Logo, {}) }), _jsx("h1", { className: 'text-5xl font-bold mb-10 bg-gradient-to-r text-indigo-900', children: translate('info.lumos_training.certificate.title') }), _jsxs("h2", { className: 'text-3xl text-gray-600 font-medium mb-8', children: [user?.first, " ", user?.last] }), _jsx("div", { className: 'w-2/3 border-b border-gray-300 mb-8', children: "\u00A0" }), _jsx("h3", { className: 'text-4xl font-bold text-gray-800 tracking-wider mb-8', children: translate('info.lumos_training.certificate.study_training', {
                                studyName: selectedEnvironment?.study?.name || '',
                            }) }), _jsx("div", { className: 'w-2/3 border-b border-gray-300 mb-12', children: "\u00A0" }), _jsx("p", { className: 'text-xl text-gray-500 mb-12', children: completionDate })] }) }))] }));
};
