import { jsx as _jsx } from "react/jsx-runtime";
export const LumosTrainingModuleShowcase = ({ trainingModuleUrl }) => {
    return (_jsx("div", { className: 'flex items-start flex-[3] justify-center xl:items-center p-10', children: _jsx("div", { className: 'sl-embed', style: {
                position: 'relative',
                paddingBottom: 'calc(49.61% + 27px)',
                width: '100%',
                height: 0,
                transform: 'scale(1)',
            }, children: _jsx("iframe", { loading: 'lazy', className: 'sl-demo', src: trainingModuleUrl, name: 'sl-embed', allow: 'fullscreen', style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: '1px solid rgba(63,95,172,0.35)',
                    boxShadow: '0px 0px 18px rgba(26, 19, 72, 0.15)',
                    borderRadius: '10px',
                    boxSizing: 'border-box',
                } }) }) }));
};
