import { getApiInstance } from '../../../helpers/api.helpers';
export const fetchStudySpecificTraining = async ({ environment, studyId, locale, }) => {
    if (!environment || !studyId) {
        return null;
    }
    const studySpecificResource = await getApiInstance(environment).get(`/v2/c_studies/${studyId}/c_study_resources`, {
        headers: {
            'Accept-Language': locale,
        },
        params: {
            where: {
                type: 'c_site_training_pdf',
            },
        },
    });
    // returns an array even with one item
    return studySpecificResource.data?.data.length ? studySpecificResource.data.data : null;
};
export const fetchSiteUserStudySpecificTraining = async ({ entityId, environment, momClient, }) => {
    const response = await momClient.get(`/v1/entities/${entityId}/studies/${environment.studyId}/training`);
    return response.data?.data;
};
export const markStudySpecificTrainingAsCompleted = async ({ entityId, environment, momClient, resourceId, locale, }) => {
    const response = await momClient.post(`/v1/entities/${entityId}/studies/${environment.studyId}/training`, {
        resourceId,
        locale,
    });
    return response.data?.data;
};
