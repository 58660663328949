import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { useState, useEffect, useContext } from 'react';
import { LocaleContext, Spinner, Steps, useLocalizeMessage } from 'libs.nucleus';
import { ApiClientService, AuthContext, useApiClient } from 'libs.react';
import { LumosTrainingModuleShowcase } from './components/lumos_training_module_showcase.component';
import { extractStoryLaneModuleId, isDesktop, submitLumosTrainingLog } from './lumos_training.utils';
import { useDeviceScreenWidth } from '../../hooks/use_device_screen_width';
import { useLumosTrainingProgress } from '../../hooks/use_lumos_training';
export const LumoTrainingModules = ({ onHandleAllModulesCompleted }) => {
    const { entityId, user } = useContext(AuthContext);
    const currentLocale = useContext(LocaleContext);
    const momClient = useApiClient(ApiClientService.MOM);
    const translate = useLocalizeMessage();
    const { screenWidth } = useDeviceScreenWidth();
    const isDesktopView = isDesktop(screenWidth);
    const queryClient = useQueryClient();
    const { startingModuleIndex, allModulesCompleted, trainingModules, siteUserModuleProgress, currentTrainingConfigId, isLoadingTrainingModules, } = useLumosTrainingProgress();
    const [currentModuleStep, setCurrentModuleStep] = useState(0);
    const onHandleModuleClick = (index) => {
        setCurrentModuleStep(index);
    };
    const onMoveToNextModule = () => {
        setCurrentModuleStep((prevModuleStep) => prevModuleStep + 1);
    };
    const handleTrainingTracking = async (event) => {
        if (trainingModules &&
            event.data.message === 'storylane-demo-event' &&
            event.data.payload.event === 'demo_finished') {
            const currentMdoduleId = extractStoryLaneModuleId(trainingModules[currentModuleStep].trainingUrl);
            const moduleIdCompleted = event.data.payload.demo?.id;
            if (currentMdoduleId === moduleIdCompleted) {
                if (!allModulesCompleted) {
                    submitLumosTrainingLog({
                        momClient,
                        entityId,
                        trainingConfigId: currentTrainingConfigId,
                        trainingModules,
                        currentModuleStep,
                        locale: currentLocale,
                        siteUserModuleProgress: siteUserModuleProgress || {},
                        queryClient,
                        currentModuleCompletionDate: new Date().toISOString(),
                        userId: user?.id || 0,
                    });
                }
                if (currentModuleStep === trainingModules.length - 1) {
                    onHandleAllModulesCompleted();
                }
                else {
                    onMoveToNextModule();
                }
            }
            else {
                console.log('No training modules found');
            }
        }
    };
    /**
     * Initialize event tracking for the training
     */
    useEffect(() => {
        window.addEventListener('message', handleTrainingTracking);
        return () => {
            window.removeEventListener('message', handleTrainingTracking);
        };
    }, [trainingModules, currentModuleStep, allModulesCompleted, siteUserModuleProgress]);
    useEffect(() => {
        setCurrentModuleStep(startingModuleIndex);
        return () => {
            setCurrentModuleStep(0);
        };
    }, [startingModuleIndex]);
    return (_jsxs("div", { className: 'flex flex-col xl:flex-row xl:flex-grow-1', children: [_jsx("div", { className: 'flex w-[90%] my-0 mx-auto items-center justify-center relative xl:flex-[1] xl:p-10 xl:overflow-hidden', children: _jsx("div", { className: 'max-w-full', children: isLoadingTrainingModules ? (_jsx(Spinner, { size: 'md' })) : (_jsx(Steps, { activeStep: currentModuleStep, orientation: isDesktopView ? 'vertical' : 'horizontal', sequential: false, steps: (trainingModules &&
                            trainingModules?.map((trainingModule, index) => {
                                const moduleKey = trainingModule.title; // keys will be lowercase as per the translation file
                                return {
                                    name: translate(moduleKey),
                                    onClick: () => {
                                        if (index > startingModuleIndex) {
                                            return;
                                        }
                                        onHandleModuleClick(index);
                                    },
                                    href: '#',
                                };
                            })) ||
                            [] })) }) }), trainingModules && (_jsx(LumosTrainingModuleShowcase, { trainingModuleUrl: trainingModules[currentModuleStep]?.trainingUrl }))] }));
};
