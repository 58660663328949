import axios from 'axios';
export const getApiInstance = (environment) => {
    const axiosInstance = axios.create({
        baseURL: `${environment.cortexURL}/${environment.code}`, // Replace with your API base URL
        headers: {
            'Medable-Client-Key': 'MedableIAMDefaultClientKey',
        },
        withCredentials: true,
    });
    return axiosInstance;
};
export const getApiErrorMessage = (error, preferReason = false) => {
    const errorData = error?.response?.data;
    if (!errorData?.faults && errorData?.message && !preferReason) {
        return errorData?.message;
    }
    function findFaultMessage(faults) {
        if (!faults) {
            return faults;
        }
        for (const fault of faults) {
            if (fault.reason) {
                return fault.reason;
            }
            if (!fault.faults && fault.message) {
                return fault.message;
            }
            if (fault.faults) {
                return findFaultMessage(fault.faults);
            }
        }
    }
    if (errorData?.reason) {
        return errorData?.reason;
    }
    return findFaultMessage(errorData?.faults);
};
