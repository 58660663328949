import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, Spinner } from 'libs.nucleus';
import { NotFoundPage, PageHeader, PageWrapper, useBooleanState } from 'libs.react';
import { WorkspaceSiteDetails, WorkspaceSiteModal } from '../../components';
import { useWorkspaceSite } from '../../hooks';
export const WorkspaceSite = () => {
    const translate = useLocalizeMessage();
    const match = useMatch('/site-management/workspace-site/:workspaceSiteId');
    const [isEditModalOpen, setEditModalOpen, setEditModalClose] = useBooleanState(false);
    const workspaceSiteId = match?.params.workspaceSiteId;
    const { workspaceSite, isLoading } = useWorkspaceSite(workspaceSiteId);
    const breadcrumbsPages = [
        { name: translate('Sites'), path: '/site-management/dashboard' },
        { name: workspaceSite?.name || '', path: '' },
    ];
    const headerOptions = [
        {
            name: translate('Edit'),
            onClick: setEditModalOpen,
            variant: 'primary',
            disabled: !workspaceSite,
            isLoading,
        },
    ];
    if (isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (!workspaceSite) {
        return _jsx(NotFoundPage, {});
    }
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: workspaceSite.name, options: headerOptions, breadcrumbPages: breadcrumbsPages }), _jsx(WorkspaceSiteDetails, { workspaceSite: workspaceSite }, 'workspace_site_details'), isEditModalOpen && _jsx(WorkspaceSiteModal, { onClose: setEditModalClose, workspaceSite: workspaceSite })] }));
};
