import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, Text } from 'libs.nucleus';
import { useDateTime } from 'libs.react';
import { stepResponseTypes } from '../../constants/step_response_type.constants';
export const StepResponseValue = ({ stepResponse }) => {
    const translate = useLocalizeMessage();
    const dateFormatter = useDateTime();
    if (stepResponse?.c_skipped) {
        return _jsx(Text, { children: translate('generic.skipped') });
    }
    //  For image captures where an image is uploaded
    if (stepResponse.type === stepResponseTypes?.cImageCapture.objectType) {
        return (_jsx("img", { alt: stepResponse.c_step.c_name ?? 'Image', "data-testid": `step-response-${stepResponse._id}`, src: stepResponse?.url }));
    }
    //  For Date time formatting
    if (stepResponse.type === stepResponseTypes?.cDatetime.objectType) {
        return _jsx(Text, { children: dateFormatter.parseDateTimeShort(stepResponse.c_value) });
    }
    //  Date formatting
    if (stepResponse.type === stepResponseTypes?.cDate.objectType) {
        return (_jsx(Text, { children: dateFormatter.parseDateShort(stepResponse.c_value, {
                timeZone: 'UTC',
            }) }));
    }
    //  Text choice formatting
    if (stepResponse.type === stepResponseTypes?.cTextChoice.objectType) {
        const textChoices = (stepResponse?.c_step?.c_text_choices || []).filter((textChoice) => {
            if (Array.isArray(stepResponse.c_value)) {
                return stepResponse.c_value.includes(textChoice.c_value);
            }
            return textChoice.c_value?.toString() === stepResponse.c_value?.toString();
        });
        return _jsx(Text, { children: textChoices.map((textChoice) => textChoice.c_text).join(', ') });
    }
    return _jsx(Text, { children: stepResponse.c_value });
};
export default StepResponseValue;
