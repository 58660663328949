import { EnvironmentCluster } from '../../types/environment.types';
const iamServiceEndPoint = process.env.IAM_SERVICE_ENDPOINT;
const getEnvironmentStatus = (environment) => {
    const workflow = getEnvironmentWorkflow(environment);
    if (workflow?.status === 'completed' && hasExpired(environment)) {
        return 'expired';
    }
    if (workflow) {
        return workflow.status || 'active';
    }
    return environment.metadata.packages.length > 0 ? 'completed' : 'failed';
};
const buildEnvironmentUrl = (studyTest, app = 'apps-dashboard') => {
    if (studyTest.deployment.cluster === EnvironmentCluster.EPHEMERAL) {
        const cortexWeb = studyTest.cortexURL.replace('api', app);
        return `${cortexWeb}/?orgCode=${studyTest.code}`;
    }
    // When working with China deployments, we need to redirect to the IAM service so they can figure out the token and do a proper redirection
    if (studyTest.deployment.cluster === EnvironmentCluster.CN_DEV ||
        studyTest.deployment.cluster === EnvironmentCluster.CN_PROD) {
        const redirectUrl = studyTest.deployment.cluster === EnvironmentCluster.CN_DEV
            ? `https://${app}-dev.medable.cn/?orgCode=${studyTest.code}`
            : `https://${app}.medable.cn/?orgCode=${studyTest.code}`;
        return `${iamServiceEndPoint}/v1/sso/token?aud=${studyTest.cortexURL}/${studyTest.code}&redirect_url=${encodeURIComponent(redirectUrl)}`;
    }
    return `https://${app}-${studyTest.deployment.cluster}.medable.com/?orgCode=${studyTest.code}`;
};
const getEnvironmentWorkflow = (environment) => {
    return environment.workflows[0];
};
const isUATEnvironment = (environment) => {
    return isTimeTravelEnvironment(environment) && environment.metadata.backupEnabled;
};
const isTimeTravelEnvironment = (environment) => {
    return environment.cluster === EnvironmentCluster.EPHEMERAL;
};
const hasExpired = (environment) => {
    if (isTimeTravelEnvironment(environment) && environment.metadata.expiresAt) {
        return environment.metadata.expiresAt < new Date().toISOString();
    }
    return false;
};
export const DeploymentUtils = {
    buildEnvironmentUrl,
    getEnvironmentWorkflow,
    getEnvironmentStatus,
    hasExpired,
    isUATEnvironment,
    isTimeTravelEnvironment,
};
