import { ActivityResponse } from './activity_response';
import { Activity } from '../helper_models/activity_model';
export class ActivityManager {
    static key = 'activityManager';
    activityResponse = null;
    constructor() {
        this.activityResponse = null;
    }
    /**
     * Set the activity/task response data
     *
     * @param activityId
     * @param studyId
     * @param publicUserId
     * @param currentGroupId
     * @param visitId
     * @param siteId
     * @param currentLocale
     * @returns
     */
    initActivityResponseData({ activityId, studyId, publicUserId, currentGroupId, visitId, siteId, currentLocale, }) {
        this.activityResponse = new ActivityResponse({
            c_task: activityId,
            c_study: studyId,
            c_start: new Date(),
            c_group: currentGroupId,
            c_locale: currentLocale,
            c_public_user: publicUserId,
            c_site: siteId,
            ...(visitId !== '' ? { c_visit: visitId } : {}),
        });
    }
    /**
     * Clear the activity response data
     */
    clearActivityResponse() {
        this.activityResponse = null;
    }
    loadActivity(activity) {
        return new Activity(activity);
    }
    isCompletionStep(step) {
        return step.c_type === 'completion';
    }
}
