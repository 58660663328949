import { EIframeMessageTypes } from '../../../../constants/iframe_message.enum';
export const ecoa_hooks = `<script type='text/javascript'>

    function post(type, message) {
        window.parent.postMessage({ type, message }, '*');
    }

    function submit({ responses, events }) {
        post(${JSON.stringify(EIframeMessageTypes.SUBMIT)}, { responses, events })
    }

    /**
     * Sends tracking data to the client app.
     *
     * @param { object } event - tracking event as a json object
     */
    function track(event) {
        post(${JSON.stringify(EIframeMessageTypes.TRACK)}, event)
    }

    /**
     * Sends a Cancel request to the client app.
     * The app should close the task.
     */
    function cancel() {
        post(${JSON.stringify(EIframeMessageTypes.CANCEL)}, '')
    }

    /**
     * Makes an HTTP request to the Cortex API based on the provided path and options.
     * 
     * @param {string} path (Mandatory) - The endpoint path for the API request. Should be relative to the base URL, which is dynamically constructed by app base on env.
     *                          Example: 'accounts/me?include=c_public_user'
     * @param {object} options - Configuration for the HTTP request.
         {string} options.method (Mandatory) - The HTTP method to use. Must be one of 'GET', 'POST', 'PUT', 'PATCH', or 'DELETE'.
        {Object} options.body (optional)    - The payload for the request. Required for 'POST', 'PUT', and 'PATCH' methods. Ignored for 'GET' and 'DELETE'.
        {Object} options.headers (optional) - An object containing HTTP headers to be sent with the request.
                                        Example: { "x-token": "value" }
    * 
    * @returns {Promise<object>} - A promise that resolves with the response from the API.
    * 
    * @throws {Error} - Throws an error if the HTTP method is invalid or if required parameters are missing.
    */
    async function callApi(url, { method, body, headers }) {
        if (!url) throw new Error('No {url} provided')
        if (!method || !['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].includes(method))
            throw new Error('Invalid or No {method} provided')
        if (['PATCH', 'POST', 'PUT'].includes(method) && !body)
            throw new Error('No {body} provided')
        const api = window.parent.getApiInstance(window.parent.currentEnvironment)
        
        const response = await api[method.toLowerCase()](url, body, { headers })
        return response.data
    }

    function getMetadata() {
        return { participantId, visitId, taskId }
    }

    window.addEventListener('message', (event) => {
        if (event.data.type === 'LUMOS_ECOA_VARIABLES_INIT') {
            const { ecoa_hook_participant_id, ecoa_hook_visit_id, ecoa_hook_task_id } = event.data.data;
            participantId = ecoa_hook_participant_id;
            visitId = ecoa_hook_visit_id;
            taskId = ecoa_hook_task_id;
        }
    });

</script>`;
