import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { LumosTrainingView } from '../../components/lumos_training/lumos_training_view.component';
export const LumosTraining = () => {
    const loadStorylane = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://js.storylane.io/js/v1/storylane.js';
            script.async = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error('Failed to load storylane.js'));
            document.body.appendChild(script);
        });
    };
    useEffect(() => {
        loadStorylane()
            .then(() => {
            console.log('storylane.js loaded successfully');
            // Initialize or use Storylane here
        })
            .catch((error) => {
            console.error(error);
        });
        // Cleanup function to remove the script when component unmounts
        return () => {
            const script = document.querySelector('script[src="https://js.storylane.io/js/v1/storylane.js"]');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);
    return _jsx(LumosTrainingView, {});
};
