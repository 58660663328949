import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { Link as RouterLink, useMatch } from 'react-router-dom';
import { EM_DASH } from 'libs.constants';
import { Badge, Button, Icon, Link, LocaleContext, useLocalizeMessage } from 'libs.nucleus';
import { getRouteLink } from '../../helpers/links.helpers';
import { formatVisitWindow, isParticipantDeactivated } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { fetchParticipantVisitEvents } from '../activities_list/helpers/fetch_activities.helpers';
export const VisitSchedule = ({ participant, participantVisits, setCurrentVisit, setIsActivityListModalOpen, }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { environment } = useCurrentEnvironment();
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const onVisitScheduleClick = (visit) => {
        setCurrentVisit({ ...visit, customVisitType: 'visit_schedule' });
        setIsActivityListModalOpen();
    };
    const { data: visitEvents } = useQuery({
        queryKey: ['visitEvent', participantId],
        queryFn: () => {
            if (environment && participantId) {
                return fetchParticipantVisitEvents({ environment, participantId });
            }
            return [];
        },
        enabled: !!environment && !!participantId,
    });
    const VisitWindowSection = ({ visit }) => {
        const visitWindow = formatVisitWindow({
            windowStartDate: visit.c_window_start_date,
            windowEndDate: visit.c_window_end_date,
            currentLocale,
        });
        if (visitWindow === EM_DASH) {
            return null;
        }
        return (_jsxs(_Fragment, { children: [_jsx("span", { className: 'text-gray-500', "data-testid": `visit-period-${visit.c_visit}`, children: visitWindow }), _jsx("span", { className: 'px-1.5', children: "\u2022" })] }));
    };
    const RenderVisitStatus = (visit, visitEvent) => {
        if (visitEvent?.c_skipped) {
            return _jsx(Badge, { variant: 'incomplete', label: translate('generic.skipped') });
        }
        const ShowCompletionStatus = () => {
            return (visit.c_completed_assignments === visit.total_activities_count && (_jsx(Badge, { label: translate('generic.completed') })));
        };
        return _jsx(ShowCompletionStatus, {});
    };
    return (_jsx(_Fragment, { children: participantVisits
            .sort((a, b) => a.c_sequence - b.c_sequence)
            .map((visit) => {
            const visitEvent = visitEvents?.find((visitEvent) => visitEvent?.c_schedule_visit?._id === visit.c_visit);
            return (_jsxs("div", { "data-testid": `visit-schedule-${visit.c_visit}`, className: 'grid grid-cols-3 grid-rows-1 gap-4 w-full p-4 bg-white border border-gray-200 rounded-lg shadow-sm', children: [_jsxs("div", { className: 'col-span-2', children: [_jsxs("div", { className: 'flex gap-2', "data-testid": `visit-title-${visit.c_visit}`, children: [_jsx(Link, { LinkComponent: RouterLink, to: getRouteLink(`participants/${participantId}/visit/${visit.c_visit}`, environment), "data-testid": 'visit-name', children: visit.c_name }), visitEvent && RenderVisitStatus(visit, visitEvent)] }), _jsxs("div", { children: [VisitWindowSection({ visit }), _jsx("span", { className: 'text-gray-500', "data-testid": `visit-assessments-${visit.c_visit}`, children: translate('label.visit_schedule.activities', {
                                            assignments: visit.total_activities_count,
                                            count: visit.total_activities_count,
                                        }) })] })] }), _jsx("div", { className: 'ml-auto col-span-1', style: { marginTop: '3px' }, children: !isParticipantDeactivated(participant) && (_jsx(Button, { label: translate('action.visit.view'), "aria-label": 'view-visit-schedule', variant: 'transparent', content: _jsx(Icon, { name: 'chevronRight', size: 24, color: 'primary' }), onClick: () => onVisitScheduleClick(visit) })) })] }, visit.c_visit));
        }) }));
};
