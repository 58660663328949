import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SDKStudy, SpecialProductTemplate, StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { AssetRole, LocalizationStatus, } from 'libs.localization.common';
import { useL10nMapFetch } from '../hooks/use_l10nmap.hook';
import { useL10nMapSync } from '../hooks/use_l10nmap_sync.hook';
import { useLibraryData } from '../hooks/use_library_data.hook';
import { useNotificationsData } from '../hooks/use_notifications_data.hook';
import { useResourcesData } from '../hooks/use_resources_data.hook';
import { useResourcesPdfData } from '../hooks/use_resourcespdf_data.hook';
import { useStudyStringsData } from '../hooks/use_study_strings_data.hook';
export const TranslationWorkbenchContext = createContext({
    locales: [],
    siteLocales: [],
    participantLocales: [],
    isStudyEnglishOnly: false,
    resourcesAssets: {},
    libraryAssets: {},
    studyAssets: {},
    notificationsAssets: {},
    studyId: '',
    studyLocalizationStatus: StudyLocalizationStatus.NOT_STARTED,
    study: new SDKStudy({ name: '', template: SpecialProductTemplate.r400, pinnedProductTemplate: 40000 }),
    isAssetsDataLoading: false,
    isReadyForTranslation: false,
});
export const TranslationWorkbenchProvider = ({ children, study, updateStudy, }) => {
    const { studyId } = useParams();
    const { localizationStatus: studyLocalizationStatus = StudyLocalizationStatus.NOT_STARTED } = study || {};
    const locales = Array.from(new Set([...study.participantLocales, ...study.siteLocales]));
    const isStudyEnglishOnly = study.enforceBaseLocale;
    const [l10nMap, setL10nMap] = useState();
    const { data: l10nMapData } = useL10nMapFetch(study.l10nMapResourceId);
    useEffect(() => {
        if (!study.l10nMapResourceId) {
            setL10nMap([]);
        }
    }, [study.l10nMapResourceId]);
    useEffect(() => {
        if (l10nMapData) {
            setL10nMap(l10nMapData);
        }
    }, [l10nMapData]);
    const { data: libraryAssets, isLoading: isLibraryDataLoading, l10SyncObjects, } = useLibraryData(study.getAllActivities(), study.participantLocales, study.siteLocales, l10nMap);
    const { data: resourcesAssets, isLoading: isResourcesDataLoading } = useResourcesData(study, study.participantLocales, study.siteLocales);
    const { data: resourcesPdfAssets, isLoading: isResourcesPdfDataLoading } = useResourcesPdfData(study, study.participantLocales, study.siteLocales);
    const { data: studyAssets, isLoading: isStudyStringsDataLoading } = useStudyStringsData(study, study.participantLocales, study.siteLocales);
    const { data: notificationsAssets, isLoading: isNotificationsDataLoading } = useNotificationsData(studyId, study.participantLocales, study.siteLocales);
    const { isPending: isL10nMapSyncPending } = useL10nMapSync({
        l10SyncObjects,
        l10nMapResourceId: study.l10nMapResourceId,
        study,
        updateStudy,
    });
    const getAssetByTranslationWorkbenchId = (translationWorkbenchId) => {
        if (translationWorkbenchId) {
            return Object.values({ ...libraryAssets, ...studyAssets, ...resourcesAssets, ...notificationsAssets }).find((asset) => asset.translationWorkbenchId === translationWorkbenchId);
        }
    };
    const isAssetsDataLoading = isL10nMapSyncPending;
    const isReadyForTranslation = !(isResourcesDataLoading ||
        isResourcesPdfDataLoading ||
        isStudyStringsDataLoading ||
        isNotificationsDataLoading ||
        isLibraryDataLoading ||
        isL10nMapSyncPending);
    const allAssets = {
        ...libraryAssets,
        ...resourcesAssets,
        ...resourcesPdfAssets,
        ...studyAssets,
        ...notificationsAssets,
    };
    const isAllLocalesAssetsReadyByRole = (locale, role) => {
        return Object.values(allAssets)
            .filter((asset) => asset.role === role)
            .every((asset) => l10nMap?.some((entry) => entry.translationWorkbenchId === asset.translationWorkbenchId &&
            entry.locale === locale &&
            entry.status === LocalizationStatus.READY));
    };
    const isAssetsReadyByLocale = (locale) => {
        const isLocaleReady = (role, locales) => locales.includes(locale) ? isAllLocalesAssetsReadyByRole(locale, role) : true;
        const isAllSiteAssetsReady = isLocaleReady(AssetRole.SITE, study.siteLocales);
        const isAllParticipantAssetsReady = isLocaleReady(AssetRole.PARTICIPANT, study.participantLocales);
        return isAllSiteAssetsReady && isAllParticipantAssetsReady;
    };
    return (_jsx(TranslationWorkbenchContext.Provider, { value: {
            locales,
            siteLocales: study.siteLocales,
            participantLocales: study.participantLocales,
            isStudyEnglishOnly,
            resourcesAssets: { ...resourcesAssets, ...resourcesPdfAssets },
            libraryAssets,
            studyAssets,
            notificationsAssets,
            studyId: study.id,
            studyLocalizationStatus,
            study,
            updateStudy,
            l10nMap,
            getAssetByTranslationWorkbenchId,
            isAssetsDataLoading,
            setL10nMap,
            isAssetsReadyByLocale,
            isReadyForTranslation,
        }, children: children }));
};
