import { get } from 'lodash';
const matchEnvAndCloudFromHostname = (host) => {
    const hostname = host?.replace('https://', '');
    // Ensure all possible combinations of the environment and cloud domain are matched.
    const regexString = `^cloud([.-]?)(?<environment>[a-zA-Z0-9-.]+)?.medable.(?<cloudDomain>(com|cn|tech))`;
    const regex = new RegExp(regexString);
    return hostname.match(regex);
};
export const getMoaBaseUrl = (hostname) => {
    const matchResult = matchEnvAndCloudFromHostname(hostname);
    const { cloudDomain } = matchResult?.groups || {};
    if (['cloud.medable.com'].includes(hostname)) {
        return 'api.medable.com';
    }
    if (cloudDomain === 'cn') {
        return 'api.medable.cn';
    }
    return 'api-int-dev.medable.com';
};
export const getFinalUrl = (baseURL, encoded) => {
    return `https://${baseURL}/moa/routes/moa/gen/${encoded}`;
};
export const getStudyShortCode = async (hostname, cortexUrl, orgCode) => {
    const moaBaseUrl = getMoaBaseUrl(hostname);
    const cortexHostname = cortexUrl.replace('https://', '');
    const encoded = encodeURIComponent(`${cortexHostname}/${orgCode}`);
    const fetchResponse = await fetch(getFinalUrl(moaBaseUrl, encoded));
    const response = await fetchResponse.json();
    return response?.data;
};
export const getOnboardingConfig = (study) => {
    const studyConfig = get(study, 'c_menu_config', []);
    return studyConfig.find((config) => config.c_menu === 'subject_onboarding');
};
const extractDomainParts = (cortexUrl) => {
    const urlObj = new URL(cortexUrl);
    const hostname = urlObj.host;
    const match = hostname.match(/^(?<api>api)(?<separator>[-.]?)(?<env>(?:[^.]+(?:\.[^.]+)*?)?)\.medable\.(?<topLevelDomain>com|cn|tech)$/);
    const urlExceptionsMap = {
        'api.qa.medable.com': { separator: '-', env: 'qa', topLevelDomain: 'com' },
    };
    if (urlExceptionsMap[hostname]) {
        return urlExceptionsMap[hostname];
    }
    const { separator, env, topLevelDomain } = match?.groups || {};
    return { separator, env, topLevelDomain };
};
export const extractClassicAppsBaseUrl = (cortexUrl, appName) => {
    const { separator, env, topLevelDomain } = extractDomainParts(cortexUrl);
    return `https://${appName}${separator ?? ''}${env ?? ''}.medable.${topLevelDomain}`;
};
