import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, useLocalizeMessage, H3 } from 'libs.nucleus';
export const CompletionStep = ({ currentStep, startDate, handleGoToNextStep, handleGoToPreviousStep, currentActivityResponse, }) => {
    const translate = useLocalizeMessage();
    const onNextStep = () => {
        const response = {
            c_step: currentStep,
            c_value: true,
            type: 'c_completion',
            c_start_date: startDate,
            c_end_date: new Date(),
            c_skipped: false,
        };
        currentActivityResponse.updateStepResponse(response);
        handleGoToNextStep();
    };
    return (_jsxs("div", { className: 'nucleus-px-10', children: [_jsx(H3, { className: 'text-xl text-center py-6', children: currentStep.c_text || currentStep.c_name }), _jsx("p", { className: 'text-center', children: currentStep.c_description || '' }), _jsx("div", { className: 'min-h-80' }), _jsxs("div", { className: 'flex', style: { justifyContent: 'space-evenly' }, children: [_jsx("div", { className: 'nucleus-w-80', children: _jsx(Button, { onClick: handleGoToPreviousStep, label: translate('generic.back'), size: 'full', className: 'h-10' }) }), _jsx("div", { className: 'nucleus-w-80', children: _jsx(Button, { dataTestId: 'completion-step-submit', onClick: onNextStep, label: translate('generic.submit'), size: 'full', className: 'h-10' }) })] })] }));
};
