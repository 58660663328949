import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalizeMessage, Icon, Link, H5, Text } from 'libs.nucleus';
import { IconWrapper } from './icon_wrapper.component';
import { GrayCard } from '../card/card.component';
import { AcademicCapIcon } from '../svgIcons/academic_cap.icon';
// import { FeedbackIcon } from '../svgIcons/feedback.icon';
import { HelpCenterIcon } from '../svgIcons/help_center.icon';
export const HelpSupportLinks = () => {
    const translate = useLocalizeMessage();
    const location = useLocation();
    const navigate = useNavigate();
    const onNavigateToTraining = () => {
        navigate('/site/training', { state: { prevLocation: location.pathname } });
    };
    return (_jsxs("div", { className: 'flex flex-row gap-6 justify-between', children: [_jsx(GrayCard, { children: _jsxs("div", { className: 'flex flex-row gap-4', children: [_jsx(IconWrapper, { children: _jsx(HelpCenterIcon, {}) }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(H5, { children: translate('label.help_center') }), _jsx(Text, { children: translate('info.help.common_questions') }), _jsxs(Link, { target: '_blank', href: 'https://www.medable.com/support', children: [translate('action.help.support'), " ", _jsx(Icon, { size: 'sm', color: 'primary', name: 'externalLink' })] })] })] }) }), _jsx(GrayCard, { children: _jsxs("div", { className: 'flex flex-row gap-4', children: [_jsx(IconWrapper, { children: _jsx(AcademicCapIcon, {}) }), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(H5, { children: translate('generic.training') }), _jsx(Text, { children: translate('info.help.training') }), _jsx(Link, { to: '/site/training', onClick: onNavigateToTraining, children: translate('action.help.training') })] })] }) })] }));
};
