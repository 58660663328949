import { filter, last, orderBy } from 'lodash';
export const getStepStartDate = (stepId, events) => {
    const stepEnterEvents = filter(events, ({ name, stepId: e_stepId }) => name === 'enter' && e_stepId === stepId);
    const event = last(orderBy(stepEnterEvents, ['timestamp'], ['asc']));
    return event?.timestamp ?? new Date();
};
export const getStepEndDate = (stepId, events) => {
    const stepNextEvents = filter(events, ({ name, stepId: e_stepId }) => name === 'next' && e_stepId === stepId);
    const event = last(orderBy(stepNextEvents, ['timestamp'], ['asc']));
    return event?.timestamp ?? new Date();
};
export const STATUS_TO_SKIP = {
    skipped: { c_skipped: true, c_skipped_type: 'user' },
    branched: { c_skipped: true, c_skipped_type: 'branching' },
    unable_to_answer: { c_skipped: true, c_skipped_type: 'unable_to_answer' },
};
export const assignAnswerStatus = (status) => {
    if (status === 'answered') {
        return {};
    }
    return STATUS_TO_SKIP[status];
};
export const assignSkippedReason = (status, reason) => {
    if (status === 'answered') {
        return {};
    }
    return reason && status === 'unable_to_answer' ? { c_skipped_reason: reason } : {};
};
