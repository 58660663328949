import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { useLocalizeMessage, useToastNotification } from 'libs.nucleus';
import { ButtonWithTooltip, useBooleanState } from 'libs.react';
import { IrbContext } from '../../contexts';
import { useCreateIrbPackages, useEnvironmentCortexToken } from '../../hooks';
import { getValidEnvironments } from '../../utils/environment_utils';
import { CriteriaSelectionModal } from '../criteria_selection_modal';
export const CreatePackagetAction = () => {
    const t = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { getEnvironments } = useContext(IrbContext);
    const environments = getEnvironments();
    const validEnvironments = getValidEnvironments(environments);
    const isVersionCreated = validEnvironments.length > 0 || false;
    const { mutate: createIrbPackages } = useCreateIrbPackages();
    const { mutate: getAuthToken } = useEnvironmentCortexToken();
    const [isProcessing, startProcessing, stopProcessing] = useBooleanState(false);
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);
    const handleCreatePackage = useCallback((props) => {
        try {
            const { selectedLocales, selectedEnvironment, selectedDevice, isCoverPageIncluded, selectedViewport } = props;
            if (!selectedEnvironment) {
                throw new Error('Environment not selected');
            }
            const _env = validEnvironments.find((env) => env.id === selectedEnvironment.id);
            const buildVersion = _env?.workflows?.[0].studyConfigVersion;
            if (!buildVersion) {
                throw new Error('Build version not found');
            }
            const studyUuid = selectedEnvironment.metadata?.packages?.find(({ type }) => type === 'studyConfig')?.resourceId;
            if (!studyUuid) {
                throw new Error('Missing studyUuid');
            }
            startProcessing();
            getAuthToken({ envId: selectedEnvironment.id }, {
                onSuccess: (authToken) => {
                    const packages = selectedLocales.map((locale) => ({
                        locale,
                        modality: selectedDevice,
                        buildVersion,
                        isCoverPageIncluded,
                        viewport: selectedViewport,
                    }));
                    createIrbPackages({
                        packages,
                        authToken,
                        studyUuid,
                        envId: selectedEnvironment.id,
                    }, {
                        onSuccess: () => {
                            closeModal();
                        },
                        onSettled: () => {
                            stopProcessing();
                        },
                    });
                },
                onError: () => {
                    stopProcessing();
                },
            });
        }
        catch (error) {
            console.error(error);
            addNotification({
                title: t('Error'),
                subtitle: error.message || t('An unknown error occurred'),
                type: 'error',
            });
        }
    }, [validEnvironments]);
    return (_jsxs(_Fragment, { children: [_jsx(ButtonWithTooltip, { variant: 'primary', onClick: openModal, label: t('Create package'), disabled: !isVersionCreated, tooltip: t('To create a package, you must first create a Version of your study.'), enableTooltip: !isVersionCreated, tooltipPlacement: 'bottom' }), isModalOpen && (_jsx(CriteriaSelectionModal, { onModalClose: closeModal, onHandleCreatePackage: handleCreatePackage, isProcessing: isProcessing }))] }));
};
