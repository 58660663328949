var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header, useLocalizeMessage, Icon, SideNav, Spinner, Text, } from 'libs.nucleus';
import { AuthContext, ApiClientService, useApiClient, AppBanner, AuthRoute, useScopeValidator, AclScope, useProductTemplates, versionNumberToString, useUserRoles, } from 'libs.react';
import { useSelfServiceNavigation } from '../../hooks';
export const PrivateRoute = ({ sectionPath, navItems }) => {
    const iamClient = useApiClient(ApiClientService.IAM);
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const canUpdateWorkspace = useScopeValidator(AclScope.MOM_ENTITY_UPDATE);
    const { latestTemplateConfig } = useProductTemplates();
    const { isSiteUser } = useUserRoles();
    const [isConfigurationLoading, setIsConfigurationLoading] = useState(false);
    const [isDocumentationLoading, setIsDocumentationLoading] = useState(false);
    const { entity, getIDPConfiguration, userEntities, isLoading, isLoggedIn, logout, navigateToLogin, ssoLogin, user } = useContext(AuthContext);
    const { appRoutes } = useSelfServiceNavigation();
    useEffect(() => {
        if (!isLoading && !isLoggedIn) {
            navigateToLogin();
        }
    }, [isLoggedIn, isLoading]);
    const onWorkspaceChange = (userEntity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsConfigurationLoading(true);
        const userEntities = yield getIDPConfiguration(user.email);
        const foundEntity = userEntities.find((entity) => entity.id === userEntity.id);
        if (foundEntity && foundEntity.idps[0]) {
            ssoLogin(foundEntity, user.email);
        }
        else {
            navigate(`${AuthRoute.SWITCH_WORKSPACE}/${userEntity.id}`);
        }
    });
    const workspaceOptions = useMemo(() => {
        const defaultOption = {
            content: (_jsx(Text, { size: 'xs', className: 'font-semibold text-text-disabled', children: translate('YOUR WORKSPACES') })),
            id: 'title',
            readonly: true,
        };
        const options = userEntities.map((userEntity) => (Object.assign({ id: userEntity.id.toString(), content: userEntity.name, onClick: () => onWorkspaceChange(userEntity) }, ((entity === null || entity === void 0 ? void 0 : entity.id) === userEntity.id && { className: 'pointer-events-none' }))));
        if (canUpdateWorkspace) {
            options.push({
                content: translate('Workspace settings'),
                hasTopBorder: true,
                id: 'ws-settings',
                onClick: () => navigate('/workspace/settings'),
            });
        }
        return [defaultOption, ...options];
    }, [userEntities]);
    const headerNavLinks = Object.values(appRoutes)
        .filter(({ hideFromHeader }) => !hideFromHeader)
        .map(({ name, path }) => {
        const onClick = () => navigate(path);
        return { name, path, onClick };
    });
    const openDeveloperFlags = () => {
        navigate('/developer/flags');
    };
    const goToProfilePage = () => {
        navigate('/profile/me');
    };
    const openDocumentation = ({ event }) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        if (!isDocumentationLoading) {
            setIsDocumentationLoading(true);
            const { data: response } = yield iamClient.get('/v1/gitbook/doc-url');
            setIsDocumentationLoading(false);
            if (response.url) {
                window.open(response.url, '_blank');
            }
        }
    });
    const dropdownOptions = useMemo(() => {
        const versionContent = {
            id: 'studio-version',
            content: (_jsx(Text, { className: 'text-text-disabled', size: 'sm', children: translate('R{version}', { version: versionNumberToString(latestTemplateConfig === null || latestTemplateConfig === void 0 ? void 0 : latestTemplateConfig.altName) }) })),
            readonly: true,
            hasBottomBorder: true,
        };
        const options = [
            versionContent,
            { id: 'profile', content: translate('Your profile'), onClick: goToProfilePage },
        ];
        const externalLinks = isSiteUser
            ? []
            : [{ id: 'documentation', content: translate('Documentation'), onClick: openDocumentation }];
        if (process.env.ENABLE_DEVELOPER_FLAGS === 'true') {
            options.push({ id: 'developer-flags', content: translate('Developer flags'), onClick: openDeveloperFlags });
        }
        options.push(...externalLinks.map((link) => ({
            id: link.id,
            content: (_jsxs("div", { className: 'flex items-center gap-2', children: [_jsx("span", { children: link.content }), isDocumentationLoading && _jsx(Spinner, { size: 'sm' }), !isDocumentationLoading && _jsx(Icon, { name: 'externalLink', size: 'sm' })] })),
            onClick: link.onClick,
        })));
        return options;
    }, [isDocumentationLoading, latestTemplateConfig, isSiteUser]);
    const renderHeaderLink = useCallback(({ name, path, isActive }) => {
        return (_jsx(Link, { to: path, className: `inline-block border-b-2 py-[0.9375rem] text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-1 ${isActive
                ? 'border-primary text-text-primary'
                : 'border-transparent text-neutral-500 hover:border-neutral-300 hover:text-neutral-700'}`, children: name }, path));
    }, [pathname]);
    return (_jsxs("section", { className: 'flex h-screen flex-col overflow-hidden', children: [_jsx("div", { "data-testid": 'app-header', children: _jsx(Header, { activeLinkPath: sectionPath, dropdownOptions: dropdownOptions, navLinks: headerNavLinks, onSignOut: logout, renderLink: renderHeaderLink, selectedWorkspace: entity === null || entity === void 0 ? void 0 : entity.name, switcherEnabled: workspaceOptions.length > 2 || isSiteUser, workspaceOptions: workspaceOptions }) }), _jsxs("div", { className: 'flex flex-col grow overflow-hidden', children: [isConfigurationLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx(AppBanner, {}), navItems && (_jsx("div", { className: 'flex flex-col bg-neutral-75', "data-testid": 'side-nav-menu', children: _jsx(SideNav, { activeLinkPath: pathname.replace(`${sectionPath}/`, ''), items: navItems, LinkComponent: Link }) })), _jsx("div", { className: 'flex h-full w-full grow flex-col overflow-hidden bg-white', children: _jsx(Suspense, { fallback: _jsx("section", { className: 'flex items-center justify-center h-full', children: _jsx(Spinner, {}) }), children: _jsx(Outlet, {}) }) })] })] }));
};
