import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useLocalizeMessage, H5, Text, Button } from 'libs.nucleus';
import { SomethingWentWrongGraphic } from './something_went_wrong.graphic';
export const NotFoundPage = () => {
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (_jsxs("section", { className: 'flex flex-col items-center justify-center flex-1 p-6', children: [_jsx(SomethingWentWrongGraphic, {}), _jsx(H5, { children: translate('404 - Page not found') }), _jsx(Text, { as: 'span', className: 'text-secondary max-w-lg text-center', size: 'sm', children: translate('The page you are looking for may have been moved, had its name changed, or is not available from the current selected workspace.') }), _jsx(Button, { className: 'mt-8', variant: 'transparent', label: translate('Go back'), onClick: goBack })] }));
};
