import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { I18nUtils } from '@medable/i18n-core';
import { LocaleContext, useLocalizeMessage, Link, Spinner, Text } from 'libs.nucleus';
import { ListInformation, useBooleanState, useDateTime } from 'libs.react';
import { getParticipantNextVisitWindow, isParticipantAuthenticated, isParticipantDeactivated, } from '../../helpers/participants.helpers';
import { getStudyShortCode } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useLumosAuth } from '../../hooks/use_lumos_auth';
import { useOrgContext } from '../../hooks/use_org_context';
import { EParticipantInviteStatus } from '../../types/participants.type';
import { LUMOS_PERMISSIONS } from '../../types/permissions.types';
import { ParticipantDeactivationModal } from '../participant_deactivation/participant_deactivation_modal.component';
import { ParticipantEditFormModal } from '../participant_form/participant_edit_form_modal.component';
import { ParticipantInviteModal } from '../participant_form/participant_invite_modal.component';
import { ResetPassword } from '../reset_password';
export const ParticipantDetails = ({ participant, participantDetailsLoading }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { parseDateTimeShort } = useDateTime();
    const [isInviteModalOpen, setIsInviteModalOpen, setIsInviteModalClose] = useBooleanState(false);
    const [isDeactivationModalOpen, setIsDeactivationModalOpen, setIsDeactivationModalClosed] = useBooleanState(false);
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen, setIsResetPasswordModalClose] = useBooleanState(false);
    const [isParticipantModalOpen, setIsparticipantModalOpen, setIsparticipantModalClose] = useBooleanState(false);
    const { environment } = useCurrentEnvironment();
    const org = useOrgContext();
    const queryClient = useQueryClient();
    const { isUserPermitted } = useLumosAuth();
    const getStudyCode = async () => {
        return await getStudyShortCode(window.location.hostname, environment?.cortexURL ?? '', environment?.code ?? '');
    };
    const { data: studyCode } = useQuery({
        queryKey: ['getStudyCode', environment?.code],
        queryFn: getStudyCode,
        enabled: !queryClient.getQueryData(['getStudyCode', environment?.code]),
    });
    if (!participant || participantDetailsLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const refreshDetailsData = () => {
        queryClient.invalidateQueries({
            queryKey: ['participantDetails', participant._id],
        });
    };
    const onDeactivationModalClose = () => {
        setIsDeactivationModalClosed();
        refreshDetailsData();
    };
    const onInviteModalClose = () => {
        setIsInviteModalClose();
        refreshDetailsData();
    };
    //  If participant doesn't have an account or is deactivated or simply doest have the permissions, don't show the deactivate button
    const canDeactivateParticipant = isParticipantAuthenticated(participant) &&
        !isParticipantDeactivated(participant) &&
        isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_DEACTIVATE);
    const canResetPassword = isParticipantAuthenticated(participant) && isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_RESET_PASSWORD);
    const canUpdateParticipant = !isParticipantAuthenticated(participant) && isUserPermitted(LUMOS_PERMISSIONS.PARTICIPANTS_UPDATE);
    const detailItems = [
        {
            title: translate('generic.language'),
            description: participant.c_locale ? I18nUtils.getLanguageLabel(participant.c_locale, currentLocale, false) : null,
            dataTestId: 'participant-details-language',
        },
        ...(org.c_no_pii
            ? [
                {
                    title: translate('generic.username'),
                    description: participant.c_username,
                    ...(canResetPassword && {
                        actionContent: (_jsx(Link, { "data-testid": 'reset-password', href: '#', onClick: setIsResetPasswordModalOpen, children: translate('action.password_reset') })),
                    }),
                    dataTestId: 'participant-details-username',
                },
            ]
            : [
                {
                    title: translate('generic.email'),
                    description: participant.c_email,
                    dataTestId: 'participant-details-email',
                    //  TODO - ADD PERMISSION HERE
                    ...(canUpdateParticipant && {
                        actionContent: (_jsx(Link, { "data-testid": 'edit-participant', href: '#', onClick: setIsparticipantModalOpen, children: translate('generic.edit') })),
                    }),
                },
            ]),
        ...(!isParticipantDeactivated(participant)
            ? [
                {
                    title: translate('label.invite_code'),
                    description: participant.c_invite === EParticipantInviteStatus.INVITED ? (_jsxs("div", { children: [_jsxs(Text, { size: 'sm', className: 'text-text-primary', children: [translate('label.study_code'), ": ", studyCode] }), _jsxs(Text, { size: 'sm', className: 'text-text-primary', children: [translate('label.invite_code'), ": ", participant.c_access_code] })] })) : (_jsx(Text, { size: 'sm', className: 'capitalize', children: participant.c_invite })),
                    actionContent: (_jsx(Link, { "data-testid": 'add-view-invite', href: '#', onClick: setIsInviteModalOpen, children: participant.c_invite === EParticipantInviteStatus.INVITED
                            ? translate('generic.view')
                            : translate('label.participant_details.new_invite') })),
                    dataTestId: 'participant-details-invite-code',
                },
            ]
            : []),
        {
            title: translate('generic.cohort'),
            description: participant.c_visit_schedule?.c_name,
            dataTestId: 'participant-details-cohort',
        },
        {
            title: translate('generic.status'),
            description: participant.c_status,
            dataTestId: 'participant-details-status',
        },
        {
            title: translate('label.participant_details.next_visit'),
            description: getParticipantNextVisitWindow(participant),
            dataTestId: 'participant-details-next-visit',
        },
        {
            title: translate('generic.create', { tense: 'past' }),
            description: parseDateTimeShort(participant.created),
            ...(canDeactivateParticipant && {
                actionContent: (_jsx(Link, { "data-testid": 'deactivate-action', href: '#', onClick: setIsDeactivationModalOpen, children: translate('generic.deactivate') })),
            }),
            dataTestId: 'participant-details-created',
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ParticipantInviteModal, { isModalOpen: isInviteModalOpen, participant: participant, onClose: onInviteModalClose, onDone: onInviteModalClose }), _jsx(ParticipantDeactivationModal, { isModalOpen: isDeactivationModalOpen, participant: participant, onClose: onDeactivationModalClose, onDone: onDeactivationModalClose }), _jsx(ResetPassword, { isModalOpen: isResetPasswordModalOpen, onClose: setIsResetPasswordModalClose, participant: participant }), _jsx(ParticipantEditFormModal, { isModalOpen: isParticipantModalOpen, participant: participant, onClose: setIsparticipantModalClose }), _jsx("div", { className: 'flex flex-col gap-8', children: _jsx(ListInformation, { dataTestId: 'participant-details-list-information', items: detailItems, useDefaultEmptyDescription: true }) })] }));
};
