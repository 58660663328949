import { jsx as _jsx } from "react/jsx-runtime";
// To be exposed to the API if needed
const OPTIONS = {
    cols: 'grid-cols-12',
    gap: 'gap-4',
    bgColor: 'bg-neutral-100',
};
const GRID_ROW_HEIGHT_MAP = {
    xs: 'min-h-28',
    sm: 'min-h-48',
    base: 'min-h-80',
    lg: 'min-h-[32rem]',
    full: '',
};
const COL_SPAN_MAP = {
    1: 'col-span-1',
    2: 'col-span-2',
    3: 'col-span-3',
    4: 'col-span-4',
    5: 'col-span-5',
    6: 'col-span-6',
    7: 'col-span-7',
    8: 'col-span-8',
    9: 'col-span-9',
    10: 'col-span-10',
    11: 'col-span-11',
    12: 'col-span-12',
};
const ROW_SPAN_MAP = {
    1: 'row-span-1',
    2: 'row-span-2',
    3: 'row-span-3',
    4: 'row-span-4',
    5: 'row-span-5',
    6: 'row-span-6',
    7: 'row-span-7',
    8: 'row-span-8',
    9: 'row-span-9',
    10: 'row-span-10',
    11: 'row-span-11',
    12: 'row-span-12',
};
const PADDING_MAP = {
    0: 'p-0',
    4: 'p-4',
    8: 'p-8',
    16: 'p-16',
};
const BG_COLOR_MAP = {
    'white': 'bg-white',
    'x-light': 'bg-neutral-50',
    'light': 'bg-neutral-100',
    'medium': 'bg-neutral-200',
};
export const ReportingGrid = ({ cards, padding = 4, bgColor = 'light', rowHeight = 'base' }) => (_jsx("div", { className: `grid ${OPTIONS.gap} ${OPTIONS.cols} ${BG_COLOR_MAP[bgColor]} ${PADDING_MAP[padding]}`, children: cards &&
        cards.map(({ card, xSpan = 12, ySpan = 1 }, index) => (_jsx("div", { className: `flex ${ROW_SPAN_MAP[ySpan]} ${COL_SPAN_MAP[xSpan]} ${GRID_ROW_HEIGHT_MAP[rowHeight]}`, children: card }, `cell-${index}`))) }));
