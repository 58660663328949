export const getDeactivationInitialValues = () => {
    return {
        participantNumber: '',
        reason: { label: '', value: '' },
    };
};
export const getDeactivationReasons = (translate) => [
    {
        label: translate('generic.completed'),
        value: 'CMP',
    },
    {
        label: translate('label.participant_deactivation.reason_screen_failure'),
        value: 'SF',
    },
    {
        label: translate('label.participant_deactivation.reason_adverse_event'),
        value: 'AE',
    },
    {
        label: translate('label.participant_deactivation.reason_death'),
        value: 'DTH',
    },
    {
        label: translate('label.participant_deactivation.reason_disease_relapse'),
        value: 'DR',
    },
    {
        label: translate('label.participant_deactivation.reason_failure_meet_criteria'),
        value: 'FTMRC',
    },
    {
        label: translate('label.participant_deactivation.reason_lack_efficacy'),
        value: 'LOE',
    },
    {
        label: translate('label.participant_deactivation.reason_lost_follow_up'),
        value: 'LTFU',
    },
    {
        label: translate('label.participant_deactivation.reason_non_compliance_drug'),
        value: 'NCWSD',
    },
    {
        label: translate('label.participant_deactivation.reason_physician_decision'),
        value: 'PD',
    },
    {
        label: translate('label.participant_deactivation.reason_pregnancy'),
        value: 'PREG',
    },
    {
        label: translate('label.participant_deactivation.reason_progressive_disease'),
        value: 'PRD',
    },
    {
        label: translate('label.participant_deactivation.reason_protocol_deviation'),
        value: 'PDV',
    },
    {
        label: translate('label.participant_deactivation.reason_recovery'),
        value: 'RCV',
    },
    {
        label: translate('label.participant_deactivation.reason_site_terminated'),
        value: 'STBS',
    },
    {
        label: translate('label.participant_deactivation.reason_study_terminated'),
        value: 'STDTBS',
    },
    {
        label: translate('label.participant_deactivation.reason_technical_problems'),
        value: 'TP',
    },
    {
        label: translate('label.participant_deactivation.reason_withdrawal_guardian'),
        value: 'WBPG',
    },
    {
        label: translate('label.participant_deactivation.reason_withdrawal_subject'),
        value: 'WBS',
    },
];
