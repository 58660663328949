import { PlainDateTime } from 'temporal-luxon';
import { RosettaSDKUtils, SpecialProductTemplate } from '@axon/rosetta-sdk';
export const versionNumberToString = (version) => {
    if (!version) {
        return '';
    }
    if (version === SpecialProductTemplate.DEV_LATEST) {
        return '-latest';
    }
    const semver = RosettaSDKUtils.convertTemplateToSemver(version);
    return semver.fullVersion;
};
export const getDatesDiff = (firstDate, secondDate) => {
    const milliseconds = firstDate.getTime() - secondDate.getTime();
    return new PlainDateTime(1, 1, 1).add({ milliseconds: Math.abs(milliseconds) });
};
/**
 * Check if two strings match
 * @param str1 The first string
 * @param str2 The second string
 * @param sanitize Whether to sanitize the strings before comparing
 * @returns Whether the strings match or not
 */
export const isSameString = (str1, str2, sanitize = true) => {
    if (!str1 || !str2) {
        return false;
    }
    return sanitize ? str1.toLowerCase().trim() === str2.toLowerCase().trim() : str1 === str2;
};
/**
 * Method to sort an array after running a map function (or similar) that returns an array of objects
 * ie: sortBy(items, 'key')
 *
 * Currently, this function only has support for string and number values
 */
export const sortBy = (items, key) => {
    return items.sort(inlineSortBy(key));
};
/**
 * Returns a function that can be used to sort an array of objects by a specific key
 * This is useful to sort an array after running a map function (or similar) that returns an array of objects
 * ie: items.map(...).sort(inlineSortBy('key'))
 *
 * Currently, this function only has support for string and number values
 */
export const inlineSortBy = (key) => {
    return (itemA, itemB) => {
        const valueA = itemA[key];
        const valueB = itemB[key];
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB);
        }
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    };
};
/**
 * CustomError class extends the built-in Error to include additional properties.
 */
class CustomError extends Error {
    source;
    originalError;
    /**
     * Creates a new CustomError instance.
     *
     * @param {string} source - The source or context where the error occurred.
     * @param {any} originalError - The original error object or information.
     */
    constructor(source, originalError) {
        super(`Error in ${source}`);
        this.source = source;
        this.originalError = originalError;
        Object.setPrototypeOf(this, CustomError.prototype);
    }
}
/**
 * Creates a custom error object.
 *
 * @param {string} source - The source or context where the error occurred.
 * @param {any} originalError - The original error object or information.
 * @returns {CustomError} A new CustomError instance enriched with custom properties.
 */
export const createCustomError = (source, originalError) => {
    return new CustomError(source, originalError);
};
