import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from 'react';
import { Select, useLocalizeMessage, Spinner } from 'libs.nucleus';
import { useStudyVersion } from '../../hooks';
export const StudyVersionsDropdown = ({ setSelectedStudyVersion, selectedStudyVersion, }) => {
    const translate = useLocalizeMessage();
    const { versions, isLoading } = useStudyVersion();
    useEffect(() => {
        if (versions.size) {
            setSelectedStudyVersion(versions.values().next().value);
        }
    }, [versions]);
    const handleChange = useCallback((selectedOption) => {
        const currentVersion = versions.get(selectedOption.value);
        setSelectedStudyVersion(currentVersion);
    }, [setSelectedStudyVersion, versions]);
    const versionsOptions = useMemo(() => {
        return Array.from(versions.values()).map((version) => ({
            value: version.id,
            label: `${version.metadata.name} (${version.externalVersion})`,
        }));
    }, [versions]);
    const currentVersion = useMemo(() => {
        if (!selectedStudyVersion) {
            return { label: '', value: '' };
        }
        return {
            value: selectedStudyVersion.id,
            label: `${selectedStudyVersion.metadata.name} (${selectedStudyVersion.externalVersion})`,
        };
    }, [selectedStudyVersion]);
    return (_jsxs("div", { className: 'relative', children: [isLoading && (_jsx("div", { className: 'absolute z-[5] inset-0 flex items-end size-full pb-1 pl-1', children: _jsx(Spinner, {}) })), _jsx(Select, { options: versionsOptions, value: currentVersion, onChange: handleChange, width: 'lg', required: true, label: translate('Study versions') })] }));
};
