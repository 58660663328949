export const IRB_FLAGS = {
    irb: {
        id: 'irb',
        value: false,
        name: 'Enable submission package',
        description: 'Enables the submission package sidebar tab',
        owner: 'Translation Workbench',
        extra: { section: 'IRB', isFirst: true, dataTestId: 'irb-flag' },
    },
    irbEnableRealAppScreens: {
        id: 'irbEnableRealAppScreens',
        value: false,
        name: 'Include real app screens',
        description: 'Include real app screens in the submission package report',
        owner: 'Translation Workbench',
        extra: { section: 'IRB', dataTestId: 'irb-real-app-screens-flag' },
    },
    irbEnableRealAppScreensEvenWhenSomeFails: {
        id: 'irbEnableRealAppScreensEvenWhenSomeFails',
        value: false,
        name: 'Include real app screens even when some fails',
        description: 'Include real app screens in the submission package report even when some fails',
        owner: 'Translation Workbench',
        extra: { section: 'IRB', dataTestId: 'irb-real-app-screens-failure-flag' },
    },
};
