import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage, Table } from 'libs.nucleus';
import { useParticipantsTableColumns } from './participants_table_columns.hook';
import { useFetchParticipants } from '../../hooks/use_fetch_participants';
export const ParticipantsTable = () => {
    const translate = useLocalizeMessage();
    const { participantsColumns } = useParticipantsTableColumns();
    const { isLoading, participants, totalPages, pagination, setPagination } = useFetchParticipants({
        initialPage: 0,
        initialPageSize: 10,
    });
    return (_jsx(Table, { columns: participantsColumns, data: participants || [], isLoading: isLoading, labelNoResults: translate('info.participants_table.empty'), paginationNavigationScreenReaderLabel: translate('info.participants_table.navigation'), useInternalSearch: true, labelSearchField: translate('generic.search'), hasPagination: true, manualPagination: true, initialPageSize: 10, pageCount: totalPages, manualPaginationState: pagination, onPaginationChange: setPagination }));
};
