import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useRef, useState } from 'react';
import { Button, H3, useLocalizeMessage, ModalWindow, Logo, LocaleContext } from 'libs.nucleus';
import { AuthContext } from 'libs.react';
import { CertificateOfCompletion } from './components/certifiicate_of_completion.component';
import { generatePDF } from '../../helpers/generate_pdf.helpers';
import { useLumosTrainingProgress } from '../../hooks/use_lumos_training';
import { TrophyIcon } from '../svgIcons/trophy.icon';
export const LumosTrainingComplete = ({ siteUserModuleProgress }) => {
    const currentLocale = useContext(LocaleContext);
    const translate = useLocalizeMessage();
    const { trainingVersion } = useLumosTrainingProgress();
    const [isCompletionCertificateModalOpen, setIsCompletionCertificateModalOpen] = useState(false);
    const { user } = useContext(AuthContext);
    const certificateRef = useRef(null);
    const completionDate = new Date(siteUserModuleProgress?.completedOn ?? new Date()).toLocaleDateString(currentLocale, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalWindow, { title: '', isOpen: isCompletionCertificateModalOpen, hideCloseIcon: true, closeWindow: () => { }, footerPrimaryActionButton: {
                    label: translate('generic.done'),
                    onClick: () => setIsCompletionCertificateModalOpen(false),
                }, footerSecondaryActionButtons: [
                    {
                        label: translate('generic.download'),
                        onClick: () => generatePDF(certificateRef, 'medable_site_training_certificate_of_completion'),
                    },
                ], width: 'full', removeBodyPadding: true, children: _jsx(CertificateOfCompletion, { user: user, completionDate: completionDate, isStudySpecificTraining: false, version: trainingVersion }) }), _jsxs("div", { className: 'h-full flex justify-center items-center flex-col', children: [_jsx("div", { children: _jsx(TrophyIcon, {}) }), _jsx(H3, { children: translate('info.lumos_training.complete_title') }), _jsx("div", { children: _jsx("p", { children: translate('info.study_training.complete_description') }) }), _jsx("div", { className: 'py-7', children: _jsx(Button, { onClick: () => setIsCompletionCertificateModalOpen(true), label: translate('action.study_training.complete_download'), variant: 'secondary' }) }), _jsx("div", { ref: certificateRef, className: `w-full bg-white my-16 p-8 border border-gray-300 rounded-lg shadow-md`, style: {
                            height: '793.7007874px',
                            width: '950px',
                            display: isCompletionCertificateModalOpen ? 'block' : 'none',
                        }, children: _jsxs("div", { className: 'bg-indigo-50 p-12 rounded-lg flex flex-col items-center justify-center text-center min-h-96', children: [_jsx("div", { className: 'mb-6', style: { width: '120px', height: '45px' }, children: _jsx(Logo, {}) }), _jsx("h1", { className: 'text-5xl font-bold mb-10 bg-gradient-to-r bg-clip-text text-indigo-900', children: translate('info.lumos_training.certificate.title') }), _jsxs("h2", { className: 'text-3xl text-gray-600 font-medium mb-8', children: [user?.first, " ", user?.last] }), _jsx("div", { className: 'w-2/3 border-b border-gray-300 mb-8', children: "\u00A0" }), _jsx("h3", { className: 'text-4xl font-bold text-gray-800 tracking-wider mb-8', children: translate('info.lumos_training.certificate.site_user_training') }), _jsx("div", { className: 'w-2/3 border-b border-gray-300 mb-8', children: "\u00A0" }), _jsxs("div", { className: 'flex items-center justify-center mb-8', children: [_jsx("div", { className: 'h-px w-6 bg-indigo-600 mt-8', children: "\u00A0" }), _jsx("span", { className: 'text-3xl font-medium text-indigo-600 mx-2', children: `v${trainingVersion}` }), _jsx("div", { className: 'h-px w-6 bg-indigo-600 mt-8', children: "\u00A0" })] }), _jsx("p", { className: 'text-xl text-gray-500', children: completionDate })] }) })] })] }));
};
