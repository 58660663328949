import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
export const useSiteSelectorTableColumns = ({ setSelectedSiteId, selectedSiteId }) => {
    const translate = useLocalizeMessage();
    const siteSelectorColumns = useMemo(() => {
        return [
            {
                accessorKey: 'id',
                header: '',
                cell: ({ row }) => {
                    return (_jsx("input", { type: 'radio', checked: row.original._id === selectedSiteId, value: row.original._id, onChange: () => setSelectedSiteId(row.original._id), "data-testid": `site-selector-radio-${row.original.c_number}` }));
                },
                size: 20,
            },
            {
                header: translate('generic.name'),
                accessorKey: 'c_name',
            },
            //   {
            //     header: translate('label.site_selector.study_site_id'),
            //     accessorKey: 'created',
            //     cell: (context) => parseDateTimeShort(context.getValue<string>()),
            //   },
            {
                header: translate('generic.location'),
                accessorKey: 'c_site_address',
                cell: (context) => {
                    const row = context.row.original;
                    return `${row.c_site_address?.c_city}, ${row.c_site_address?.c_state}, ${row.c_site_address?.c_country}`;
                },
            },
        ];
    }, [selectedSiteId]);
    return {
        siteSelectorColumns,
    };
};
