import { lazy } from 'react';
import { DataManagementApp } from 'apps.data_management';
import { ECOABuilderApp } from 'apps.ecoa_builder';
import { SiteApp } from 'apps.site_app';
import { SiteManagementApp } from 'apps.site_management';
import { StudyManagementApp } from 'apps.study_management';
import { UserManagementApp } from 'apps.user_management';
import { AclScope, useScopeValidator, useUserRoles } from 'libs.react';
import DeveloperFlagsPage from '../../pages/developer_flags/developer_flags.component';
const WorkspaceSettingsPage = lazy(() => import('../../pages/workspace/workspace_settings.page'));
const ProfilePage = lazy(() => import('../../pages/profile/profile.page'));
export const useSelfServiceNavigation = () => {
    const { isAdmin, isBuilder, isLibrarian, isUserAndSiteManager, isSiteUser } = useUserRoles();
    const canUpdateWorkspace = useScopeValidator(AclScope.MOM_ENTITY_UPDATE);
    const canViewDataApp = useScopeValidator([
        AclScope.CONTENT_REPORT_LIST,
        AclScope.TRANSFER_EXECUTION_LIST,
        AclScope.CORTEX_ROLE_AXON_SITE_AND_USER_MANAGER,
    ]);
    const appRoutes = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, ((isAdmin || isBuilder || isUserAndSiteManager) && {
        studies: {
            name: 'Studies',
            path: '/studies',
            redirectTo: 'dashboard',
            hideSideNav: true,
            appRoutes: [{ title: 'Studies', path: '/studies', component: StudyManagementApp }],
        },
    })), ((isAdmin || isLibrarian || isBuilder) && {
        ecoa: {
            name: 'Library',
            path: '/library',
            redirectTo: 'activities',
            hideSideNav: true,
            appRoutes: [{ title: 'Activities', path: 'activities', component: ECOABuilderApp, icon: 'navProtocol' }],
        },
    })), (isAdmin && {
        users: {
            name: 'Users',
            path: '/users',
            redirectTo: 'dashboard',
            hideSideNav: true,
            appRoutes: [{ title: 'Users', path: '/users', component: UserManagementApp }],
        },
    })), (isSiteUser && {
        site: {
            name: 'Studies',
            path: '/site',
            redirectTo: 'dashboard',
            hideSideNav: true,
            appRoutes: [{ title: 'Studies', path: '/site', component: SiteApp }],
        },
    })), ((isAdmin || isUserAndSiteManager) && {
        siteManagement: {
            name: 'Sites',
            path: '/site-management',
            redirectTo: 'dashboard',
            hideSideNav: true,
            appRoutes: [{ title: 'Sites', path: '/site-management', component: SiteManagementApp }],
        },
    })), ((isAdmin || canViewDataApp) && {
        dataManagement: {
            name: 'Data',
            path: '/data',
            redirectTo: 'dashboard',
            hideSideNav: true,
            appRoutes: [{ title: 'Data', path: '/data', component: DataManagementApp }],
        },
    })), (canUpdateWorkspace && {
        workspace: {
            name: 'Workspace settings',
            path: '/workspace',
            redirectTo: 'settings',
            hideSideNav: true,
            hideFromHeader: true,
            appRoutes: [{ title: 'Workspace settings', path: 'settings', component: WorkspaceSettingsPage }],
        },
    })), { profile: {
            name: 'Your profile',
            path: '/profile',
            redirectTo: 'me',
            hideSideNav: true,
            hideFromHeader: true,
            appRoutes: [{ title: 'Your profile', path: 'me', component: ProfilePage }],
        } }), ((process.env.ENABLE_DEVELOPER_FLAGS === 'true' || process.env.ENABLE_DEVELOPER_FLAGS === 'route-only') && {
        flags: {
            name: 'Developer',
            path: '/developer',
            hideSideNav: true,
            hideFromHeader: true,
            appRoutes: [{ title: 'Developer flags', path: 'flags', component: DeveloperFlagsPage }],
        },
    }));
    return {
        appRoutes,
        defaultAppSection: Object.values(appRoutes)[0],
    };
};
