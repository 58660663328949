import { I18nUtils } from '@medable/i18n-core';
/**
 * Formats a locale option for the locales dropdown
 * @param locale - The locale to format
 * @param currentLocale - The current locale
 * @param forceI18nFormat - Whether to force the locale to be in i18n format from any potential cortex formats
 * @returns SelectOption
 */
export const formatLocaleOption = (locale, currentLocale) => {
    const showOwnLocale = true;
    return {
        value: I18nUtils.cortexToI18nFormat(locale),
        label: I18nUtils.getLanguageLabel(locale, currentLocale, showOwnLocale),
    };
};
export const formatLocaleList = (locales, currentLocale) => {
    return locales.length ? locales.map((locale) => formatLocaleOption(locale, currentLocale)) : [];
};
