import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ModalDialog, useLocalizeMessage } from 'libs.nucleus';
export const ContinueToStudyTrainingPrompt = ({ isPromptOpen, onHandleContinue, onCancel, onClosePrompt, }) => {
    const translate = useLocalizeMessage();
    return (_jsx(_Fragment, { children: isPromptOpen && (_jsx(ModalDialog, { title: translate('info.study_training.access'), body: _jsx("div", { className: 'font-sans m-0 text-sm text-text-secondary', children: _jsx("p", { children: translate('info.study_training.instructions') }) }), width: 'lg', actionButtons: [
                {
                    label: translate('generic.continue'),
                    onClick: onHandleContinue,
                },
                {
                    label: translate('generic.cancel'),
                    onClick: onCancel,
                },
            ], isOpen: true, closeDialog: onClosePrompt, willCloseOnOutsideClickAndEscapeKeyPress: false })) }));
};
