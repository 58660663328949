import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import { ApiClientService, useApiClient } from '../../api_client';
import { AuthContext } from '../../auth';
export const ProductTemplatesContext = createContext({
    productTemplateConfigurations: [],
    isLoading: true,
    latestTemplateConfig: undefined,
    validatedTemplate: '',
    validatedPinnedVersion: 0,
});
export const ProductTemplatesProvider = ({ children }) => {
    const momClient = useApiClient(ApiClientService.MOM);
    const { entityId } = useContext(AuthContext);
    const retrieveStudies = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/templates`);
        return response.data;
    };
    const { data: productTemplateConfigurations = [], isLoading } = useQuery({
        queryKey: ['entities', entityId, 'templates'],
        queryFn: retrieveStudies,
        enabled: !!entityId,
    });
    const latestValidatedConfig = useMemo(() => {
        return productTemplateConfigurations.find((template) => template.validated);
    }, [productTemplateConfigurations]);
    return (_jsx(ProductTemplatesContext.Provider, { value: {
            productTemplateConfigurations,
            isLoading,
            latestTemplateConfig: productTemplateConfigurations[0],
            validatedTemplate: latestValidatedConfig?.altName || '',
            validatedPinnedVersion: latestValidatedConfig?.pinnedVersion || 0,
        }, children: children }));
};
