import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FlagContext } from 'libs.flags.react';
import { AclScope, NotFoundPage, useScopeValidator } from 'libs.react';
import { DATA_MANAGEMENT_APP_FLAGS } from './constants/developer_flags.constants';
import { DataReportsProvider } from './contexts/data_reports/data_reports.context';
import { StudiesProvider } from './contexts/studies/studies.context';
import UserTrainingReportPage from './pages/user_training/user_training.page';
const LayoutWrapper = lazy(() => import('./components/layout_wrapper/layout_wrapper.component'));
const StudiesDashboardPage = lazy(() => import('./pages/studies/studies_dashboard.page'));
const DataTransferPage = lazy(() => import('./pages/data_transfer/data_transfer.page'));
const DataReportsViewPage = lazy(() => import('./pages/data_reports/data_reports_view.page'));
const DataReportsBuildPage = lazy(() => import('./pages/data_reports/data_reports_build.page'));
const ReportsList = lazy(() => import('./pages/data_reports/reports_list.page'));
const ReportDashboard = lazy(() => import('./pages/data_reports/report_dashboard.page'));
const StudyManagerAccessPage = lazy(() => import('./pages/study_manager_access/study_manager_access.page'));
const ProvidersWrapper = ({ children }) => {
    return (_jsx(StudiesProvider, { children: _jsx(DataReportsProvider, { children: children }) }));
};
export const DataManagementApp = () => {
    const flagManager = useContext(FlagContext);
    const isViewReportsEnabled = flagManager.getValue(DATA_MANAGEMENT_APP_FLAGS.view_reports);
    const isBuildReportsEnabled = flagManager.getValue(DATA_MANAGEMENT_APP_FLAGS.build_reports);
    const canViewDataReports = useScopeValidator([AclScope.CONTENT_REPORT_GET]);
    const canViewDataTransfers = useScopeValidator([AclScope.TRANSFER_EXECUTION_LIST]);
    return (_jsx(ProvidersWrapper, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, path: 'dashboard', element: _jsx(StudiesDashboardPage, {}) }), _jsxs(Route, { path: '/study/:studyId', element: _jsx(LayoutWrapper, {}), children: [canViewDataReports && _jsx(Route, { index: true, path: 'reporting', element: _jsx(ReportsList, {}) }), canViewDataTransfers && _jsx(Route, { path: 'transfer', element: _jsx(DataTransferPage, {}) }), isViewReportsEnabled && _jsx(Route, { path: 'reports', element: _jsx(DataReportsViewPage, {}) }), isBuildReportsEnabled && _jsx(Route, { path: 'reports/build', element: _jsx(DataReportsBuildPage, {}) }), _jsx(Route, { path: 'study-manager-access', element: _jsx(StudyManagerAccessPage, {}) }), _jsx(Route, { path: 'user-training', element: _jsx(UserTrainingReportPage, {}) })] }), canViewDataReports && _jsx(Route, { path: '/study/:studyId/reporting/:reportId', element: _jsx(ReportDashboard, {}) }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] }) }));
};
export { DATA_MANAGEMENT_APP_FLAGS } from './constants/developer_flags.constants';
