import { jsx as _jsx } from "react/jsx-runtime";
import { FormSections, useLocalizeMessage, RadioGroup, RadioGroupOption } from 'libs.nucleus';
import { SITE_LOCALES } from '../../constants';
export const SelectLocaleTypeSection = ({ localeCode, localeType = 'participant', onLocaleTypeChange, useLimitedLocales, }) => {
    const translate = useLocalizeMessage();
    const options = [
        { value: 'participant', label: 'Participant' },
        {
            value: 'both',
            label: 'Site and Participant',
            isDisabled: useLimitedLocales && localeCode && !SITE_LOCALES.includes(localeCode),
        },
    ];
    const section = [
        {
            title: translate('Locale availability'),
            description: translate('Select whether a locale is available to participants or both site users and participants.'),
            content: (_jsx("div", { children: _jsx(RadioGroup, { label: translate('Locale availability'), value: localeType, onChange: onLocaleTypeChange, required: true, children: options.map((option) => (_jsx(RadioGroupOption, { option: option, disabled: option.isDisabled }, option.value))) }) })),
        },
    ];
    return _jsx(FormSections, { sections: section });
};
