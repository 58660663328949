import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { SDKStudy, Error as SDKError, DefaultTimelineId, StudyLocalizationStatus, RosettaSDKUtils, SpecialProductTemplate, } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useLocalizeMessage, useDialog, useToastNotification } from 'libs.nucleus';
import { AclScope, ApiClientService, AuthContext, LibraryEndpoint, LibraryResourceStatus, useApiClient, useProductTemplates, useScopeValidator, } from 'libs.react';
import { useDocumentVisibility } from '../../hooks/use_document_visibility/use_document_visibility.hooks';
import { getActivityWarningMessage, hashToTimelineName, timelineNameToHash } from '../../utils/study/study.utils';
import { getDependentActivities } from '../../utils/study/study_configuration.utils';
import { StudiesContext } from '../studies/studies.context';
const mockedStudy = new SDKStudy({ name: '', template: SpecialProductTemplate.r400, pinnedProductTemplate: 40000 });
const sortActivities = (study, timelineId) => {
    return study
        .mapActivities(study.visitActivities[timelineId])
        .sort((activityA, activityB) => (activityA.order || 0) - (activityB.order || 0));
};
const missingProvider = () => {
    throw Error('Missing the study configuration provider');
};
export const DEFAULT_STUDY_CONFIGURATION_VALUE = {
    activities: [],
    assignStudy: missingProvider,
    changeCurrentTimelineId: missingProvider,
    configId: '',
    createNewStudy: missingProvider,
    currentStudy: mockedStudy,
    currentTimelineId: '',
    getStudy: missingProvider,
    getStudyConfiguration: missingProvider,
    isActivityOnVisit: missingProvider,
    isInitialized: false,
    isLoading: true,
    isUpgradeAvailable: false,
    persistedTimelineId: '',
    removeActivityStatusDependencies: missingProvider,
    scheduledActivities: [],
    setOnboarding: missingProvider,
    studyId: '',
    toggleActivityOnVisit: missingProvider,
    translationsReadOnly: false,
    visits: [],
};
export const StudyConfigurationContext = createContext(DEFAULT_STUDY_CONFIGURATION_VALUE);
const getInitialTimelineId = (study, hash) => {
    const timelineName = hashToTimelineName(hash);
    const timeline = Object.values(study.getScheduleTimelines()).find((timeline) => timeline.name.toLocaleLowerCase() === timelineName.toLocaleLowerCase());
    return timeline?.id ?? study.timelineInfo.onboarding.id;
};
export const StudyConfigurationProvider = ({ children }) => {
    const { createStudy, showStudyUpdatedWarning, updateStudy, studies } = useContext(StudiesContext);
    const { entity, entityId, refreshIdentity } = useContext(AuthContext);
    const dialog = useDialog();
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const momClient = useApiClient(ApiClientService.MOM);
    const { logEvent } = useContext(FirebaseAppContext);
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const match = useMatch('/studies/:studyId/:section/*');
    const { hash } = useLocation();
    const navigate = useNavigate();
    const isDocumentVisible = useDocumentVisibility();
    const { validatedTemplate } = useProductTemplates();
    const [activities, setActivities] = useState([]);
    const [currentStudy, setCurrentStudy] = useState(mockedStudy);
    const [visits, setVisits] = useState([]);
    const [scheduledActivities, setScheduledActivities] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const [momStudy, setMOMStudy] = useState();
    const [studyId, setStudyId] = useState('');
    const [configId, setConfigId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentTimelineId, setCurrentTimelineId] = useState('');
    const [persistedTimelineId, setPersistedTimelineId] = useState(currentStudy.timelineInfo.main.id);
    const canEditStudy = useScopeValidator(AclScope.MOM_STUDY_UPDATE, {
        resource: `entity/${entityId}/study/${studyId}`,
    });
    const isUpgradeAvailable = useMemo(() => {
        try {
            return (canEditStudy &&
                isInitialized &&
                !!currentStudy.name &&
                RosettaSDKUtils.isTemplateOlderThan(currentStudy.productTemplate, validatedTemplate));
        }
        catch (error) {
            addNotification({
                title: translate('Unable to open study'),
                subtitle: translate('There was an error while attempting to load the study platform version, please contact support.'),
                type: 'error',
            });
            console.error(error);
            navigate('/studies/dashboard');
            return false;
        }
    }, [isInitialized, currentStudy.name, currentStudy.productTemplate]);
    const translationsReadOnly = useMemo(() => currentStudy.localizationStatus === StudyLocalizationStatus.READY, [currentStudy.localizationStatus]);
    useEffect(() => {
        if (isInitialized && isDocumentVisible) {
            verifyConfiguration();
        }
    }, [isInitialized, isDocumentVisible]);
    useEffect(() => {
        if (match && match.params.studyId && match.params.section && match.params.studyId !== studyId) {
            setStudyId(match.params.studyId);
            getStudy(match.params.studyId);
        }
    }, [match?.params]);
    /**
     * Verifies if the current study configuration is still the one in Draft
     * as it might have changed to Published in a different tab, or by a different user
     */
    const verifyConfiguration = async () => {
        if (studyId && configId) {
            setIsLoading(true);
            const studyConfiguration = await getStudyConfiguration(studyId);
            setIsLoading(false);
            if (studyConfiguration) {
                if (studyConfiguration.id !== configId) {
                    showStudyUpdatedWarning(true);
                }
                else {
                    const extraProps = momStudy && { creationDate: momStudy.createdAt };
                    const study = SDKStudy.fromRosetta(studyConfiguration.data, extraProps);
                    if (entity?.configuration) {
                        // Privacy policy is not coming as part of the entity for builder as of today, and we dont want to overwrite this value with empty by mistake
                        study.privacyPolicyURL = entity.configuration.privacyPolicy || '';
                    }
                    assignStudy(study, false);
                }
            }
        }
    };
    const getStudy = async (id = studyId) => {
        setIsLoading(true);
        let existingStudy = studies.find((study) => study.id === parseInt(id));
        if (!existingStudy) {
            // we need to fetch the study to see if it exists. if it doesnt, reroute back to studies dashboard
            try {
                const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies/${id}`, { no404Handling: true });
                existingStudy = response.data;
            }
            catch (error) {
                if (error.response?.status === 404) {
                    setIsLoading(false);
                    setIsInitialized(true);
                    return {};
                }
            }
        }
        const studyConfiguration = await getStudyConfiguration(id);
        let newStudyConfigId;
        let newStudy;
        if (studyConfiguration && existingStudy) {
            try {
                setConfigId(studyConfiguration.id);
                setMOMStudy(existingStudy);
                const study = SDKStudy.fromRosetta(studyConfiguration.data, { creationDate: existingStudy.createdAt });
                if (entity?.configuration) {
                    // Privacy policy is not coming as part of the entity for builder as of today, and we dont want to overwrite this value with empty by mistake
                    study.privacyPolicyURL = entity.configuration.privacyPolicy || '';
                }
                const timelineId = getInitialTimelineId(study, hash);
                assignStudy(study, false, timelineId);
                setCurrentTimelineId(timelineId);
                if (timelineId !== currentStudy.timelineInfo.onboarding.id) {
                    setPersistedTimelineId(timelineId);
                }
                newStudyConfigId = studyConfiguration.id;
                newStudy = study;
            }
            catch (error) {
                addNotification({
                    title: translate('Unable to open study'),
                    subtitle: error instanceof SDKError && error.message
                        ? error.message
                        : translate('There was an error while opening the study, please contact support.'),
                    type: 'error',
                });
            }
        }
        setIsLoading(false);
        setIsInitialized(true);
        return { newStudyConfigId, newStudy };
    };
    const getStudyConfiguration = async (id) => {
        setIsLoading(true);
        const searchParams = new URLSearchParams({ studyId: id, status: LibraryResourceStatus.DRAFT });
        const { data: response } = await libraryClient.get(`${LibraryEndpoint.GET_STUDY_CONFIGS}?${searchParams.toString()}`);
        setIsLoading(false);
        return response.data && response.data.length > 0 ? response.data[0] : undefined;
    };
    const createNewStudy = async (study) => {
        setIsLoading(true);
        try {
            const newStudyId = await createStudy(study);
            assignStudy(study, false, study.timelineInfo.onboarding.id);
            await refreshIdentity();
            return newStudyId;
        }
        finally {
            setIsLoading(false);
        }
    };
    const changeCurrentTimelineId = useCallback((timelineId, doNavigate = true) => {
        if (timelineId !== currentTimelineId) {
            setCurrentTimelineId(timelineId);
            if (timelineId !== currentStudy.timelineInfo.onboarding.id) {
                setPersistedTimelineId(timelineId);
            }
            setActivities(sortActivities(currentStudy, timelineId));
            const studyVisitSchedule = currentStudy.visitSchedule[timelineId];
            setVisits(studyVisitSchedule.map((visitId) => currentStudy.visits[timelineId][visitId]));
            setScheduledActivities(currentStudy.scheduledActivities[timelineId]);
            if (doNavigate) {
                navigate(timelineNameToHash(currentStudy.getScheduleTimelines()[timelineId].name));
            }
        }
    }, [currentStudy, currentTimelineId]);
    /**
     * Updates the information of the current Study with the new changes made
     */
    const assignStudy = (newStudy, updateLibraryConfig = true, timelineId = currentTimelineId) => {
        setCurrentStudy(newStudy);
        setActivities(sortActivities(newStudy, timelineId));
        const studyVisitSchedule = newStudy.visitSchedule[timelineId];
        setVisits(studyVisitSchedule.map((visitId) => newStudy.visits[timelineId][visitId]));
        setScheduledActivities(newStudy.scheduledActivities[timelineId]);
        if (updateLibraryConfig) {
            updateStudy(configId, newStudy);
        }
    };
    /**
     * Checks if the given activity is part of the given visit schedule
     * @param activity - Activity
     * @param visitId - string
     */
    const isActivityOnVisit = (activity, visitId) => {
        const visit = currentStudy.getVisitById(currentTimelineId, visitId);
        return !!visit && !!visit.activities[activity.id];
    };
    /**
     * Used to persist the previous value of the schedule timeline id (if there is any)
     * so that we can provide a better UX when the user navigates through the Onboarding and Schedule tabs
     * @param isOnboardingEnabled
     */
    const setOnboarding = (isOnboardingEnabled) => {
        if (isOnboardingEnabled) {
            changeCurrentTimelineId(currentStudy.timelineInfo.onboarding.id, false);
            if (currentTimelineId !== currentStudy.timelineInfo.onboarding.id) {
                setPersistedTimelineId(currentTimelineId);
            }
        }
        else {
            changeCurrentTimelineId(persistedTimelineId, false);
        }
    };
    /**
     * Removes all dependent activity status dependencies for the given status id
     */
    const removeActivityStatusDependencies = (statusId) => {
        currentStudy.participantStatusConditions = currentStudy.participantStatusConditions.filter((statusCondition) => statusCondition.updatesParticipantStatusTo !== statusId);
        assignStudy(currentStudy);
    };
    /**
     * Given an activity and a visit,
     * it will include or exclude the activity on/from the visit depending on the previous visit schedule
     * @param activity
     * @param visitId
     */
    const toggleActivityOnVisit = async (activity, visitId) => {
        const visit = currentStudy.getVisitById(currentTimelineId, visitId);
        if (visit) {
            if (visit.activities[activity.id]) {
                const instance = visit.getActivitySequenceByActivityId(activity.id);
                const dependencyFilter = (condition) => 'dependencyInstanceId' in condition && condition.dependencyInstanceId === instance.instanceId;
                const dependentActivities = currentTimelineId !== DefaultTimelineId.ONBOARDING
                    ? visit.activitySequence.filter((activityInstance) => {
                        return (activityInstance.instanceId !== instance.instanceId &&
                            (activityInstance.timing.startConditions.some(dependencyFilter) ||
                                activityInstance.timing.endConditions.some(dependencyFilter)));
                    })
                    : getDependentActivities(activity.id, currentStudy, currentTimelineId);
                if (dependentActivities.length > 0) {
                    await dialog.open({
                        title: translate('Activity cannot be unscheduled'),
                        body: getActivityWarningMessage({
                            dependentActivities,
                            translate,
                            isUnscheduling: true,
                            currentTimelineId,
                            timelines: currentStudy.getScheduleTimelines(),
                        }),
                        actionButtons: [{ isDestructive: false, label: translate('OK') }],
                    });
                }
                else {
                    visit.removeActivity(activity);
                }
            }
            else {
                visit.addActivity(activity, { study: currentStudy });
                // When scheduling the first activity to the Onboarding visit, we need to ensure all timelines have a start activity assigned
                if (currentTimelineId === DefaultTimelineId.ONBOARDING && Object.values(visit.activities).length === 1) {
                    currentStudy.assignStartActivityToAllFirstVisits();
                }
            }
            logEvent('study_activity_scheduled', {
                activity_name: activity.name,
                assigned: !!visit.activities[activity.id],
                study_id: currentStudy.id,
                visit_name: visit.name,
            });
        }
        assignStudy(currentStudy);
    };
    const value = useMemo(() => ({
        activities,
        assignStudy,
        changeCurrentTimelineId,
        configId,
        createNewStudy,
        currentStudy,
        currentTimelineId,
        getStudy,
        getStudyConfiguration,
        isActivityOnVisit,
        isInitialized,
        isLoading,
        persistedTimelineId,
        removeActivityStatusDependencies,
        scheduledActivities,
        setOnboarding,
        studyId,
        toggleActivityOnVisit,
        translationsReadOnly,
        visits,
        isUpgradeAvailable,
    }), [
        activities,
        assignStudy,
        changeCurrentTimelineId,
        configId,
        createNewStudy,
        currentStudy,
        currentTimelineId,
        getStudy,
        getStudyConfiguration,
        isActivityOnVisit,
        isInitialized,
        isLoading,
        persistedTimelineId,
        removeActivityStatusDependencies,
        scheduledActivities,
        setOnboarding,
        studyId,
        isUpgradeAvailable,
        toggleActivityOnVisit,
        translationsReadOnly,
        visits,
    ]);
    return _jsx(StudyConfigurationContext.Provider, { value: value, children: children });
};
