import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister, CAN_USE_DOM } from '@lexical/utils';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, createCommand, KEY_ARROW_DOWN_COMMAND, KEY_ARROW_UP_COMMAND, KEY_ENTER_COMMAND, KEY_ESCAPE_COMMAND, KEY_TAB_COMMAND, } from 'lexical';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
export const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
export class MenuOption {
    key;
    ref;
    constructor(key) {
        this.key = key;
        this.ref = { current: null };
        this.setRefElement = this.setRefElement.bind(this);
    }
    setRefElement(element) {
        this.ref = { current: element };
    }
}
const scrollIntoViewIfNeeded = (target) => {
    const typeaheadContainerNode = document.getElementById('autocomplete-menu');
    if (!typeaheadContainerNode) {
        return;
    }
    const typeaheadRect = typeaheadContainerNode.getBoundingClientRect();
    if (typeaheadRect.top + typeaheadRect.height > window.innerHeight) {
        typeaheadContainerNode.scrollIntoView({
            block: 'center',
        });
    }
    if (typeaheadRect.top < 0) {
        typeaheadContainerNode.scrollIntoView({
            block: 'center',
        });
    }
    target.scrollIntoView({ block: 'nearest' });
};
/**
 * Walk backwards along user input and forward through entity title to try
 * and replace more of the user's text with entity.
 */
function getFullMatchOffset(documentText, entryText, offset) {
    let triggerOffset = offset;
    for (let i = triggerOffset; i <= entryText.length; i++) {
        if (documentText.slice(-i) === entryText.substring(0, i)) {
            triggerOffset = i;
        }
    }
    return triggerOffset;
}
/**
 * Split Lexical TextNode and return a new TextNode only containing matched text.
 * Common use cases include: removing the node, replacing with a new node.
 */
const $splitNodeContainingQuery = (match) => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return null;
    }
    const { anchor } = selection;
    if (anchor.type !== 'text') {
        return null;
    }
    const anchorNode = anchor.getNode();
    if (!anchorNode.isSimpleText()) {
        return null;
    }
    const selectionOffset = anchor.offset;
    const textContent = anchorNode.getTextContent().slice(0, selectionOffset);
    const characterOffset = match.replaceableString.length;
    const queryOffset = getFullMatchOffset(textContent, match.matchingString, characterOffset);
    const startOffset = selectionOffset - queryOffset;
    if (startOffset < 0) {
        return null;
    }
    let newNode;
    if (startOffset === 0) {
        [newNode] = anchorNode.splitText(selectionOffset);
    }
    else {
        [, newNode] = anchorNode.splitText(startOffset, selectionOffset);
    }
    return newNode;
};
// Got from https://stackoverflow.com/a/42543908/2013580
export function getScrollParent(element, includeHidden) {
    let style = getComputedStyle(element);
    const excludeStaticParent = style.position === 'absolute';
    const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
    if (style.position === 'fixed') {
        return document.body;
    }
    for (let parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === 'static') {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
            return parent;
        }
    }
    return document.body;
}
function isTriggerVisibleInNearestScrollContainer(targetElement, containerElement) {
    const tRect = targetElement.getBoundingClientRect();
    const cRect = containerElement.getBoundingClientRect();
    return tRect.top > cRect.top && tRect.top < cRect.bottom;
}
// Reposition the menu on scroll, window resize, and element resize.
export function useDynamicPositioning(resolution, targetElement, onReposition, onVisibilityChange) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (targetElement != null && resolution != null) {
            const rootElement = editor.getRootElement();
            const rootScrollParent = rootElement != null ? getScrollParent(rootElement, false) : document.body;
            let ticking = false;
            let previousIsInView = isTriggerVisibleInNearestScrollContainer(targetElement, rootScrollParent);
            const handleScroll = function () {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        onReposition();
                        ticking = false;
                    });
                    ticking = true;
                }
                const isInView = isTriggerVisibleInNearestScrollContainer(targetElement, rootScrollParent);
                if (isInView !== previousIsInView) {
                    previousIsInView = isInView;
                    if (onVisibilityChange != null) {
                        onVisibilityChange(isInView);
                    }
                }
            };
            const resizeObserver = new ResizeObserver(onReposition);
            window.addEventListener('resize', onReposition);
            document.addEventListener('scroll', handleScroll, {
                capture: true,
                passive: true,
            });
            resizeObserver.observe(targetElement);
            return () => {
                resizeObserver.unobserve(targetElement);
                window.removeEventListener('resize', onReposition);
                document.removeEventListener('scroll', handleScroll, true);
            };
        }
    }, [targetElement, editor, onVisibilityChange, onReposition, resolution]);
}
export const SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND = createCommand('SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND');
export function LexicalMenu({ close, editor, anchorElementRef, resolution, options, menuRenderFn, onSelectOption, shouldSplitNodeWithQuery = false, commandPriority = COMMAND_PRIORITY_LOW, }) {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const matchingString = resolution.match && resolution.match.matchingString;
    useEffect(() => {
        setSelectedIndex(0);
    }, [matchingString]);
    const selectOptionAndCleanUp = useCallback((selectedEntry) => {
        editor.update(() => {
            const textNodeContainingQuery = resolution.match != null && shouldSplitNodeWithQuery ? $splitNodeContainingQuery(resolution.match) : null;
            onSelectOption(selectedEntry, textNodeContainingQuery, close, resolution.match ? resolution.match.matchingString : '');
        });
    }, [editor, shouldSplitNodeWithQuery, resolution.match, onSelectOption, close]);
    const updateSelectedIndex = useCallback((index) => {
        const rootElem = editor.getRootElement();
        if (rootElem !== null) {
            rootElem.setAttribute('aria-activedescendant', 'autocomplete-item' + index);
            setSelectedIndex(index);
        }
    }, [editor]);
    useEffect(() => {
        return () => {
            const rootElem = editor.getRootElement();
            if (rootElem !== null) {
                rootElem.removeAttribute('aria-activedescendant');
            }
        };
    }, [editor]);
    useLayoutEffect(() => {
        if (options === null) {
            setSelectedIndex(null);
        }
        else if (selectedIndex === null) {
            updateSelectedIndex(0);
        }
    }, [options, selectedIndex, updateSelectedIndex]);
    useEffect(() => {
        return mergeRegister(editor.registerCommand(SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND, ({ option }) => {
            if (option.ref && option.ref.current != null) {
                scrollIntoViewIfNeeded(option.ref.current);
                return true;
            }
            return false;
        }, commandPriority));
    }, [editor, updateSelectedIndex, commandPriority]);
    useEffect(() => {
        return mergeRegister(editor.registerCommand(KEY_ARROW_DOWN_COMMAND, (payload) => {
            const event = payload;
            if (options !== null && options.length && selectedIndex !== null) {
                const newSelectedIndex = selectedIndex !== options.length - 1 ? selectedIndex + 1 : 0;
                updateSelectedIndex(newSelectedIndex);
                const option = options[newSelectedIndex];
                if (option.ref != null && option.ref.current) {
                    editor.dispatchCommand(SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND, {
                        index: newSelectedIndex,
                        option,
                    });
                }
                event.preventDefault();
                event.stopImmediatePropagation();
            }
            return true;
        }, commandPriority), editor.registerCommand(KEY_ARROW_UP_COMMAND, (payload) => {
            const event = payload;
            if (options !== null && options.length && selectedIndex !== null) {
                const newSelectedIndex = selectedIndex !== 0 ? selectedIndex - 1 : options.length - 1;
                updateSelectedIndex(newSelectedIndex);
                const option = options[newSelectedIndex];
                if (option.ref != null && option.ref.current) {
                    scrollIntoViewIfNeeded(option.ref.current);
                }
                event.preventDefault();
                event.stopImmediatePropagation();
            }
            return true;
        }, commandPriority), editor.registerCommand(KEY_ESCAPE_COMMAND, (payload) => {
            const event = payload;
            event.preventDefault();
            event.stopImmediatePropagation();
            close();
            return true;
        }, commandPriority), editor.registerCommand(KEY_TAB_COMMAND, (payload) => {
            const event = payload;
            if (options === null || selectedIndex === null || options[selectedIndex] == null) {
                return false;
            }
            event.preventDefault();
            event.stopImmediatePropagation();
            selectOptionAndCleanUp(options[selectedIndex]);
            return true;
        }, commandPriority), editor.registerCommand(KEY_ENTER_COMMAND, (event) => {
            if (options === null || selectedIndex === null || options[selectedIndex] == null) {
                return false;
            }
            if (event !== null) {
                event.preventDefault();
                event.stopImmediatePropagation();
            }
            selectOptionAndCleanUp(options[selectedIndex]);
            return true;
        }, commandPriority));
    }, [selectOptionAndCleanUp, close, editor, options, selectedIndex, updateSelectedIndex, commandPriority]);
    const listItemProps = useMemo(() => ({
        options,
        selectOptionAndCleanUp,
        selectedIndex,
        setHighlightedIndex: setSelectedIndex,
    }), [selectOptionAndCleanUp, selectedIndex, options]);
    return menuRenderFn(anchorElementRef, listItemProps, resolution.match ? resolution.match.matchingString : '');
}
function setContainerDivAttributes(containerDiv, className) {
    if (className != null) {
        containerDiv.className = className;
    }
    containerDiv.setAttribute('aria-label', 'Autocomplete menu');
    containerDiv.setAttribute('role', 'listbox');
    containerDiv.style.display = 'block';
    containerDiv.style.position = 'absolute';
    containerDiv.style.zIndex = '10';
}
export function useMenuAnchorRef(resolution, setResolution, className, parent = CAN_USE_DOM ? document.body : undefined, shouldIncludePageYOffset__EXPERIMENTAL = true) {
    const [editor] = useLexicalComposerContext();
    const anchorElementRef = useRef(CAN_USE_DOM ? document.createElement('div') : null);
    const positionMenu = useCallback(() => {
        if (anchorElementRef.current === null || parent === undefined) {
            return;
        }
        anchorElementRef.current.style.top = anchorElementRef.current.style.bottom;
        const rootElement = editor.getRootElement();
        const containerDiv = anchorElementRef.current;
        const menuEle = containerDiv.firstChild;
        if (rootElement !== null && resolution !== null) {
            const { left, top, width, height } = resolution.getRect();
            const anchorHeight = anchorElementRef.current.offsetHeight; // use to position under anchor
            containerDiv.style.top = `${top + anchorHeight + 3 + (shouldIncludePageYOffset__EXPERIMENTAL ? window.pageYOffset : 0)}px`;
            containerDiv.style.left = `${left + window.pageXOffset}px`;
            containerDiv.style.height = `${height}px`;
            containerDiv.style.width = `${width}px`;
            if (menuEle !== null) {
                menuEle.style.top = `${top}`;
                const menuRect = menuEle.getBoundingClientRect();
                const menuHeight = menuRect.height;
                const menuWidth = menuRect.width;
                const rootElementRect = rootElement.getBoundingClientRect();
                if (left + menuWidth > rootElementRect.right) {
                    containerDiv.style.left = `${rootElementRect.right - menuWidth + window.pageXOffset}px`;
                }
                if ((top + menuHeight > window.innerHeight || top + menuHeight > rootElementRect.bottom) &&
                    top - rootElementRect.top > menuHeight + height) {
                    containerDiv.style.top = `${top - menuHeight - height + (shouldIncludePageYOffset__EXPERIMENTAL ? window.pageYOffset : 0)}px`;
                }
            }
            if (!containerDiv.isConnected) {
                setContainerDivAttributes(containerDiv, className);
                parent.append(containerDiv);
            }
            containerDiv.setAttribute('id', 'autocomplete-menu');
            anchorElementRef.current = containerDiv;
            rootElement.setAttribute('aria-controls', 'autocomplete-menu');
        }
    }, [editor, resolution, shouldIncludePageYOffset__EXPERIMENTAL, className, parent]);
    useEffect(() => {
        const rootElement = editor.getRootElement();
        if (resolution !== null) {
            positionMenu();
            return () => {
                if (rootElement !== null) {
                    rootElement.removeAttribute('aria-controls');
                }
                const containerDiv = anchorElementRef.current;
                if (containerDiv !== null && containerDiv.isConnected) {
                    containerDiv.remove();
                    containerDiv.removeAttribute('id');
                }
            };
        }
    }, [editor, positionMenu, resolution]);
    const onVisibilityChange = useCallback((isInView) => {
        if (resolution !== null) {
            if (!isInView) {
                setResolution(null);
            }
        }
    }, [resolution, setResolution]);
    useDynamicPositioning(resolution, anchorElementRef.current, positionMenu, onVisibilityChange);
    // Append the context for the menu immediately
    const containerDiv = anchorElementRef.current;
    if (containerDiv != null) {
        setContainerDivAttributes(containerDiv, className);
        if (parent != null && resolution) {
            parent.append(containerDiv);
        }
    }
    return anchorElementRef;
}
