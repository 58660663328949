var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useLocalizeMessage } from 'libs.nucleus';
import { ApiClientService, ListInformation, PageHeader, PageWrapper, useApiClient, buildInfo, } from 'libs.react';
import { URP_VERSION } from '../../constants/medable.constants';
export const BuildInfo = () => {
    const translate = useLocalizeMessage();
    const iamClient = useApiClient(ApiClientService.IAM);
    const momClient = useApiClient(ApiClientService.MOM);
    const libraryClient = useApiClient(ApiClientService.LIBRARY);
    const webClient = useApiClient(ApiClientService.WEB);
    const [versions, setVersions] = useState({ iam: '', mom: '', library: '', dt: '', urp: URP_VERSION });
    useEffect(() => {
        getVersions();
    }, []);
    const getVersions = () => __awaiter(void 0, void 0, void 0, function* () {
        const [{ data: iamResponse }, { data: momResponse }, { data: libraryResponse }, { data: webResponse }] = yield Promise.all([
            iamClient.get('/version'),
            momClient.get('/version'),
            libraryClient.get('/version'),
            webClient.get('/api/data_transfer/version'),
        ]);
        setVersions(Object.assign(Object.assign({}, versions), { iam: iamResponse.result.version, library: libraryResponse.result.version, mom: momResponse.result.version, dt: webResponse.result.version }));
    });
    const { BUILD_DATE, CI_COMMIT_TAG } = buildInfo;
    const listItems = [
        { title: translate('Studio App'), description: `${CI_COMMIT_TAG || 'Latest'} (${BUILD_DATE})` },
        { title: translate('IAM'), description: versions.iam },
        { title: translate('MOM'), description: versions.mom },
        { title: translate('Library'), description: versions.library },
        { title: translate('Study data transfers'), description: versions.dt },
        { title: translate('Unified reporting platform'), description: versions.urp },
    ];
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('Studio versions') }), _jsx(ListInformation, { items: listItems })] }));
};
