import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AssetTypes } from 'libs.localization.common';
import { useLocalizeMessage, Link, InlineNotification, Spinner, H3, Tooltip, Badge, } from 'libs.nucleus';
import { PageHeader } from 'libs.react';
import { SectionExport } from '../../components/section_export';
import { SectionImport } from '../../components/section_import';
import { TranslationWorkbenchContext } from '../../contexts';
import { useLibraryDetails } from '../../hooks';
export const ActivityAssetView = ({ assetId, studyId }) => {
    const translate = useLocalizeMessage();
    const { libraryAssets, isStudyEnglishOnly } = useContext(TranslationWorkbenchContext);
    const { isLoading, data: library } = useLibraryDetails(assetId);
    const _activity = Object.values(libraryAssets).find((asset) => asset.id === assetId);
    const filteredLocales = Object.keys(_activity?.currentVersionStatus || {});
    // autobuild activities return in data.data.data.activity vs ecoa return in data.data.data format
    const resource = library?.data.data.data.activity || library?.data.data.data;
    const resourceDisplayname = resource?.short_name;
    const buildVersion = library?.data.data.version;
    const breadcrumbPages = useMemo(() => [
        { name: translate('Translations'), path: `/studies/${studyId}/translations` },
        {
            name: resourceDisplayname || translate('Library'),
            path: `/studies/${studyId}/translations/${assetId}`,
        },
    ], [resourceDisplayname]);
    const asset = {
        type: AssetTypes.ACTIVITY,
        id: resource?.identifier,
        version: '1.0',
        buildVersion,
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: _jsxs("div", { className: 'flex items-center justify-center gap-x-2', children: [_jsx(H3, { children: resourceDisplayname || translate('Library') }), resource?.is_ai_generated && (_jsx(Tooltip, { title: translate('This activity was created with the assistance of AI'), placement: 'end', children: _jsx(Badge, { variant: 'common', label: translate('Assisted by AI') }) }))] }), breadcrumbPages: breadcrumbPages }), _jsx("hr", { className: 'mb-6' }), _jsxs("div", { className: 'flex flex-col gap-6', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), assetId && (_jsx(InlineNotification, { title: translate('Library asset'), subtitle: translate('Translations done here are applied to the Library, so any change may affect other studies.'), action: _jsx(Link, { LinkComponent: RouterLink, to: `/library/activities/${assetId}#translation`, target: '_blank', rel: 'noopener noreferrer', children: translate('View in Library') }) })), resource && _jsx(SectionExport, { asset: asset, trackingContext: { context: 'study' } }), _jsx("hr", {}), resource && !isStudyEnglishOnly && _jsx(SectionImport, { asset: asset, locales: filteredLocales })] })] }));
};
