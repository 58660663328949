import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { ModalWindow, Spinner, useLocalizeMessage, useToastNotification } from 'libs.nucleus';
import { SectionCard, useBooleanState } from 'libs.react';
import { NonVisitSchedule } from './non_visit_schedule.component';
import { OnboardingSchedule } from './onboarding_schedule.component';
import { VisitSchedule } from './visit_schedule.component';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { getParticipantDetails, isParticipantDeactivated } from '../../helpers/participants.helpers';
import { getOnboardingConfig } from '../../helpers/study.helpers';
import { getParticipantVisitScheduleData } from '../../helpers/visit.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useOrgContext } from '../../hooks/use_org_context';
import { ActivitiesList } from '../activities_list/activities_list.component';
import { ActivityView } from '../activity/activity_modal/activity_view';
export const ParticipantVisits = ({ participantNumber }) => {
    const [isActivityListModalOpen, setIsActivityListModalOpen, setIsActivityListModalClosed] = useBooleanState(false);
    const [isActivityModalOpen, setIsActivityModalOpen, setIsActivityModalClosed] = useBooleanState(false);
    const [visitActivity, setVisitActivity] = useState({});
    const [currentVisit, setCurrentVisit] = useState({});
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const { addNotification } = useToastNotification();
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const onboardingGroupId = getOnboardingConfig(org)?.c_group_id;
    const queryClient = useQueryClient();
    const getParticipantData = async () => {
        if (!environment) {
            return [];
        }
        return await getParticipantDetails({
            environment,
            siteId: site?._id ?? '',
            participantId: participantId ?? '',
        });
    };
    const { data: participantDetails, isLoading: isParticipantLoading } = useQuery({
        queryKey: ['participantDetails', participantId],
        queryFn: getParticipantData,
        enabled: true,
    });
    const participantCNumber = participantNumber || (isParticipantLoading ? '' : participantDetails?.c_number || '');
    const getOnboardingData = async () => {
        if (!environment) {
            return [];
        }
        try {
            const response = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_groups/${onboardingGroupId}/c_task_assignments`);
            return response ? response?.data.data : [];
        }
        catch (error) {
            addNotification({ title: '', subtitle: translate('error.visits.fetch_onboarding'), type: 'error' });
        }
    };
    const getParticipantVisitsData = async () => {
        if (!environment) {
            return [];
        }
        try {
            const response = participantCNumber && site?._id && participantId
                ? await getParticipantVisitScheduleData({ environment, participantId, siteId: site?._id })
                : null;
            return response ? response?.data.data : [];
        }
        catch (error) {
            addNotification({ title: '', subtitle: translate('error.visits.fetch_schedule'), type: 'error' });
        }
    };
    const results = useQueries({
        queries: [
            { queryKey: ['onboardingActivities', onboardingGroupId], queryFn: getOnboardingData, enabled: true },
            {
                queryKey: ['participantVisits', participantId, participantCNumber],
                queryFn: getParticipantVisitsData,
                enabled: true,
            },
        ],
    });
    const getNonVisitGroups = async () => {
        if (!environment) {
            return [];
        }
        const response = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/non_visit_groups`, {
            params: {
                limit: 100,
                skip: 0,
                sort: -1,
            },
        });
        return (response ? response?.data.data : []).filter((responseData) => !responseData.is_patient_tasks);
    };
    const { data: nonVisitGroups, isLoading: isNonVisitLoading } = useQuery({
        queryKey: ['nonVisitGroups', site?._id, participantId],
        queryFn: getNonVisitGroups,
        enabled: true,
    });
    const invalidateCachesWithActiviyList = () => {
        queryClient.invalidateQueries({
            queryKey: ['onboardingActivities', onboardingGroupId],
        });
        queryClient.invalidateQueries({
            queryKey: ['participantVisits', participantId, participantCNumber],
        });
    };
    const invalidateVisitScheduleCaches = () => {
        queryClient.invalidateQueries({
            queryKey: ['participantActivities', participantId],
        });
        queryClient.invalidateQueries({
            queryKey: [
                'siteActivities',
                participantId,
                currentVisit.c_visit ? currentVisit.c_visit : currentVisit.c_group,
                site?._id,
            ],
        });
        queryClient.invalidateQueries({
            queryKey: ['visitEvent', participantId],
        });
        queryClient.invalidateQueries({
            queryKey: ['participantVisits', participantId, participantCNumber],
        });
        queryClient.invalidateQueries({
            queryKey: ['participantDetails', match?.params.participantId],
        });
        queryClient.invalidateQueries({
            queryKey: ['nonVisitGroups', site?._id, participantId],
        });
    };
    const isLoading = results.some((query) => query.isLoading);
    if (isLoading || isParticipantLoading || isNonVisitLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const onboardingVisits = results[0].data;
    const participantVisits = results[1].data;
    if (participantVisits.length === 0 && onboardingVisits.length === 0) {
        return (_jsx(SectionCard, { children: _jsx("section", { className: 'flex flex-col items-center justify-center p-10 m-10', children: _jsx("p", { className: 'mb-8 text-sm text-secondary', children: translate('info.visits.none_created') }) }) }));
    }
    return (_jsxs("div", { className: 'flex flex-col gap-8', children: [!isActivityModalOpen && (_jsx(ModalWindow, { title: currentVisit.c_name || translate('label.visits.onboarding_schedule'), subtitle: participantNumber, isOpen: isActivityListModalOpen, hideCloseIcon: true, closeWindow: () => { }, footerSecondaryActionButtons: [
                    {
                        disabled: false,
                        label: translate('action.visits.exit'),
                        content: undefined,
                        onClick: () => {
                            invalidateVisitScheduleCaches();
                            setIsActivityListModalClosed();
                            setCurrentVisit({});
                        },
                        dataTestId: 'exit-visit',
                    },
                ], width: 'full', removeBodyPadding: true, children: _jsx(ActivitiesList, { visit: currentVisit, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen, onActionComplete: invalidateCachesWithActiviyList, participant: participantDetails, ...(currentVisit.customVisitType === 'onboarding' && {
                        isOnboarding: true,
                        onboardingSchedule: onboardingVisits,
                    }), ...(currentVisit.customVisitType !== 'visit_schedule' && { isNonVisitSchedule: true }) }) })), _jsx(ModalWindow, { title: visitActivity.activity?.c_group_task ? visitActivity.activity.c_group_task.c_assignment.c_name : '', subtitle: '', isOpen: isActivityModalOpen, hideCloseIcon: true, closeWindow: () => { }, width: 'full', children: _jsx(ActivityView, { visitActivity: visitActivity, setIsActivityModalClosed: setIsActivityModalClosed }) }), !isParticipantDeactivated(participantDetails) && (_jsx(OnboardingSchedule, { onboardingScheduleActivities: onboardingVisits, setCurrentVisit: setCurrentVisit, setIsActivityListModalOpen: setIsActivityListModalOpen })), participantCNumber && (_jsx(VisitSchedule, { participant: participantDetails, participantVisits: participantVisits, setCurrentVisit: setCurrentVisit, setIsActivityListModalOpen: setIsActivityListModalOpen })), _jsx(NonVisitSchedule, { participant: participantDetails, nonVisitData: nonVisitGroups, setCurrentVisit: setCurrentVisit, setIsActivityListModalOpen: setIsActivityListModalOpen })] }));
};
