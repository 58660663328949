import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useToastNotification } from 'libs.nucleus';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../use_current_environment';
export const useFetchParticipants = ({ initialPage = 0, initialPageSize = 10 }) => {
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const { addNotification } = useToastNotification();
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pagination, setPagination] = useState({ pageIndex: initialPage, pageSize: initialPageSize });
    const fetchParticipants = async (pageSize, pageIndex) => {
        if (!environment) {
            return [];
        }
        try {
            const { data: response } = await getApiInstance(environment).get(`/v2/routes/site_subjects/${site?._id}`, {
                params: {
                    enablePagination: true,
                    sort: JSON.stringify({ created: -1 }),
                    where: JSON.stringify({ c_type: { $in: ['participant', null] } }),
                    limit: pageSize,
                    skip: pageIndex * pageSize,
                },
            });
            // Check if the response has the expected data structure
            if (!response?.data?.data) {
                addNotification({
                    title: 'Please perform a study update to latest R4.0.0/dev-latest template to get the latest axon to view participants',
                    subtitle: 'This toast will be removed before the release SAW-2810',
                    type: 'error',
                });
                return [];
            }
            if (response.data.data.length === 0) {
                setTotalPages(0);
                setTotalItems(0);
            }
            setTotalItems(response.data.total);
            setTotalPages(Math.ceil(response.data.total / pagination.pageSize));
            return response.data.data;
        }
        catch (error) {
            addNotification({
                title: 'Please perform a study update to R4.0.0 to view participants',
                subtitle: 'This toast will be removed before the release SAW-2810',
                type: 'error',
            });
            return [];
        }
    };
    const { data: participants, isLoading } = useQuery({
        queryKey: ['participantsList', environment?.id, site?._id, { pagination }],
        queryFn: () => fetchParticipants(pagination.pageSize, pagination.pageIndex),
        enabled: true,
        placeholderData: keepPreviousData,
    });
    return {
        isLoading,
        participants,
        totalPages,
        totalItems,
        pagination,
        setPagination,
    };
};
