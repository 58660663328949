import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { FirebaseAuthContext } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { useLocalizeMessage, useToastNotification } from 'libs.nucleus';
import { ApiClientService, useApiClient } from 'libs.react';
import { IRB_FLAGS } from '../utils';
export const useCreateIrbPackages = () => {
    const { studyId } = useParams();
    const { post } = useApiClient(ApiClientService.WEB);
    const { firebaseToken } = useContext(FirebaseAuthContext);
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    let extraParams = {};
    if (flagManager.getValue(IRB_FLAGS.irbEnableRealAppScreensEvenWhenSomeFails)) {
        extraParams = { realAppScreens: 'lenient' };
    }
    else if (!flagManager.getValue(IRB_FLAGS.irbEnableRealAppScreens)) {
        extraParams = { realAppScreens: 'disabled' };
    }
    return useMutation({
        mutationKey: ['irb', 'create_packages'],
        mutationFn: async ({ packages, authToken, studyUuid, envId }) => {
            return await post(`/api/irb/create_package`, {
                studyId,
                studyUuid,
                packages,
                authToken,
                envId,
                ...extraParams,
            }, {
                // Pass firebase token, don't pass medable token
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${firebaseToken}`,
                },
                no500Handling: true,
            });
        },
        retry: false,
        onError: (error) => {
            // @ts-expect-error no error here my friend
            const errorMessage = error?.response?.status === 500 && error?.response?.data?.error;
            if (errorMessage) {
                addNotification({
                    title: translate('Cannot proceed'),
                    subtitle: {
                        'Test plan screenshot API is in use, cannot proceed. Try again later': translate('Test plan screenshot API is in use, cannot proceed. Try again later'),
                    }[errorMessage] || errorMessage,
                    type: 'error',
                });
            }
        },
    });
};
