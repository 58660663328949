import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { EM_DASH } from 'libs.constants';
import { Badge } from 'libs.nucleus';
import { getApiInstance } from './api.helpers';
import { PARTICIPANT_NUMBER_PREFIX } from './constants.helpers';
import { normalizeDate } from './date.helpers';
import { ParticipantStatuses } from '../constants/participants.constants';
export const getParticipantNumber = (rowData) => {
    if (rowData.c_number) {
        return rowData.c_number;
    }
    const { _id } = rowData;
    if (!_id) {
        return EM_DASH;
    }
    return `${PARTICIPANT_NUMBER_PREFIX}-${_id.substring(_id.length - 6, _id.length)}`;
};
export const getParticipantStatus = (status) => {
    if (status === ParticipantStatuses.CONSENTED) {
        return _jsx(Badge, { variant: 'success', label: status });
    }
    return _jsx(Badge, { label: status });
};
export const getParticipantDetails = async ({ environment, siteId, participantId, }) => {
    try {
        const response = await getApiInstance(environment).get(`/v2/routes/site_subjects/${siteId}`, {
            params: {
                expand: ['c_visit_schedule', 'c_caregiver_relationship.c_caregivers_info.c_public_user'],
                where: JSON.stringify({ _id: participantId }),
            },
        });
        return response?.data?.data[0] || {};
    }
    catch (error) {
        return {};
    }
};
export const isParticipantAuthenticated = (participant) => !!participant?.c_account;
export const isParticipantACaregiver = (participant) => participant?.c_type === 'caregiver';
export const participantHasLinkedCaregivers = (participant) => (participant?.c_caregiver_relationship?.c_caregivers_info?.length ?? 0) > 0;
export const isParticipantDeactivated = (participant) => participant?.c_status?.toLowerCase() === 'deactivated';
export const getParticipantNextVisitWindow = (participant) => {
    // todo: add localization for date format
    if (participant.c_next_visit_window_start && participant.c_next_visit_window_end) {
        const startDateFormatted = moment(participant.c_next_visit_window_start).format('YYYY-MMM-DD');
        const endDateFormatted = moment(participant.c_next_visit_window_end).format('YYYY-MMM-DD');
        return `${startDateFormatted} to ${endDateFormatted}`;
    }
    if (participant.c_next_visit_date) {
        return moment(participant.c_next_visit_date).format('YYYY-MMM-DD');
    }
    return EM_DASH;
};
export const formatVisitWindow = (param) => {
    if (!param.windowStartDate || !param.windowEndDate) {
        return EM_DASH;
    }
    const startDate = normalizeDate(param.windowStartDate);
    const endDate = normalizeDate(param.windowEndDate);
    const startMonthName = new Intl.DateTimeFormat(param.currentLocale, { month: 'long' }).format(startDate);
    const endMonthName = new Intl.DateTimeFormat(param.currentLocale, { month: 'long' }).format(endDate);
    const year = startDate.getFullYear();
    // Check if the months are the same
    let periodStr;
    if (startMonthName === endMonthName) {
        const period = startDate.getDate() === endDate.getDate() ? startDate.getDate() : `${startDate.getDate()}-${endDate.getDate()}`;
        periodStr = `${startMonthName} ${period}, ${year}`;
    }
    else {
        periodStr = `${startMonthName} ${startDate.getDate()} - ${endMonthName} ${endDate.getDate()}, ${year}`;
    }
    return periodStr;
};
export const getParticipantDuplicateIndexErrorMessage = (error, translate) => {
    const errorData = error?.response?.data;
    if (['kError'].includes(errorData?.code) &&
        [errorData?.faults?.[0]?.code, errorData?.faults?.[0]?.faults?.[0]?.code].includes('kValidationError') &&
        [errorData?.faults?.[0]?.faults?.[0]?.errCode, errorData?.faults?.[0]?.faults?.[0]?.faults?.[0]?.errCode].includes('cortex.conflict.duplicateKey')) {
        let message;
        const errorPath = errorData?.faults?.[0]?.faults?.[0]?.path || errorData?.faults?.[0]?.faults?.[0]?.faults?.[0]?.path;
        switch (errorPath) {
            case 'c_number':
                message = translate('error.participant.id_exists');
                break;
            case 'c_email':
                message = translate('error.participant.email_exists');
                break;
        }
        return message;
    }
};
export const shouldShowTelevistButton = (participant, org) => {
    const hasUserAcceptedInvite = participant?.c_invite === 'accepted';
    return !!org.c_televisit_enabled && hasUserAcceptedInvite;
};
