import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { LocaleContext, Text } from 'libs.nucleus';
import { COLORS_TEXT, TEXT_SIZE_MAP } from '../reporting.constants';
const getFormattedNumber = (number, locale) => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        notation: 'compact',
        compactDisplay: 'short',
    });
    return formatter.format(number).toLowerCase();
};
export const NumberChart = ({ data, className = '' }) => {
    const locale = useContext(LocaleContext).toString();
    return (_jsx("div", { className: `flex flex-1 items-center justify-items-center ${className}`, children: data &&
            data.datasets &&
            data.datasets.map(({ number, label, title, unit = { value: '', size: 'base' }, numberSize = 'base', color, }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            _jsxs("div", { className: 'flex flex-col mx-auto', children: [_jsx("div", { className: 'flex flex-1 text-text-secondary', children: title }), _jsxs("div", { className: `flex justify-center flex-1 ${color ? COLORS_TEXT[color] : 'text-text-primary'} ${TEXT_SIZE_MAP[numberSize]}`, children: [typeof number === 'number' ? getFormattedNumber(number, locale) : number, _jsx("span", { className: `flex items-end ${TEXT_SIZE_MAP[unit.size]}`, children: unit.value })] }), _jsx(Text, { size: 'xs', className: 'flex flex-1 text-text-secondary justify-center', children: label })] }, `number-chart-container-${index}`))) }));
};
